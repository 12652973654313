import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { Box, Button, IconButton, Card, Typography, Dialog, AppBar, DialogContent, DialogTitle, DialogActions, Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ThreeDots } from 'react-loader-spinner'
import Cookies from 'universal-cookie';

//components
import { Filters } from './component/filters';
import { Header } from './component/header';
import Trip_create from "./trip_create";
import Trip_view from "./trip_view"

// colors
import colors from '../config/colors';

// Api
import fetchData from '../api/fetchData';

//icons
import CloseIcon from '@mui/icons-material/Close';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import DataSaverOnOutlinedIcon from '@mui/icons-material/DataSaverOnOutlined';
import PostAddIcon from '@mui/icons-material/PostAdd';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Trip_Assign } from "./trip_assign";

//styles
const classes = {
    container: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    data_table: {
        position: "absolute",
        top: 130,
        left: 10,
        right: 10,
        bottom: 10,
        '& .super-app-theme--header': {
            backgroundColor: colors.dark1,
        },
    },
    loader: {
        position: "absolute",
        top: 130,
        left: 10,
        right: 10,
        bottom: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    vehicleBox: {
        position: "absolute",
        top: 130,
        width: '20%',
        right: 10,
        padding: 2,
        zIndex: 10,
        bottom: 10,
        overflowY: "scroll"
    },
    v_item: {
        mt: 2,
        flex: 1,
        display: "flex",
        borderRadius: 1,
        py: 0.1, px: 2,
        justifyContent: "space-between",
        border: 2,
        borderColor: "divider",
        alignItems: "center"
    },
    v_val:
    {
        fontSize: 13, flex: 1,
    },
}

export default function Trip() {
    const [datas, setDatas] = useState([])
    const [delete_modal, setDelete_modal] = useState(false)
    const [delete_data, setDelete_Data] = useState([])
    const [dialog_title, setDialog_Title] = useState()
    const [submit, setSubmit] = useState()
    const [trip_det, setTrip_det] = useState([])
    const [loader, setLoader] = useState(true)
    const [v_list, setV_list] = useState([])
    const [assign_tripbox, setAssign_Tripbox] = useState(false)
    const [tableWidth, setTableWidth] = useState();
    const [create_trip_popup, setCreate_trip_popup] = useState(false);
    const [view_trip_popup, setView_trip_popup] = useState(false);
    const [view_trip_det, setView_trip_det] = useState([]);
    const [assign_popup, setAssign_popup] = useState(false);
    const [edit_data, setEdit_Data] = useState([])
    const [assigned_data, setAssigned_Data] = useState([])
    const [fence_id, setFence_id] = useState()
    const [trip_name,setTrip_name]=useState([])

    const { enqueueSnackbar } = useSnackbar();

    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }
    const columns = [
        { field: 'gfence_name', headerName: 'Trip Name', flex: 1, headerClassName: 'super-app-theme--header', width: 70 },
        { field: 'dname', headerName: 'District', flex: 1, headerClassName: 'super-app-theme--header', width: 70 },
        { field: 'ac_name', headerName: 'Assembly', flex: 1, headerClassName: 'super-app-theme--header', width: 70 },
        { field: 'stdt', headerName: 'Start Time', flex: 1, headerClassName: 'super-app-theme--header', width: 70 },
        { field: 'endt', headerName: 'End Time', flex: 1, headerClassName: 'super-app-theme--header', width: 70 },
        { field: 'no_of_stops', headerName: 'No of Waypoints', flex: 1, headerClassName: 'super-app-theme--header', width: 70 },
        { field: 'type', headerName: 'Trip Type', flex: 1, headerClassName: 'super-app-theme--header', width: 70 },
        { field: 'no_of_veh', headerName: 'No of Vehicles', flex: 1, headerClassName: 'super-app-theme--header', width: 70 },
        {
            headerName: 'View',
            field: 'View',
            width: 100, headerClassName: 'super-app-theme--header',
            renderCell: (params) =>
                <>
                    <IconButton sx={{ backgroundColor: colors.primary }} onClick={(e) => { view_trip(params.row);setTrip_name(params?.row?.gfence_name) }}><RemoveRedEyeIcon />
                    </IconButton >
                </>
        },
        {
            headerName: 'Assign',
            field: 'Assign',
            width: 100, headerClassName: 'super-app-theme--header',
            renderCell: (params) =>
                <>
                    <IconButton sx={{ backgroundColor: colors.primary }} onClick={(e) => { asigned_veh(params?.row?.id); setAssigned_Data(params.row) }}><PostAddIcon />
                    </IconButton >
                </>
        },
    ];

    const apply = (data) => {
        // if (!data.ac_fk) {
        //     alert_trigger("error", "Please select assembly")
        //     return
        // }
        setDatas(data)
        setLoader(true)
        fetchData.get_trips(data).then((res) => {
            var { trips } = res;
            setTrip_det(trips)
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            console.log(error)
        })
    }

    const closebox = () => {
        setAssign_Tripbox(false);
        setTableWidth();
    }

    const create_trip = () => {
        setCreate_trip_popup(true)
    }

    const view_trip = (item) => {
        var data = { "gfence_pk": item?.id }
        fetchData.view_fence_route(data).then((res) => {
            var { view_route_lst } = res;
            setView_trip_det(view_route_lst)
            setView_trip_popup(true)

        }).catch((error) => {
            console.log(error)
        })
    }

    const asigned_veh = (rowid) => {
        if (rowid != undefined) {
            var data = { "fence_id": rowid }
            setFence_id(rowid)
            fetchData.get_rfence_vmap(data).then((res) => {
                var { rfence_vmap } = res;
                setV_list(rfence_vmap)
                setAssign_Tripbox(true)
                setTableWidth('78.5%');
            }).catch((error) => {
                console.log(error)
            })
        }
    }

    const refresh = () => {
        apply(datas)
        asigned_veh(fence_id)
    }

    const detete_data = () => {
        const cookies = new Cookies();
        var data = { "map_id": delete_data?.vmap_id }
        fetchData.delete_rfence_vmap(data).then((res) => {
            var { msg, rval } = res;
            if (rval > 0) {
                alert_trigger("success", msg)
                var data = { "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }
                apply(data)
                setDelete_modal(false)
                refresh()
            } else {
                alert_trigger("error", msg)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <Box sx={classes.container}>
            <Header></Header>
            <Filters apply={apply} options={"District,Assembly,TripAdd,Nav"} page="Trip" onCreate={() => { create_trip() }}></Filters>
            <Box sx={classes.data_table} style={{ width: tableWidth }} >
                {loader ? <Box sx={classes.loader}>
                    <ThreeDots
                        height="100"
                        width="100"
                        radius="7"
                        color="#0288d1"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    /></Box>
                    :
                    <DataGrid
                        disableColumnSelector
                        disableDensitySelector
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                        pageSizeOptions={[10, 20, 50, 100]}
                        columns={columns}
                        rows={trip_det}
                    />
                }
            </Box>
            {assign_tripbox &&
                <Card sx={classes.vehicleBox}>
                    <Box >
                        <Box sx={{ pb: 1 }}>
                            <Button startIcon={<DataSaverOnOutlinedIcon />} variant="contained" onClick={() => { setAssign_popup(true); setDialog_Title("Assigning Route Fence"); setSubmit("Submit") }}>Assing New Vehicle</Button>
                            <IconButton onClick={() => closebox()} sx={{ position: 'absolute', right: 10 }}><CloseIcon /></IconButton>
                        </Box>
                        <Divider />
                        {v_list?.map((item) => {
                            return (
                                <Box sx={classes.v_item}>
                                    <Box sx={{ flex: 5 }}>
                                        <Typography variant="subtitle1" sx={classes.v_val}>{item?.v_no}</Typography>
                                    </Box>
                                    <IconButton color="primary" onClick={() => { setAssign_popup(true); setDialog_Title("Edit Route Fence"); setSubmit("Update"); setEdit_Data(item) }}><EditNoteIcon /></IconButton>
                                    <IconButton color="error" onClick={() => { setDelete_modal(true); setDelete_Data(item) }}><DeleteIcon /></IconButton>
                                </Box>
                            )
                        })}
                    </Box>
                </Card>
            }
            {create_trip_popup &&
                <Dialog
                    fullScreen
                    open={true}
                >
                    <Trip_create onClose={() => setCreate_trip_popup(false)} onRefresh={() => { apply(datas) }} />
                </Dialog>
            }
            {view_trip_popup &&
                <Dialog
                    open={true}
                    onClose={() => { setView_trip_popup(false) }}
                    sx={{
                        '& .MuiPaper-root': {
                            width: '100%',
                            maxWidth: 700,
                        },
                    }}
                >
                    <Box sx={{ height: 500, width: 700 }}>
                        <Trip_view item={view_trip_det} onClose={() => { setView_trip_popup(false) }} trip_name={trip_name} />
                    </Box>
                </Dialog>
            }
            {assign_popup &&
                <Dialog
                    open={true}
                    onClose={() => { setAssign_popup(false) }}
                >
                    <Trip_Assign datas={assigned_data} items={trip_det} item={edit_data} submit={submit} dialog_title={dialog_title} onClose={() => { setAssign_popup(false); { setEdit_Data({}) } }} refresh={refresh} />
                </Dialog>

            }
            {delete_modal &&
                <Dialog
                    open={true}
                    onClose={() => setDelete_modal(false)}
                >
                    <Box sx={{ width: 450 }}>
                        <DialogTitle sx={{ fontSize: 15 }}>Confirm Delete</DialogTitle>
                        <DialogContent >
                            Are you sure you want to delete ?
                        </DialogContent>
                        <DialogActions sx={{ pr: 3, pb: 2 }}>
                            <Button onClick={() => setDelete_modal(false)}>Cancel</Button>
                            <Button onClick={() => detete_data()}>Delete</Button>
                        </DialogActions>
                    </Box>
                </Dialog>
            }
        </Box>
    );
}