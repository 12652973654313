import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Box, IconButton, InputBase, Divider } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { ThreeDots } from 'react-loader-spinner'
import Cookies from 'universal-cookie';
import { Navigate, useNavigate } from "react-router-dom";


// components
import { Filters } from './component/filters';
import Reportmap from './report_map';

// colors
import colors from '../config/colors';

// Api
import fetchData from '../api/fetchData';

//icons
import MyLocationIcon from '@mui/icons-material/MyLocation';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const classes = {
    container: {
        position: "absolute",
        top: 10,
        left: 0,
        right: 0,
        bottom: 0,
        '& .super-app-theme--header': {
            backgroundColor: colors.dark1,
        },
    },
    data_table: {
        position: "absolute",
        top: 130,
        left: 10,
        right: 10,
        bottom: 10,
    },
    loader: {
        position: "absolute",
        top: 130,
        left: 10,
        right: 10,
        bottom: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}

var vehicle_info1 = {}
export default function Notif_report() {
    const [vehicle_details, setVehicle_details] = useState([]);
    const [proc_id, setProc_id] = useState("")
    const [paginationModel, setPaginationModel] = useState({ pageSize: 50, page: 0 })
    const [rowCountState, setRowCountState] = useState(0)
    const [datas, setDatas] = useState([])
    const [loader, setLoader] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const [active_item, setActive_item] = React.useState([]);
    const [active_coords, setActive_coords] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const [column, setColumn] = React.useState([]);
    const [search_val, setSearch_val] = useState("");
    const [dom_search_val, setDom_search_val] = useState("");
    const cookies = new Cookies();

    const navigate = useNavigate();


    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        } else {
            set_table_col(vehicle_info1)
        }
    }, []);


    const open_map = (e) => {
        setActive_item([{ "label": "District", "value": e.dname }, { "label": "Assembly", "value": e.ac_name }, { "label": "Vehicle No", "value": e.v_no },
          { "label": "Date Time", "value": e.dt },  { "label": "Title", "value":e.alert  },{ "label": "Driver Name", "value":e.driver_name  },
          { "label": "Driver No", "value":e.driver_no  },{ "label": "Alert", "value":e.pattern  },
          { "label": "Incharge Name", "value":e.incharge_name??""  },{ "label": "Incharge No", "value":e.incharge_no??""  }]);
        setActive_coords({ "lat": e.lat, "lng": e.lon })
        setOpen(true)
    }


    const set_table_col = (vehicle_info) => {
        const column = [
            { field: 'dname', headerName: 'District', width:200, headerClassName: 'super-app-theme--header', },
            { field: 'ac_name', headerName: 'Assembly', width:200, headerClassName: 'super-app-theme--header', },
            { field: 'category', headerName: 'Category', width:200, headerClassName: 'super-app-theme--header', },
            { field: 'v_no', headerName: 'Vehicle No', width:200, headerClassName: 'super-app-theme--header', },
            { field: 'driver_name', headerName: 'Driver Name', width:200, headerClassName: 'super-app-theme--header', },
            { field: 'driver_no', headerName: 'Driver No', width:200, headerClassName: 'super-app-theme--header', },
            { field: 'incharge_name', headerName: 'Incharge Name', width:200, headerClassName: 'super-app-theme--header', },
            { field: 'incharge_no', headerName: 'Incharge No', width:200, headerClassName: 'super-app-theme--header', },

            { field: 'v_no', headerName: 'Vehicle No', width:200, headerClassName: 'super-app-theme--header', },
            // { field: 'vehicle_name', headerName: 'Vehicle Type', width:200, headerClassName: 'super-app-theme--header', },
            {
                headerName: 'Map',
                disableExport: true,
                width: 100, headerClassName: 'super-app-theme--header',
                renderCell: (params) =>
                    <>
                        <IconButton onClick={(e) => { open_map(params.row) }}><MyLocationIcon color="primary" />
                        </IconButton >
                    </>
            },
            { field: 'dt', headerName: 'Date Time', width:200, headerClassName: 'super-app-theme--header' },
            { field: 'alert', headerName: 'Title', width:200, headerClassName: 'super-app-theme--header', },
            { field: 'lat', headerName: 'Latitude', width:200, headerClassName: 'super-app-theme--header' },
            { field: 'lon', headerName: 'Longitude', width:200, headerClassName: 'super-app-theme--header', },
            { field: 'pattern', headerName: 'Alert', width:300, headerClassName: 'super-app-theme--header', },
        ]
        setColumn(column)
    }

    const apply = (data) => {
        setDatas(data)
        if (!data.from) {
            alert_trigger("error", "Please select date range to view report")
            return
        }
        else if (!data.to) {
            alert_trigger("error", "Please select date range to view report")
            return
        }
        // if ((!data.dist_fk) && (cookies.get("priv") == 0 || cookies.get("priv") == 1)) {
        //     alert_trigger("info", "Please Select District");
        //     return
        // }
        // if ((!data.ac_fk) ) {
        //     alert_trigger("info", "Please Select Assembly");
        //     return
        // }
        setLoader(true)

        data["pageSize"] = data.pageSize ? data.pageSize : paginationModel.pageSize
        data["page"] = data.page ? data.page : paginationModel.page
        fetchData.alerts(data).then((res) => {
            var { alerts, total_count } = res;
            setProc_id(proc_id)
            setRowCountState(total_count)
            setVehicle_details(alerts)
            setLoader(false)
            get_addr(alerts)

        }).catch((error) => {
            console.log(error)
            setLoader(false)
        })
    }

    const get_addr = (vehicle_list) => {
        var cords = []
        vehicle_list?.map((item) => {
            cords.push({ "lat": parseFloat(item.lat), "lon": parseFloat(item.lon), "id": item.id })
        })
        fetchData.v_addr({ "latlon": cords }).then((res) => {
            var addr = res.addr
            var v_new_list = []
            vehicle_list?.map((item) => {
                var it = item
                var act = addr.find(({ id }) => id == item.id);
                console.log(item)
                it["pattern"] = it["pattern"].replace("<addr>", act.formattedAddress)
                v_new_list.push(item)
            })
            setVehicle_details(v_new_list)


        }).catch((error) => {
            console.log(error)
        })
    }

    const search_vehicle = (action_type) => {

        var data = datas
        if (action_type == 2) {
            if (!search_val) {
                alert_trigger("info", "Please enter Vehicle No to search the data")
                return
            }

            setDom_search_val(search_val)
            data["search_val"] = search_val
        } else {
            setDom_search_val("")
            data["search_val"] = ""
        }
        if(search_val)
            apply(data)
    }

    function exchangeTime(en, st) {
        var a = (new Date(en) - new Date(st)) / 1000
        var b, hh, mm, ss;
        b = Math.round(a / 86400 + 0.5) - 1;
        hh = Math.round((a - b * 86400) / 3600 + 0.5) - 1;
        mm = Math.round((a - b * 86400 - hh * 3600) / 60 + 0.5) - 1;
        ss = Math.round(a - b * 86400 - hh * 3600 - mm * 60);
        var c = "";
        if (b > 0)
            c = c + b + " Day ";
        if (hh > 0)
            c = c + hh + " Hour ";
        if (mm > 0) {
            c = c + mm + " Mins ";
            if (b > 0)
                return c
        }
        if (ss > 0)
            c = c + ss + " Sec";
        return c
    }

    const changePagination = (e) => {
        console.log(e)
        setPaginationModel(e);
        var data1 = datas
        data1["pageSize"] = String(e.pageSize)
        data1["page"] = String(e.page)
        apply(data1)
    }





    return (
        <Box sx={classes.container}>
            <Filters page={"NReport"} alertf={true} apply={apply} options={"District,Assembly,Category,Vehicle,Alerttype,From,To,Apply"}></Filters>
            {loader ? <Box sx={classes.loader}>
                <ThreeDots
                    height="100"
                    width="100"
                    radius="7"
                    color="#0288d1"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                /></Box>
                : <Box sx={classes.data_table}>
                    <DataGrid
                        disableColumnSelector
                        disableDensitySelector
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                        pageSizeOptions={[10, 20, 50]}
                        initialState={{
                            pagination: { paginationModel: { pageSize: paginationModel.pageSize } },
                        }}
                        paginationModel={paginationModel}
                        rowCount={rowCountState}
                        paginationMode="server"
                        onPaginationModelChange={(e) => { changePagination(e) }}
                        rows={vehicle_details}
                        columns={column}
                        slots={{
                            toolbar: CustomToolbar,
                        }}
                        slotProps={{ toolbar: { csvOptions: { allColumns: true } } }}
                    />
                </Box>
            }
            {open &&

                <Reportmap handleClose={handleClose} item={active_item} coords={active_coords} />
            }
            {(vehicle_details.length > 0&&!loader )&&
                <Box
                    component="form"
                    sx={{ display: 'flex', alignItems: 'center', backgroundColor: "black", borderRadius: 1, ml: 1, height: 40, position: "absolute", top: 138, right: 160 }}
                >
                    <InputBase
                        sx={{ ml: 1, width:200 }}
                        placeholder="Vehicle No"
                        value={search_val}
                        onChange={(e) => { setSearch_val(e.target.value) }}
                    />
                    <Divider orientation="vertical" />
                    <IconButton sx={{ borderRadius: 0 }}>
                        <CloseIcon onClick={() => {  search_vehicle(1);setSearch_val(""); }} />
                    </IconButton>
                    <Divider orientation="vertical" />
                    <IconButton sx={{ borderRadius: 0 }}>
                        <SearchIcon onClick={() => { search_vehicle(2) }} />
                    </IconButton>
                </Box>
            }
        </Box>
    )
}

function CustomToolbar() {
    return (
        <GridToolbarContainer sx={{ backgroundColor: colors.dark1, justifyContent: "flex-end", pt: 1 }}>
            <GridToolbarExport printOptions={{ disableToolbarButton: true }} sx={{ mr: 3, backgroundColor: "black", height: 39, width: 90,color:"white",fontSize:14 }} />
        </GridToolbarContainer>
    );
}