import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Box, IconButton, TextField, Autocomplete, Chip, Checkbox, Typography } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { ThreeDots } from 'react-loader-spinner'
import Cookies from 'universal-cookie';
import { useNavigate, useLocation } from "react-router-dom";


// components
import { Filters } from './component/filters';
import { Header } from './component/header';
import Reportmap from './report_map';

// colors
import colors from '../config/colors';

// Api
import fetchData from '../api/fetchData';

//icons
import MyLocationIcon from '@mui/icons-material/MyLocation';

const classes = {
    container: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        '& .super-app-theme--header': {
            backgroundColor: colors.dark1,
        },
    },
    data_table: {
        position: "absolute",
        top: 170,
        left: 10,
        right: 10,
        bottom: 10,
    },
    loader: {
        position: "absolute",
        top: 130,
        left: 10,
        right: 10,
        bottom: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    alert: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        pl: 1
    },
}

var vehicle_info1 = {}
export default function Manage_Alerts() {
    const [vehicle_details, setVehicle_details] = useState([]);
    const [proc_id, setProc_id] = useState("")
    const [paginationModel, setPaginationModel] = useState({ pageSize: 50, page: 0 })
    const [rowCountState, setRowCountState] = useState(0)
    const [datas, setDatas] = useState([])
    const [loader, setLoader] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const [active_item, setActive_item] = React.useState([]);
    const [active_coords, setActive_coords] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const [column, setColumn] = React.useState([]);
    const [search_val, setSearch_val] = useState("");
    const [dom_search_val, setDom_search_val] = useState("");
    const [alert_types, setAlert_types] = useState([])
    const [checkedItems, setCheckedItems] = useState({});
    const cookies = new Cookies();
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location.state,"lllllll")

    console.log("checkedItems", checkedItems)


    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        } else {
            set_table_col(vehicle_info1)
        }
    }, []);

    useEffect(() => {
        get_alert_type();
    }, []);

    const open_map = (e) => {
        setActive_item([{ "label": "District", "value": e.dname }, { "label": "Assembly", "value": e.ac_name }, { "label": "Vehicle No", "value": e.v_no }, { "label": "Date Time", "value": e.dt }, { "label": "Title", "value": e.alert }]);
        setActive_coords({ "lat": e.lat, "lng": e.lon })
        setOpen(true)
    }


    const set_table_col = (vehicle_info) => {
        const column = [
            { field: 'dname', headerName: 'District', flex: 1, headerClassName: 'super-app-theme--header', },
            { field: 'ac_name', headerName: 'Assembly', flex: 1, headerClassName: 'super-app-theme--header', },
            { field: 'category', headerName: 'Category', flex: 1, headerClassName: 'super-app-theme--header', },
            { field: 'v_no', headerName: 'Vehicle No', flex: 1, headerClassName: 'super-app-theme--header', },
            // { field: 'vehicle_name', headerName: 'Vehicle Type', flex: 1, headerClassName: 'super-app-theme--header', },
            {
                headerName: 'Map',
                disableExport: true,
                width: 100, headerClassName: 'super-app-theme--header',
                renderCell: (params) =>
                    <>
                        <IconButton onClick={(e) => { open_map(params.row) }}><MyLocationIcon color="primary" />
                        </IconButton >
                    </>
            },
            { field: 'dt', headerName: 'Date Time', flex: 1.2, headerClassName: 'super-app-theme--header' },
            { field: 'alert', headerName: 'Title', flex: 1.2, headerClassName: 'super-app-theme--header', },
            { field: 'lat', headerName: 'Latitude', flex: 1.2, headerClassName: 'super-app-theme--header' },
            { field: 'lon', headerName: 'Longitude', flex: 1.2, headerClassName: 'super-app-theme--header', },
            { field: 'pattern', headerName: 'Alert', flex: 2, headerClassName: 'super-app-theme--header', },
        ]
        setColumn(column)
    }

    const apply = (data) => {
        setDatas(data)
        // if (!data.from) {
        //     alert_trigger("error", "Please select date range to view report")
        //     return
        // }
        // else if (!data.to) {
        //     alert_trigger("error", "Please select date range to view report")
        //     return
        // }
        // if ((!data.dist_fk) && (cookies.get("priv") == 0 || cookies.get("priv") == 1)) {
        //     alert_trigger("info", "Please Select District");
        //     return
        // }
        // if ((!data.ac_fk) ) {
        //     alert_trigger("info", "Please Select Assembly");
        //     return
        // }
        setLoader(true)
        data["pageSize"] = data.pageSize ? data.pageSize : paginationModel.pageSize
        data["page"] = data.page ? data.page : paginationModel.page
        fetchData.alerts(data).then((res) => {
            var { alerts, total_count } = res;
            setProc_id(proc_id)
            setRowCountState(total_count)
            setVehicle_details(alerts)
            setLoader(false)
            get_addr(alerts)
        }).catch((error) => {
            console.log(error)
            setLoader(false)
        })
    }

    const get_addr = (vehicle_list) => {
        var cords = []
        vehicle_list?.map((item) => {
            cords.push({ "lat": parseFloat(item.lat), "lon": parseFloat(item.lon), "id": item.id })
        })
        fetchData.v_addr({ "latlon": cords }).then((res) => {
            var addr = res.addr
            var v_new_list = []
            vehicle_list?.map((item) => {
                var it = item
                var act = addr.find(({ id }) => id == item.id);
                it["pattern"] = it["pattern"].replace("<addr>", act.formattedAddress)
                v_new_list.push(item)
            })
            setVehicle_details(v_new_list)
        }).catch((error) => {
            console.log(error)
        })
    }

    const search_vehicle = (action_type) => {
        var data = datas
        if (action_type == 2) {
            if (!search_val) {
                alert_trigger("info", "Please enter Vehicle No to search the data")
                return
            }
            setDom_search_val(search_val)
            data["search_val"] = search_val
        } else {
            setDom_search_val("")
            data["search_val"] = ""
        }
        if (search_val)
            apply(data)
    }

    // function exchangeTime(en, st) {
    //     var a = (new Date(en) - new Date(st)) / 1000
    //     var b, hh, mm, ss;
    //     b = Math.round(a / 86400 + 0.5) - 1;
    //     hh = Math.round((a - b * 86400) / 3600 + 0.5) - 1;
    //     mm = Math.round((a - b * 86400 - hh * 3600) / 60 + 0.5) - 1;
    //     ss = Math.round(a - b * 86400 - hh * 3600 - mm * 60);
    //     var c = "";
    //     if (b > 0)
    //         c = c + b + " Day ";
    //     if (hh > 0)
    //         c = c + hh + " Hour ";
    //     if (mm > 0) {
    //         c = c + mm + " Mins ";
    //         if (b > 0)
    //             return c
    //     }
    //     if (ss > 0)
    //         c = c + ss + " Sec";
    //     return c
    // }

    const changePagination = (e) => {
        console.log(e)
        setPaginationModel(e);
        var data1 = datas
        data1["pageSize"] = String(e.pageSize)
        data1["page"] = String(e.page)
        apply(data1)
    }

    // const get_alert_type = () => {
    //     var data = {};
    //     fetchData.alert_types(data)
    //         .then((res) => {
    //             var { alert_types } = res;
    //             const initialCheckedItems = alert_types.reduce((acc, item) => ({
    //                 ...acc,
    //                 [item.value]: item.label
    //             }), {});
    //             setCheckedItems(initialCheckedItems);
    //             setAlert_types(alert_types);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // };

    const get_alert_type = () => {
        var data = {};
        fetchData.alert_types(data)
            .then((res) => {
                var { alert_types } = res;
                setCheckedItems(alert_types.map(item => item.value));
                setAlert_types(alert_types);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // const alert_checked_val = (isChecked, value, label) => {
    //     if (isChecked) {
    //         setCheckedItems(prevState => ({
    //             ...prevState,
    //             [value]: label
    //         }));
    //     } else {
    //         const updatedItems = { ...checkedItems };
    //         delete updatedItems[value];
    //         setCheckedItems(updatedItems);
    //     }
    // };
    const alert_checked_val = (isChecked, value) => {
        if (isChecked) {
            setCheckedItems(prevState => [...prevState, value]);
        } else {
            setCheckedItems(prevState => prevState.filter(item => item !== value));
        }
    };

    return (
        <Box sx={classes.container}>
            <Header ></Header>
            <Filters page={location.state}  alertf={true} apply={apply} options={"District,Assembly,Category,Vehicle,Nav"}></Filters>
            {alert_types?.map((item) => {
                return (
                    <Chip
                        variant="outlined"
                        sx={{ mr: 0.1, mt: 0.5, ml: 2, width: 200, }}
                        onClick={() => {
                            const isChecked = !checkedItems.includes(item.value);
                            alert_checked_val(isChecked, item.value);
                        }}
                        label={
                            <Box
                                sx={{
                                    fontSize: '12px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <Checkbox
                                    sx={{
                                        pr: 0.3,
                                        pl: 0,
                                        marginRight: '4px',
                                    }}
                                    defaultChecked
                                    checked={checkedItems.includes(item.value)}
                                    size="small"
                                    onChange={(e) => {
                                        alert_checked_val(e.target.checked, item.value);
                                    }}
                                />
                                {item?.label}
                            </Box>
                        }
                    />
                )
            })}
            {/* <Autocomplete
                sx={{
                    top: 80,
                    ml: 2,
                    mr: 1,
                    // position: 'absolute',
                    // width: '100%',
                    right: 30,
                }}
                size='small'
                multiple
                options={alert_types}
                value={alert_type_val}
                onChange={(event, newValue) => {
                    setalert_type_val(newValue);
                }}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Alert Type"
                        placeholder="Alerts"
                        variant="standard"
                    />
                )}
            /> */}

            {loader ? <Box sx={classes.loader}>
                <ThreeDots
                    height="100"
                    width="100"
                    radius="7"
                    color="#0288d1"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                /></Box>
                : <Box sx={classes.data_table}>
                    <DataGrid
                        disableColumnSelector
                        disableDensitySelector
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                        pageSizeOptions={[10, 20, 50]}
                        initialState={{
                            pagination: { paginationModel: { pageSize: paginationModel.pageSize } },
                        }}
                        paginationModel={paginationModel}
                        rowCount={rowCountState}
                        paginationMode="server"
                        onPaginationModelChange={(e) => { changePagination(e) }}
                        rows={vehicle_details}
                        columns={column}
                        slots={{
                            toolbar: CustomToolbar,
                        }}
                        slotProps={{ toolbar: { csvOptions: { allColumns: true } } }}
                    />
                </Box>
            }
            {open &&

                <Reportmap handleClose={handleClose} item={active_item} coords={active_coords} />
            }
            {/* {(vehicle_details.length > 0 && !loader) &&
                <Box
                    component="form"
                    sx={{ display: 'flex', alignItems: 'center', backgroundColor: "black", borderRadius: 1, ml: 1, height: 40, position: "absolute", top: 138, right: 160 }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Vehicle No"
                        value={search_val}
                        onChange={(e) => { setSearch_val(e.target.value) }}
                    />
                    <Divider orientation="vertical" />
                    <IconButton sx={{ borderRadius: 0 }}>
                        <CloseIcon onClick={() => { search_vehicle(1); setSearch_val(""); }} />
                    </IconButton>
                    <Divider orientation="vertical" />
                    <IconButton sx={{ borderRadius: 0 }}>
                        <SearchIcon onClick={() => { search_vehicle(2) }} />
                    </IconButton>
                </Box>
            } */}
        </Box>
    )
}

function CustomToolbar() {
    return (
        <GridToolbarContainer sx={{ backgroundColor: colors.dark1, justifyContent: "flex-end", pt: 1 }}>
            <GridToolbarExport printOptions={{ disableToolbarButton: true }} sx={{ mr: 3, backgroundColor: "black", height: 39, width: 90, color: "white", fontSize: 14 }} />
        </GridToolbarContainer>
    );
}