import React from 'react';
import { useState, useEffect, useRef, useMemo } from "react";
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from "react-router-dom";
import { TextField, Box, Typography, Autocomplete, responsiveFontSizes, Button, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { Map, InfoWindow, Marker, GoogleApiWrapper as MapScreen, Polygon, Circle, Polyline } from 'google-maps-react';
import { ThreeDots } from 'react-loader-spinner'
import { running_img, idle_img, stop_img, list_img, } from '../screens/image'
import Cookies from 'universal-cookie';
import SingleMapScreen from './trail'
import debounce from "lodash/debounce";
import encryptStorage from "../encryptStorage";
import pin_red from '../assets/pin_red.png'
import pin_blue from '../assets/pin_blue.png'
import b_icon from '../assets/cc_icons/b_icon.png'
import g_icon from '../assets/cc_icons/g_icon.png'
import r_icon from '../assets/cc_icons/r_icon.png'
import p_icon from '../assets/cc_icons/p_icon.png'
import bl_icon from '../assets/cc_icons/bl_icon.png'
import br_icon from '../assets/cc_icons/br_icon.png'
import df_icon from '../assets/cc_icons/df_icon.png'
import reddot from '../assets/reddot.png'
import greendot from '../assets/green_dot1.png'
import bluedot from '../assets/blue_dot.png'



// components
import { Header } from './component/header';
import { Filters } from './component/filters';

// colors
import colors from '../config/colors';

// map API Key
import mapkey from '../config/map_key';

// Api
import fetchData from '../api/fetchData';


//Icons
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { pink } from '@mui/material/colors';
// Styles

var interval
var addr_interval

const classes = {
    container: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        flexDirection: "row"
    },
    search: {
        position: "absolute",
        top: 120,
        left: 0,
        width: 300,
        bottom: 0,
        height: 60,
        //backgroundColor: colors.dark1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    vlist: {
        position: "absolute",
        top: 220,
        left: 0,
        width: 300,
        bottom: 0,
        // backgroundColor: colors.dark1,
        overflow: "auto", overflowY: "scroll",
        justifyContent: "center",
    },
    map: {
        position: "absolute",
        top: 120,
        left: 300,
        right: 0,
        bottom: 0,
    },
    v_item: {
        flex: 1,
        display: "flex",
        p: 0.8,
        borderLeft: 4,
        borderRadius: 1,
        pl: 1.5,
        cursor: "pointer",
        flexDirection: "column",
        m: 1,
    },
    v_icon: {

        color: "white",
        width: 30,
        height: 30
    },
    info_cont: {
        display: "flex",
        border: 1,
        borderBottom: "1px solid grey",
        paddingLeft: 8,
        paddingRight: 8
    },
    info_cont1: {
        display: "flex",
        border: 1,
        paddingLeft: 8,
        paddingRight: 8
    },
    label: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400,
    },
    value: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400
    },
    value_ps: {
        flex: 1,
        fontSize: 12,
        fontWeight: 500
    },
    vsum_label:
    {
        fontSize: 13, flex: 1, color: "grey.300"
    },
    vsum_value:
    {
        fontSize: 13, flex: 1
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99999,
        backgroundColor: "rgba(0,0,0,0.7)"
    }
}


var RotateIcon = function (options) {
    this.options = options || {};
    this.rImg = options.img || new Image();
    this.rImg.src = this.rImg.src || this.options.url || '';
    this.options.width = this.options.width || this.rImg.width || 52;
    this.options.height = this.options.height || this.rImg.height || 60;
    var canvas = document.createElement("canvas");
    canvas.width = this.options.width;
    canvas.height = this.options.height;
    this.context = canvas.getContext("2d");
    this.canvas = canvas;
};
RotateIcon.makeIcon = function (url) {
    return new RotateIcon({ url: url });
};
RotateIcon.prototype.setRotation = function (options) {
    var canvas = this.context,
        angle = options.deg ? options.deg * Math.PI / 180 :
            options.rad,
        centerX = this.options.width / 2,
        centerY = this.options.height / 2;
    canvas.clearRect(0, 0, this.options.width, this.options.height);
    canvas.save();
    canvas.translate(centerX, centerY);
    canvas.rotate(angle);
    canvas.translate(-centerX, -centerY);
    canvas.drawImage(this.rImg, 0, 0);
    canvas.restore();
    return this;
};
RotateIcon.prototype.getUrl = function () {
    return this.canvas.toDataURL('image/png');
};

var are;

export const MapSub = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [vsumm, setVsumm] = useState([])
    const [bounds, setBounds] = useState([])
    const [active_marker, setActive_marker] = useState(null)
    const [info, setInfo] = useState(false)
    const [active_item, setActive_item] = useState({})
    const [loader, setLoader] = useState(false)
    const [v_addr, setV_addr] = useState({})
    const [open, setOpen] = React.useState(false);
    const [active_vehicle_id, setActive_vehicle_id] = React.useState(0);
    const [search_veh, setSearch_veh] = useState([]);
    const [data_prev, setData_prev] = useState(null);
    const [app_conf, setApp_conf] = useState(encryptStorage.getItem("appSetting"));
    const [filepath, setFilepath] = useState(false)
    const [triangleCoords, setTrisngleCoords] = useState([])
    const [toggle, setToggle] = useState(false)
    const [pstoggle, setPstoggle] = useState(false)
    const [cctoggle, setCctoggle] = useState(false)
    const [psfence, setPsfence] = useState([])
    const [ccfence, setCcfence] = useState([])
    const [view_trip_det, setView_trip_det] = useState([]);
    const [start_point, setStart_point] = useState({})
    const [end_point, setEnd_point] = useState({})
    const [trip_bounds, setTrip_bounds] = useState([])
    const [coords, setCoords] = useState([])
    const [points, setPoints] = useState([])
    const [Label, setLabel] = useState(null);
    const [trip_active_marker, settrip_active_marker] = useState(null)
    const [trip_active_item, setTrip_active_item] = useState({})
    const [trip_info, setTrip_info] = useState(false)
    const [showTrip, setShowTrip] = useState(false);

    const [tripState, setTripState] = useState({});

    const [ps_active_marker, setPsActive_marker] = useState(null)
    const [ps_info, setPsInfo] = useState(false)
    const [activeps, setActiveps] = useState({})
    const [sortVal, setSortVal] = useState(0);


    const autoC = useRef(null);
    const markers = useRef([]);

    const appSetting = encryptStorage.getItem("appSetting");
    const map_default1 = { "lat": 16.2160, "lon": 77.3566, "zoom": 6 }
    const map_default2 = appSetting?.map_default
    const [ac_fk, setAc_fk] = useState(null);
    const [coordsArray, setCoordsArray] = useState([]);
    const show_cc_default = appSetting?.trip
    const [cc_label1, setCC_label1] = useState(show_cc_default?.show ? show_cc_default.label_1 : "CC 1KM")
    const [cc_label2, setCC_label2] = useState(show_cc_default?.show ? show_cc_default.label_2 : "CC 5KM")
    const [cc_reach_data, setCC_reach_data] = useState({});
    var cc_inter=show_cc_default?.interval

    useEffect(() => {
        if (show_cc_default?.show) {
            cc_Reached_data();
            const intervalId = setInterval(() => {
                cc_Reached_data();
            }, (cc_inter ?? 300) * 1000);
            return () => clearInterval(intervalId);
        }
    }, [ac_fk]);


    useEffect(() => {
        view_trip();
    }, [ac_fk]);

    const handleClose = () => {
        setOpen(false);
        clear_auto_complete()
        setSearch_veh([])
        console.log(data_prev, "data_prev")
        if (data_prev) {
            console.log("++++")
        }
    };


    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }
    
    const cc_Reached_data = () => {
        const requestData = { "ac_fk": ac_fk };
        fetchData.cc_reach_info(requestData).then((res) => {
            const { cc_info } = res;
            setCC_reach_data(cc_info)
        }).catch((error) => {
            console.log(error);
        });
    };
    //
    const onMarkerClick = (props, marker, e) => {
        setActive_marker(marker)
        console.log(props)
        setInfo(true);
        markerData(marker.id);
        let conf_inter = app_conf?.interval?.Tooltip;
        setTimeout(() => { setInfo(false); console.log("close") }, (conf_inter ?? 10) * 1000)
    }
    const markerData = (vid) => {
        tooltipData({ "vehicle_fk": vid });
        // Tooltip Address
        get_addr(vid)

    }
    const get_addr = (id) => {

        var act = vsumm.find(({ vehicle_fk }) => vehicle_fk == id);
        fetchData.v_addr({ "latlon": [{ "lat": act.lat, "lon": act.lon, "id": act.vehicle_fk }] }).then((res) => {
            setV_addr(res.addr)

        }).catch((error) => {
            console.log(error)
        })

    }

    const tooltipData = (data) => {
        fetchData.vehicle_list(data).then((res) => {
            var { vehicle_list } = res;
            setActive_item(vehicle_list[0])
        }).catch((error) => {
            console.log(error)
        })

    }

    const onPsMarkerClick = (props, marker, e, item) => {
        setActiveps(item)
        setPsActive_marker(marker)
        setPsInfo(true);
    }

    const extendBounds = (bounds, radius) => {

        var point1 = new props.google.maps.LatLng(
            bounds.getNorthEast().lat() + radius,
            bounds.getNorthEast().lng() + radius
        )
        var point2 = new props.google.maps.LatLng(
            bounds.getSouthWest().lat() - radius,
            bounds.getSouthWest().lng() - radius
        )
        bounds.extend(point1);
        bounds.extend(point2);
        return bounds
    }
    const onInputChange = (event, values) => {
        if (values)
            v_search(values)
    }

    const debouncedResults = useMemo(() => {
        return debounce(onInputChange, 500);
    }, []);


    const onTagsChange = (event, values) => {
        console.log(values?.vehicle_fk)
        if (values?.vehicle_fk) {
            open_trail(values.vehicle_fk)
        }
        else {
            handleClose()
        }
    }

    const v_search = (search_val) => {
        const cookies = new Cookies();
        fetchData.search_vehicle({ "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv"), "search_val": search_val }).then((res) => {
            console.log(res)
            setSearch_veh(res.vehicle_list)
        }).catch((error) => {
            console.log(error)
        })
    }



    const open_trail = (id) => {
        // clearInterval(interval)
        clearInterval(addr_interval)
        setActive_marker(null); setInfo(false)
        setOpen(false)
        setLoader(true)
        setActive_vehicle_id(id)
        setTimeout(() => {
            setOpen(true)
            setLoader(false)
        }, 200);

    }

    useEffect(() => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        } else {
            return (() => {
                clearInterval(interval)
                clearInterval(addr_interval)
                debouncedResults.cancel();

            })
        }
    }, []);




    const clear_auto_complete = () => {
        const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
        if (ele) ele.click();
        console.log(autoC)
        autoC.current.blur()
    }



    const apply = (data, sortval) => {

        setData_prev(data)
        setOpen(false)
        clear_auto_complete()
        const cookies = new Cookies();

        document.getElementsByClassName("gm-ui-hover-effect").innerHTML = "X"
        clearInterval(interval)
        setLoader(true)
        data["page_name"] = "Map"
        data["sort_val"] = sortval;
        var priv = cookies.get("priv")
        // if ((!data.dist_fk) && (priv == 0 || priv == 1)) {
        //     setLoader(false)
        //     setVsumm([])
        //     alert_trigger("info", "Please Select District");
        //     return
        // }
        // else
        //     if (!data.ac_fk) {
        //         setLoader(false)
        //         alert_trigger("info", "Please select Assembly")
        //         return
        //     }
        setAc_fk(data.ac_fk)
        mapData(data, true);
        setPsInfo(false)
        setPstoggle(false)
        setCctoggle(false)
        setPsfence([])
        setCcfence([])
        get_bounds_data(data)
        interv_func(data)
    }

    const interv_func = (data) => {
        console.log(app_conf?.interval?.Map)
        let conf_inter = app_conf?.interval?.Map
        if (isNaN(conf_inter) || conf_inter < 5 || conf_inter == undefined)
            conf_inter = 30;
        clearInterval(interval)
        interval = setInterval(function () {
            console.log("++++++++++++++++++++")
            document.getElementsByClassName("gm-ui-hover-effect").innerHTML = "X"
            mapData(data);

        }, (conf_inter ?? 30) * 1000)
    }


    const mapData = (data, isInit = false) => {
        fetchData.vehicle_list_min(data).then((res) => {
            if (isInit)
                setLoader(false)
            var { vehicle_list } = res;
            var vlist1 = []
            vehicle_list?.map((item) => {
                if (item?.lat != 0 && item?.lon != 0) {
                    vlist1.push(item)
                }
            })
            setVsumm(vlist1)
            let cords = []
            vlist1?.map((item) => {
                cords.push({ "lat": parseFloat(item.lat), "lng": parseFloat(item.lon) })
            })
            if (isInit) {
                var bounds = new props.google.maps.LatLngBounds();
                for (var i = 0; i < cords.length; i++) {
                    bounds.extend(cords[i]);
                }
                setBounds(extendBounds(bounds, 0.1))
            }

        }).catch((error) => {
            if (isInit)
                setLoader(false)
            console.log(error)
        })

    }

    const get_icon = (data) => {
        var type = 0
        var icon;
        if (data.name == "Bus") {
            type = 4
        }
        else if (data.name == "Truck") {
            type = 2
        }
        else if (data.name == "Motorcycle") {
            type = 3
        }
        else {
            type = 1
        }
        if (data.v_stat == "Running") {
            icon = running_img[type]
        }
        else if (data.v_stat == "Idle") {
            icon = idle_img[type]

        }
        else {
            icon = stop_img[type]
        }

        return icon
    }

    const getIconUrl = (icon, item) => {
        // Define color codes based on categories
        const colorMap = {
            "Flying Surveillance Team (FST)": "#FF2400",
            "SST": "#D90E18",
            "Video Surveillance Team (VST)": "#FFAA33",
            "Flying Squad (FS)": null,
            "zonel vehicle": "#00008b",
            "sector vehicle": "#25d5e7",
            "pooling party vehicle": "#ffff00",
            "others": "#FF9933"
        };

        // Determine the color based on item.category
        const backgroundColor = colorMap[item.category];

        // Create SVG markup
        // If backgroundColor is null, we do not include the circle element
        const svgMarkup = backgroundColor ? `
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="20" fill="${backgroundColor}" />
      <image href="${icon}" x="5" y="5" width="30" height="30" />
    </svg>
  ` : `
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <image href="${icon}" x="5" y="5" width="30" height="30" />
    </svg>
        `;

        // Convert SVG markup to a data URL
        return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgMarkup)}`;
    };

    const get_icon1 = (data) => {
        var type = 0
        var icon;
        if (data.name == "Bus") {
            type = 4
        }
        else if (data.name == "Truck") {
            type = 2
        }
        else if (data.name == "Motorcycle") {
            type = 3
        }
        else {
            type = 1
        }
        if (data.v_stat == "Running") {
            icon = list_img[type]
        }
        else if (data.v_stat == "Idle") {
            icon = list_img[type]

        }
        else {
            icon = list_img[type]
        }

        return icon
    }

    const get_bounds_data = (data) => {
        setTrisngleCoords([])
        setToggle(false)
        fetchData.bounding_shape(data).then((res) => {
            var { file_path } = res;
            setFilepath(file_path)
        }).catch((error) => {
            console.log(error)
        })
    }


    const create_bounds = (bool) => {
        setToggle(bool)
        if (bool) {
            fetchData.preSignedUrl(filepath).then((res) => {
                create_bounding_box(res)
            }).catch((error) => {
                console.log(error)
            })
        } else {
            setTrisngleCoords([])
        }
    }


    const create_bounding_box = (data1) => {
        var values = data1.value.split("|")
        for (var j = 0; j < values.length - 1; j++) {
            var latlon = values[j].replace(/\(/g, '').replace(/\)/g, '').replace(/\#/g, ',').split(",")
            var polygon = []
            are = []
            for (var i = 0; i < latlon.length - 1; i = i + 2) {
                are.push({ lat: parseFloat(latlon[i]), lng: parseFloat(latlon[i + 1]) })
            }
            setTrisngleCoords(are)
        }
    }

    const ps_fence_data = (bool) => {
        setPstoggle(bool)
        setPsfence([])
        if (bool) {
            data_prev['icon_type'] = 1
            fetchData.ps_fence_data(data_prev).then((res) => {
                var { route_lst } = res;
                setPsfence(route_lst)
            }).catch((error) => {
                console.log(error)
            });
        }
    }

    const getIconForType = (type) => {
        switch (type) {
            case 1:
                return g_icon;
            case 2:
                return r_icon;
            case 3:
                return b_icon;
            case 4:
                return p_icon;
            case 5:
                return br_icon;
            case 6:
                return bl_icon;
            default:
                return df_icon;
        }
    };



    const cc_fence_data = (bool) => {
        setCctoggle(bool)
        setCcfence([])
        if (bool) {
            data_prev['icon_type'] = 2
            fetchData.ps_fence_data(data_prev).then((res) => {
                var { route_lst } = res;
                setCcfence(route_lst)
            }).catch((error) => {
                console.log(error)
            })
        }
    }


    const handleChangesort = () => {
        let val = sortVal == 0 ? 1 : 0
        setSortVal(val);
        apply(data_prev, val)
    };



    // const splitRoute = (array, chunkSize) => {
    //     const result = [];
    //     for (let i = 0; i < array.length; i += chunkSize) {
    //         result.push(array.slice(i, i + chunkSize));
    //     }
    //     return result;
    // };

    // const view_trip = () => {
    //     setCoords([]);
    //     setTrip_bounds(null);
    //     setTripState({
    //         start_point: {},
    //         end_point: {},
    //         points: []
    //     })
    //     console.log("hellooo trip",tripState)
    //     var data = { "ac_fk": ac_fk };
    //     fetchData.view_all_fence_route(data).then((res) => {
    //         console.log("result macha", res);
    //         var { view_route_lst } = res;
    //         setView_trip_det(view_route_lst);

    //         // Split the route list into chunks of 5
    //         // const tripChunks = splitRoute(view_route_lst, 5);

    //         // Process only the first chunk
    //         // if (tripChunks.length > 0) {
    //             view_route(view_route_lst); // Only process the first chunk
    //         // }
    //     }).catch((error) => {
    //         console.log(error);
    //     });
    // };

    // const view_trip = () => {
    //     setCoords([]);
    //     setTrip_bounds(null);
    //     setTripState({
    //         start_point: {},
    //         end_point: {},
    //         points: []
    //     });
    //     console.log("hellooo trip", tripState);

    //     const data = { "ac_fk": ac_fk };
    //     fetchData.view_all_fence_route(data).then((res) => {
    //         console.log("result macha", res);
    //         const { view_route_lst } = res;
    //         setView_trip_det(view_route_lst);
    //         view_route(view_route_lst);
    //     }).catch((error) => {
    //         console.log(error);
    //     });
    // };

    const view_trip = () => {
        setCoordsArray([]);
        setTrip_bounds(null);
        const data = { "ac_fk": ac_fk };
        fetchData.view_all_fence_route(data).then((res) => {
            const { view_route_lst } = res;
            setView_trip_det(view_route_lst);
            view_route(view_route_lst);
        }).catch((error) => {
            console.log(error);
        });
    };



    // const view_route = (view_route_lst) => {
    //     if (view_route_lst.length > 0) {
    //         let start = view_route_lst[0];
    //         let end = view_route_lst[view_route_lst.length - 1];
    //         let wp = view_route_lst.slice(1, -1); // Get waypoints

    //         console.log(wp);
    //         setTripState({
    //             start_point: start,
    //             end_point: end,
    //             points: wp
    //         });
    //         draw_route_markers(start, end, wp);
    //     }
    // };
    // const view_route = (view_route_lst) => {
    //     if (view_route_lst.length > 0) {
    //         const start = view_route_lst[0];
    //         const end = view_route_lst[view_route_lst.length - 1];
    //         const wp = view_route_lst.slice(1, -1); // Get waypoints

    //         console.log(wp);
    //         setTripState({
    //             start_point: start,
    //             end_point: end,
    //             points: wp
    //         });

    //         draw_polyline([start, ...wp, end]);
    //     }
    // };

    const view_route = (view_route_lst) => {
        const chunkSize = 5;
        const chunks = [];

        // Split the array into chunks of 5
        for (let i = 0; i < view_route_lst.length; i += chunkSize) {
            chunks.push(view_route_lst.slice(i, i + chunkSize));
        }

        // Create coordinates for each chunk
        const newCoordsArray = chunks.map(chunk => {
            if (chunk.length > 0) {
                const start = chunk[0];
                const end = chunk[chunk.length - 1];
                const wp = chunk.slice(1, -1);
                return [start, ...wp, end];
            }
            return [];
        });

        setCoordsArray(newCoordsArray); // Set all coordinates at once
    };
    // const draw_route_markers = (start, end, waypoints) => {
    //     const waypts = waypoints.map(waypoint => ({
    //         location: new window.google.maps.LatLng(waypoint.lat, waypoint.lng),
    //         stopover: true
    //     }));

    //     const directionsService = new window.google.maps.DirectionsService();
    //     directionsService.route({
    //         origin: new window.google.maps.LatLng(start.lat, start.lng),
    //         destination: new window.google.maps.LatLng(end.lat, end.lng),
    //         waypoints: waypts,
    //         travelMode: window.google.maps.TravelMode.DRIVING,
    //         provideRouteAlternatives: true,
    //     }, (result, status) => {
    //         if (status === window.google.maps.DirectionsStatus.OK) {
    //             const path = result.routes[0].overview_path; // Get the path from the result
    //             const newCoords = path.map(item => ({ lat: item.lat(), lng: item.lng() })); // Extract lat/lng from each point

    //             // Update state to store the first set of coordinates
    //             setCoords(newCoords); // Set the coords to the first chunk's coordinates

    //             // Create bounds for the map
    //             const bounds = new window.google.maps.LatLngBounds();
    //             path.forEach(point => bounds.extend(point));
    //             setTrip_bounds(bounds);
    //         } else {
    //             console.error('Error fetching directions:', result);
    //         }
    //     });
    // };

    // const draw_polyline = (coordinates) => {
    //     const path = coordinates.map(point => ({
    //         lat: point.lat,
    //         lng: point.lng
    //     }));

    //     setCoords(prevCoords => [...prevCoords, ...path]); // Append to existing coords

    //     // Create bounds for the map
    //     const bounds = new window.google.maps.LatLngBounds();
    //     path.forEach(point => bounds.extend(new window.google.maps.LatLng(point.lat, point.lng)));
    //     setTrip_bounds(bounds);
    // };

    // const draw_polyline = (coordinates) => {
    //     const path = coordinates.map(point => ({
    //         lat: point.lat,
    //         lng: point.lng
    //     }));

    //     setCoords(path);
    // };
    // const onTripMarkerClick = (props, marker, e, point, label) => {
    //     setLabel(label)
    //     settrip_active_marker(marker)
    //     setTrip_info(true)
    //     setTrip_active_item(point)
    // }

    return (
        <Box sx={classes.container}>
            <Header ></Header>
            <Filters page={location.state} apply={(e) => apply(e, null)} options={"District,Assembly,Category,Status,idleType,Idlemins,toggle,pstoggle,cctoggle,Nav"} changeToggle={(e) => { create_bounds(e) }} getchecked={toggle} changepsToggle={(e) => { ps_fence_data(e) }} getpschecked={pstoggle} changeccToggle={(e) => { cc_fence_data(e) }} getccchecked={cctoggle} setAc_fk={setAc_fk} ></Filters>

            <Box sx={classes.search}>
                <Autocomplete
                    ref={autoC}
                    size='small'
                    freeSolo
                    onChange={onTagsChange}
                    onInputChange={debouncedResults}
                    getOptionLabel={(option) => option.v_no + " ( " + option.imei + " )"}
                    sx={{ width: 280 }}
                    options={search_veh}
                    renderInput={(params) => <TextField  {...params} label="Vehicle No / IMEI" />}
                />
            </Box>
            {
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, position: 'absolute', top: 130, right: 70, }}>
                    {(show_cc_default?.show) &&
                        <Box sx={{ zIndex: 999, backgroundColor: 'white', height: '40px', maxWidth: '400px', display: 'flex' }}>
                            <Typography sx={{ color: 'black', p: 1 }}>{cc_label1} : <span style={{ color: 'red' }}>{cc_reach_data.one_km}</span></Typography>
                            <Typography sx={{ color: 'black', p: 1, pl: 2 }}>{cc_label2} : <span style={{ color: 'red' }}>{cc_reach_data.five_km}</span></Typography>
                        </Box>
                    }
                    {(show_cc_default?.show_trip) &&
                        <Box sx={{ zIndex: 999, backgroundColor: 'white', height: '40px', width: '140px' }}>

                            <FormGroup sx={{ zIndex: 9999, color: 'black', pl: 3 }}>
                                <FormControlLabel control={<Checkbox
                                    sx={{ color: 'black' }}
                                    checked={showTrip}
                                    onChange={(event) => {
                                        setShowTrip(event.target.checked);
                                        if (event.target.checked) {
                                            view_trip(); // Call to fetch and draw the route
                                        } else {
                                            setCoords([]); // Clear the coordinates
                                            setTrip_bounds(null); // Clear bounds
                                            setTripState({
                                                start_point: {},
                                                end_point: {},
                                                points: []
                                            })
                                        }
                                    }}
                                />
                                }
                                    label="Show Trip" />
                            </FormGroup>

                        </Box>
                    }
                </Box>
            }
            {
                data_prev &&
                <Box sx={{ mt: 7.5, ml: 25, mr: 1 }}>
                    <Button
                        variant="contained"
                        endIcon={sortVal === 0 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />} // Conditional rendering of icon
                        onClick={handleChangesort} // Handle button click
                    >
                        Sort
                    </Button>
                </Box>
            }
            <Box sx={classes.vlist}>
                {
                    vsumm?.map((item) => {
                        if (item.vstat == 1) {
                            var bg = colors.running_bg
                            var cl = colors.running_cl
                        }
                        else if (item.vstat == 0) {
                            var bg = colors.idle_bg
                            var cl = colors.idle_cl
                        }
                        else {
                            var bg = colors.offline_bg
                            var cl = colors.offline_cl
                        }
                        var icon = get_icon1(item)

                        return (
                            <Box sx={[classes.v_item, { borderColor: cl, backgroundColor: bg }]} onClick={() => { open_trail(item.vehicle_fk); clear_auto_complete() }}>
                                <Box sx={{ justifyContent: "space-between", display: "flex", flex: 1, p: 0.2, alignItems: "center" }}>
                                    <Typography variant="subtitle1" sx={{ fontSize: 13, flex: 1 }}>{item.v_no} </Typography>
                                    {/* <Avatar sx={[classes.v_icon, { backgroundColor: cl, p: 0.2 }]} src={icon}>
                                    </Avatar> */}
                                    <Typography variant="subtitle1" sx={{ fontSize: 13, flex: 1 }}>AC : {item.ac_name}</Typography>

                                </Box>
                                <Box sx={{ display: "flex", flex: 1, alignItems: "center", borderTop: 1, borderColor: "divider" }}>
                                    <Typography variant="subtitle2" sx={classes.vsum_label}>Category</Typography>
                                    <Typography variant="subtitle1" sx={classes.vsum_value}>{item.category}</Typography>
                                </Box>
                                {/* <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
                                    <Typography variant="subtitle2" sx={classes.vsum_label}>Status</Typography>
                                    <Typography variant="subtitle1" sx={classes.vsum_value}>{item.v_stat}</Typography>
                                </Box> */}
                            </Box>
                        )
                    })
                }

            </Box>
            {loader &&
                <Box sx={[classes.map, classes.loader]}>
                    <ThreeDots
                        height="100"
                        width="100"
                        radius="7"
                        color="#0288d1"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </Box>
            }
            <Box sx={classes.map}>
                <Map
                    initialCenter={{ lat: map_default2?.lat ? map_default2?.lat : map_default1?.lat, lng: map_default2?.lon ? map_default2?.lon : map_default1?.lon }}
                    google={props.google}
                    zoom={map_default2?.zoom ? map_default2?.zoom : map_default1?.zoom}
                    bounds={bounds}
                    onClick={() => { setActive_marker(null); setInfo(false); clearInterval(addr_interval); setPsInfo(false) }}

                >
                    {
                        vsumm?.map((item) => {
                            const icon = get_icon(item);
                            const iconUrl = getIconUrl(icon, item);
                            // var image = RotateIcon.makeIcon(icon).setRotation({ deg: Number(item.cog) }).getUrl();
                            // if (image.length > 1000)
                            //     icon = image;
                            return (
                                <Marker
                                    ref={(marker) => markers[item.vehicle_fk] = marker}
                                    onClick={onMarkerClick}
                                    title={item.v_no}
                                    id={item.vehicle_fk}
                                    position={{ lat: parseFloat(item.lat), lng: parseFloat(item.lon) }}
                                    icon={{
                                        url: iconUrl
                                        // rotation: item.cog
                                    }}
                                >
                                </Marker>
                            )

                        })
                    }
                    {
                        psfence?.map((item) => {
                            var latlon = item.value.replace('(', '').replace(')', '').split(",");
                            var coordinate = { lat: parseFloat(latlon[0]), lng: parseFloat(latlon[1]) };
                            var icon_new = getIconForType(item.gfence_category);
                            return (
                                <Marker
                                    ref={(marker) => markers[item.gfence_pk] = marker}
                                    title={item.gfence_name}
                                    position={coordinate}
                                    onClick={(props, marker, e) => onPsMarkerClick(props, marker, e, item)}
                                    icon={{
                                        url: icon_new,
                                        scaledSize: item?.gfence_category > 0 ? new window.google.maps.Size(64, 64) : new window.google.maps.Size(40, 40)
                                    }}
                                />
                            );
                        })
                    }
                    {
                        ccfence?.map((item) => {
                            var latlon = item.value.replace('(', '').replace(')', '').split(",")
                            var coordinate = { lat: parseFloat(latlon[0]), lng: parseFloat(latlon[1]), };
                            return (
                                <Marker
                                    ref={(marker) => markers[item.gfence_pk] = marker}
                                    title={item.gfence_name}
                                    position={coordinate}
                                    onClick={(props, marker, e) => { onPsMarkerClick(props, marker, e, item) }}
                                    icon={{
                                        url: pin_blue
                                        // rotation: item.cog
                                    }}
                                >
                                </Marker>
                            )
                        })

                    }
                    {
                        ccfence?.map((item) => {
                            var latlon = item.value.replace('(', '').replace(')', '').split(",")
                            var coordinate = { lat: parseFloat(latlon[0]), lng: parseFloat(latlon[1]), };
                            return (
                                <Circle
                                    radius={Number(item?.tollerence)}
                                    center={coordinate}
                                    strokeColor='red'
                                    strokeOpacity={5}
                                    strokeWeight={5}
                                    fillColor='red'
                                    fillOpacity={0.4}
                                />
                            )
                        })

                    }
                    {showTrip && coordsArray.length > 0 && coordsArray.map((coords, index) => (
                        // <React.Fragment key={index}>
                        // <>
                        <Polyline
                            path={coords.map(point => ({ lat: point.lat, lng: point.lng }))}
                            strokeColor="#2b9df4"
                            strokeOpacity={6}
                            strokeWeight={6}
                        />
                        //  </>
                        // {index === 0 && tripState?.start_point && (
                        //     <Marker
                        //         position={{ lat: tripState.start_point.lat, lng: tripState.start_point.lng }}
                        //         onClick={(props, marker, e) => onTripMarkerClick(props, marker, e, tripState.start_point, "Start")}
                        //         icon={{
                        //             url: greendot,
                        //             anchor: new props.google.maps.Point(14, 14),
                        //             scaledSize: new props.google.maps.Size(25, 25),
                        //         }}
                        //     />
                        // )}

                        // {index === coordsArray.length - 1 && tripState?.end_point && (
                        //     <Marker
                        //         position={{ lat: tripState.end_point.lat, lng: tripState.end_point.lng }}
                        //         onClick={(props, marker, e) => onTripMarkerClick(props, marker, e, tripState.end_point, "End")}
                        //         icon={{
                        //             url: reddot,
                        //             anchor: new props.google.maps.Point(14, 14),
                        //             scaledSize: new props.google.maps.Size(25, 25),
                        //         }}
                        //     />
                        // )}

                        // {coords.map((point, pointIndex) => (
                        //     <Marker
                        //         key={pointIndex}
                        //         position={{ lat: point.lat, lng: point.lng }}
                        //         onClick={(props, marker, e) => onTripMarkerClick(props, marker, e, point, `Waypoint - ${pointIndex + 1}`)}
                        //         icon={{
                        //             url: bluedot,
                        //             anchor: new props.google.maps.Point(14, 14),
                        //             scaledSize: new props.google.maps.Size(25, 25),
                        //         }}
                        //     />
                        // ))}
                        // </React.Fragment>
                    ))}


                    <InfoWindow
                        visible={trip_info}
                        marker={trip_active_marker}
                    >
                        <div style={{ width: 200, marginRight: 12, marginBottom: 12, border: "1px solid grey", borderRadius: 5 }}>
                            <div style={classes.info_cont}>
                                <p style={{ textAlign: 'center', ...classes.value }}>{Label} - {active_item.name}</p>
                            </div>

                        </div>
                    </InfoWindow>


                    <InfoWindow
                        visible={info}
                        marker={active_marker}
                    >
                        <div style={{ margin: 2, width: 400, marginRight: 10, marginBottom: 10, border: "1px solid grey", borderRadius: 5, }}>
                            <div style={classes.info_cont}>
                                <p style={classes.label}>Vehicle No</p>
                                <p style={classes.value}>{active_item.v_no}</p>
                            </div>
                            <div style={classes.info_cont}>
                                <p style={classes.label}>Assembly</p>
                                <p style={classes.value}>{active_item.ac_name}</p>
                            </div>
                            <div style={classes.info_cont}>
                                <p style={classes.label}>Category</p>
                                <p style={classes.value}>{active_item.category}</p>
                            </div>

                            <div style={classes.info_cont}>
                                <p style={classes.label}>Vehicle Status (Speed)</p>
                                <p style={classes.value}>{active_item.v_stat} ( {active_item.speed} Km/Hr )</p>
                            </div>

                            <div style={classes.info_cont}>
                                <p style={classes.label}>Last Updated</p>
                                <p style={classes.value}>{active_item?.dt}</p>
                            </div>
                            <div style={classes.info_cont}>
                                <p style={classes.label}>Address</p>
                                <p style={classes.value}>{v_addr[0]?.formattedAddress}</p>
                            </div>
                            <div style={classes.info_cont}>
                                <p style={classes.label}>Sector Off Name</p>
                                <p style={classes.value}>{active_item?.incharge_name}</p>
                            </div>
                            <div style={classes.info_cont}>
                                <p style={classes.label}>Sector Off Mobile No</p>
                                <p style={classes.value}>{active_item?.incharge_no}</p>
                            </div>
                            <div style={classes.info_cont}>
                                <p style={classes.label}>Driver Name</p>
                                <p style={classes.value}>{active_item?.driver_name}</p>
                            </div>
                            <div style={classes.info_cont1}>
                                <p style={classes.label}>Driver Mobile No</p>
                                <p style={classes.value}>{active_item?.driver_no}</p>
                            </div>

                        </div>
                    </InfoWindow>

                    <InfoWindow
                        visible={ps_info}
                        marker={ps_active_marker}
                    >
                        <div style={{ margin: 2, width: 250, marginRight: 10, marginBottom: 10, border: "1px solid grey", borderRadius: 5, }}>
                            <div style={classes.info_cont}>
                                <p style={classes.value_ps}>{activeps?.gfence_name}</p>
                            </div>
                            <div style={classes.info_cont}>
                                <p style={classes.value_ps}>{activeps?.disp_text}</p>
                            </div>
                        </div>
                    </InfoWindow>

                    <Polygon
                        paths={triangleCoords}
                        strokeColor="#FF0000"
                        strokeOpacity={8}
                        strokeWeight={2}
                        fillColor={"#FF0000"}
                        fillOpacity={0.35}
                    />
                </Map>
            </Box>
            {
                open &&
                <SingleMapScreen page="Map" position={{
                    position: "absolute",
                    zIndex: 9999,
                    top: 120,
                    left: 300,
                    right: 0,
                    bottom: 0,
                    flexDirection: "row"
                }} handleClose={handleClose} vehicle_fk={active_vehicle_id} />
            }
        </Box >
    )
}


export default MapScreen({
    apiKey: mapkey.mapKey
})(MapSub)