import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Navigate, useNavigate } from "react-router-dom";
import { Button, TextField, Box, IconButton, MenuItem, Select, FormControl, InputLabel, FormControlLabel, RadioGroup, Radio, InputBase, Divider } from '@mui/material';
import { Map, Polyline, Marker, GoogleApiWrapper as TripF_Create, Circle, InfoWindow } from 'google-maps-react';
import encryptStorage from "../encryptStorage";
import reddot from '../assets/reddot.png'
import LoadingButton from '@mui/lab/LoadingButton';

// Api
import fetchData from '../api/fetchData';
// colors
import colors from '../config/colors';

// map API Key
import mapkey from '../config/map_key';

const classes = {
    map1: {
        position: "absolute",
        top: 60,
        left: 400,
        right: 0,
        bottom: 0,
        zIndex: 2,
        backgroundColor: "white"
    },
}

var mapp;
export const TripF_Create_sub = (props) => {
    const [new_point_name, setNew_point_name] = useState('')
    const [loading, setLoading] = useState(false)

    const [bounds, setBounds] = useState([])
    const [point_coords, setPoint_coords] = useState(props?.point_coords)
    const { enqueueSnackbar } = useSnackbar();


    const appSetting = encryptStorage.getItem("appSetting");
    const map_default1 = { "lat": 16.2160, "lon": 77.3566, "zoom": 6 }
    const map_default2 = appSetting?.map_default

    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }


    useEffect(() => {
        var bounds = new props.google.maps.LatLngBounds();
        bounds.extend(props.point_coords);
        setBounds(extendBounds(bounds, 0.01))
    }, []);

    const extendBounds = (bounds, radius) => {

        var point1 = new props.google.maps.LatLng(
            bounds.getNorthEast().lat() + radius,
            bounds.getNorthEast().lng() + radius
        )
        var point2 = new props.google.maps.LatLng(
            bounds.getSouthWest().lat() - radius,
            bounds.getSouthWest().lng() - radius
        )
        bounds.extend(point1);
        bounds.extend(point2);
        return bounds
    }

    const onMapClicked = (mapProps, map, e) => {
        const coords = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        setPoint_coords(coords)
        var bounds = new props.google.maps.LatLngBounds();
        bounds.extend(coords);
        setBounds(extendBounds(bounds, 0.01))
    }

    const fence_add = () => {
        var val = "";
        if (!point_coords.lat || !point_coords.lng) {
            alert_trigger("error", "Please draw fence ! ")
            return
        }
        val = "(" + point_coords.lat + "," + point_coords.lng + ")#"
        if (!props?.data?.ac_fk) {
            alert_trigger("error", "Please select district, assembly and apply! ")
            return
        }
        if (!new_point_name) {
            alert_trigger("error", "Please enter point name!")
            return
        }
        setLoading(true)

        var data = {}
        data["value"] = val
        data["act"] = "insert"
        data["f_type"] = 1
        data["tollerence"] = 150
        data["gfence_name"] = new_point_name
        data["ac_fk"] = props?.data?.ac_fk

        fetchData.put_fence(data).then((res) => {
            var { rval, msg } = res;
            console.log(res)
            if (rval > 0) {
                alert_trigger("success", msg)
                props?.onRefresh()
                props.onCancel()
            }
            else {
                alert_trigger("error", msg)
            }
            setLoading(false)

        }).catch((error) => {
            console.log(error)
            setLoading(false)
            alert_trigger("error", "Someting went wrong")
        })
    }


    const fence_cancel = () => {
        setPoint_coords(null)
        props.onCancel()
    }

    return (
        <Box sx={classes.map1}>
            <Map
                onClick={(onMapClicked)}
                ref={(mapr) => mapp = mapr}
                bounds={bounds}
                initialCenter={{ lat: map_default2?.lat ? map_default2?.lat : map_default1?.lat, lng: map_default2?.lon ? map_default2?.lon : map_default1?.lon }}
                google={props.google}
                zoom={map_default2?.zoom ? map_default2?.zoom : map_default1?.zoom}
            >
                {(point_coords?.lat && point_coords?.lng) &&
                    <Marker
                        id={1}
                        position={point_coords}
                        icon={{
                            url: reddot,
                            anchor: new props.google.maps.Point(16, 16),
                            scaledSize: new props.google.maps.Size(30, 30),
                        }}
                    >
                    </Marker>
                }
            </Map>
            <Box
                sx={{ position: "absolute", bottom: 10, right: 70, display: 'flex', alignItems: 'center', backgroundColor: colors.dark1, borderRadius: 1, height: 70, py: 1, px: 2, display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <TextField
                    sx={{ mr: 1, width: 200 }}
                    size='small'
                    label='Point Name'
                    placeholder="Point Name"
                    value={new_point_name}
                    onChange={(e) => { setNew_point_name(e.target.value) }}
                />
                {loading ?
                    <LoadingButton
                        sx={{ width: 150, mr: 1 }}
                        loading
                        loadingPosition="end"
                        variant="contained"

                    >
                        Loading..
                    </LoadingButton> :
                <Button variant="contained" fullWidth sx={{ width: 150, mr: 1 }} onClick={() => { fence_add() }}>Submit</Button>
            }
                <Button variant="contained" fullWidth sx={{ width: 150 }} onClick={() => { fence_cancel() }}>Cancel</Button>
            </Box>
        </Box>
    )
}

export default TripF_Create({
    apiKey: mapkey.mapKey
})(TripF_Create_sub)