import React from 'react';
import { useState, useEffect, useRef } from "react";
import { Button, TextField, Box, Typography, DialogActions, Avatar, IconButton } from '@mui/material';
import { Map, InfoWindow, Marker, GoogleApiWrapper as Reportmap } from 'google-maps-react';
import CloseIcon from '@mui/icons-material/Close';

// map API Key
import mapkey from '../config/map_key';

// Styles
const classes = {
    container: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        flexDirection: "row",
        zIndex:999,
        backgroundColor:"white"
    },
    closebtn: {
        position: "absolute",
        top: 0,
        alignSelf: "center",
        zIndex: 999,
        backgroundColor: "white",
        left: '49%',
        boxShadow: 2,
        color: "grey"

    }
}




var marker;
var info;

export const MapSub = (props) => {
    var mapp = useRef();

    useEffect(() => {
        draw_marker()
    }, []);


    const draw_marker = () => {
        var bounds = new props.google.maps.LatLngBounds();
        var coords = props.coords
        bounds.extend(coords);
        mapp.map.fitBounds(bounds);
        marker = new props.google.maps.Marker({
            position: props.coords,
            map: mapp.map,
        });
        info = new props.google.maps.InfoWindow();
        info.open({
            anchor: marker,
            map: mapp.map,
            shouldFocus: true,
        });
        info.setContent(content(props.item))
    }




    const content = (item) => {
        var contentString = '<div style="margin: 2; width: 400px; margin-right: 10px; margin-bottom: 10px; border: 1px solid grey;border-radius: 5px">'
        for (var i = 0; i < item.length; i++) {
            contentString += '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> ' + item[i].label + '</p><p  style="font-size:12px;flex:1;font-weight:400">' + item[i].value + ' </p></div>'
        }
        contentString += '</div>'
        return contentString
    }

    return (
        <Box sx={classes.container}>
            <Map
                initialCenter={{ lat: 20.5937, lng: 78.9629 }}
                google={props.google}
                zoom={14}
                ref={(mapr) => mapp = mapr}
            >

            </Map>
            <IconButton sx={classes.closebtn} onClick={props.handleClose}>
                <CloseIcon />
            </IconButton>
        </Box>
    )
}


export default Reportmap({
    apiKey:mapkey.mapKey
})(MapSub)

