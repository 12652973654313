import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Navigate, useNavigate } from "react-router-dom";
import { Button, TextField, Box, Typography, DialogActions, IconButton, DialogTitle, Dialog, DialogContent, MenuItem, Select, FormControl, InputLabel, FormControlLabel, Checkbox } from '@mui/material';
import { Map, Polyline, Marker, GoogleApiWrapper as RouteAssign, Circle, InfoWindow } from 'google-maps-react';
import { ThreeDots } from 'react-loader-spinner'
import Cookies from 'universal-cookie';
import reddot from '../assets/reddot.png'
import greendot from '../assets/green_dot1.png'
import bluedot from '../assets/blue_dot.png'
import encryptStorage from "../encryptStorage";

//Icons

import VisibilityIcon from '@mui/icons-material/Visibility';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

// components
import { Header } from './component/header';
import { Filters } from './component/filters';
// colors
import colors from '../config/colors';

// map API Key
import mapkey from '../config/map_key';

// Api
import fetchData from '../api/fetchData';

// Styles

const classes = {
    container: {
        position: "absolute",
        top: 170,
        left: 0,
        right: 0,
        bottom: 0,
        flexDirection: "row"
    },
    map: {
        position: "absolute",
        top: 120,
        left: 350,
        right: 0,
        bottom: 0,
    },
    vlist: {
        position: "absolute",
        top: 170,
        left: 0,
        width: 350,
        bottom: 0,
        backgroundColor: colors.dark1,
        overflow: "auto", overflowY: "scroll",
        justifyContent: "center",
    },
    v_item: {
        flex: 1,
        display: "flex",
        p: 0.8,
        borderRadius: 1,
        pl: 1.5,
        m: 1,
        justifyContent: "space-between",
        border: 1,
        borderColor: "divider",
        alignItems: "center"
    },
    vsum_value:
    {
        fontSize: 13, flex: 1, color: "grey.300"
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99999,
        backgroundColor: "rgba(0,0,0,0.7)"
    },
    filter: {
        m: 1,
    },
    info_cont: {
        display: "flex",
        border: 1,
        borderBottom: "1px solid grey",
        paddingLeft: 8,
        paddingRight: 8
    },
    info_cont1: {
        display: "flex",
        border: 1,
        paddingLeft: 8,
        paddingRight: 8
    },
    label: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400,
    },
    value: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400
    },
    container1: {
        flex: 1,
    },
    filter1: {
        width: 200,
        my: 4,
        mr: 4
    },
    checkbox: {
        width: 200,
        mr: 4
    },
    full_width: {
        my: 2, mr: 1
    },
    no_data: {
        m: 1
    }
}



export const Assign = (props) => {
    const [loader, setLoader] = useState(false)
    const [route_list, setRoute_list] = useState([]);
    const [edit_route_list, setEdit_route_list] = useState([]);
    const [bounds, setBounds] = useState([])
    const [assign_fence, setAssign_fence] = useState(false)
    const [datas, setDatas] = useState({})
    const [coords, setCoords] = useState([])
    const [points, setPoints] = useState([])
    const [Label, setLabel] = useState(null);
    const [active_marker, setActive_marker] = useState(null)
    const [active_item, setActive_item] = useState({})
    const [info, setInfo] = useState(false)

    const appSetting = encryptStorage.getItem("appSetting");
    const map_default1 = { "lat":16.2160, "lon":  77.3566, "zoom": 6 }
    const map_default2 = appSetting?.map_default

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [start_point, setStart_point] = useState({})
    const [end_point, setEnd_point] = useState({})
    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }

    useEffect(() => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        }
    }, []);


    const apply = (data) => {
        if (!data.ac_fk) {
            alert_trigger("error", "Please select assembly")
            return
        }
        if (!data.category_fk) {
            alert_trigger("error", "Please select category")
            return
        }
        setDatas(data)
        fetchData.get_trips(data).then((res) => {
            var { trips } = res;
            setRoute_list(trips)
            setLoader(false)

        }).catch((error) => {
            console.log(error)
        })
    }


    const view_route = (item) => {
        var data = { "gfence_pk": item.gfence_pk }
        fetchData.view_fence_route(data).then((res) => {
            var { view_route_lst } = res;
            setStart_point(view_route_lst[0])
            setEnd_point(view_route_lst[view_route_lst.length - 1])
            if (view_route_lst.length > 0) {
                let start = view_route_lst[0]
                let end = view_route_lst[view_route_lst.length - 1]
                let wp = view_route_lst
                wp.splice(0, 1)
                wp.splice(wp.length - 1, 1)
                setPoints(wp)

                draw_route_markers(start, end, wp)
            }

        }).catch((error) => {
            console.log(error)
        })
    }


    const draw_route_markers = (start, end, waypoints) => {
        const waypts = waypoints.map(waypoint => ({
            location: new window.google.maps.LatLng(waypoint.lat, waypoint.lng),
            stopover: true
        }));

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route({
            origin: start,
            destination: end,
            waypoints: waypts,
            travelMode: window.google.maps.TravelMode.DRIVING,
            provideRouteAlternatives: true,

        }, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                const path = result.routes[0].overview_path;
                setCoords(path);
                var res_ar = []
                path.map((items) => {
                    res_ar.push({ "lat": items.lat(), "lon": items.lng() })
                })
                console.log("res_ar", res_ar)
                const bounds = new window.google.maps.LatLngBounds();
                result.routes[0].overview_path.forEach(function (point) {
                    bounds.extend(point);
                    setBounds(bounds)
                });
            } else {
                console.error('Error fetching directions:', result);
            }
        });
    };

    const onMarkerClick = (props, marker, e, point, label) => {
        setLabel(label)
        setActive_marker(marker)
        setInfo(true)
        setActive_item(point)
    }


    return (
        <>
            <Header ></Header>
            <Filters page={"Trip"} options={"District,Assembly,Category,Apply,Nav"} btn={"Add"} apply={apply}></Filters>
            <Box sx={{ position: "absolute", top: 120, left: 0, width: 350, height: 50, justifyContent: "center",}} display="flex" >
                <Button onClick={() => { navigate("/route-fence") }} sx={{ borderBottom: 4, width: 120, borderColor:'#1A1A1A', borderRadius: 0, flex: 1 }}>Create</Button>
                <Button sx={{ borderBottom: 4, width: 120, borderColor: "primary", borderRadius: 0, flex: 1 }}>Assign</Button>
            </Box>

            {route_list?.length == 0 ?
                <Box sx={[classes.vlist, { display: "flex", justifyContent: "center", alignItems: "center" }]}>
                    <Typography variant="subtitle2" sx={classes.no_data}>Please select filters to view data</Typography>
                </Box>
                : <Box sx={classes.vlist}>
                    {
                        route_list?.map((item) => {
                            return (
                                <Box sx={classes.v_item} >
                                    <Box sx={{ flex: 5 }}>
                                        <Typography variant="subtitle1" sx={classes.vsum_value}>{item.gfence_name}</Typography>
                                    </Box>
                                    <IconButton >
                                        <VisibilityIcon onClick={() => { view_route(item) }} sx={{ color: "grey.400" }} />
                                    </IconButton>
                                    <IconButton onClick={() => { setAssign_fence(true); setEdit_route_list(item) }} >
                                        <PlaylistAddIcon sx={{ color: "grey.400" }} />
                                    </IconButton>
                                </Box>
                            )
                        })
                    }

                </Box>
            }
            {loader &&
                <Box sx={[classes.map, classes.loader]}>
                    <ThreeDots
                        height="100"
                        width="100"
                        radius="7"
                        color="#0288d1"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </Box>}
            <Box sx={classes.map}
            >
                <Map
                    bounds={bounds}
                    initialCenter={{ lat:map_default2?.lat?map_default2?.lat:map_default1?.lat, lng: map_default2?.lon?map_default2?.lon:map_default1?.lon }}
                    google={props.google}
                    zoom={map_default2?.zoom?map_default2?.zoom:map_default1?.zoom}
                >
                    {start_point.lat && start_point.lng && (
                        <Marker
                            position={{ lat: start_point.lat, lng: start_point.lng }}
                            onClick={(props, marker, e) => onMarkerClick(props, marker, e, start_point, "Start")}
                            icon={{
                                url: greendot,
                                anchor: new props.google.maps.Point(14, 14),
                                scaledSize: new props.google.maps.Size(25, 25),
                            }}
                        />
                    )}
                    {end_point.lat && end_point.lng && (
                        <Marker
                            position={{ lat: end_point.lat, lng: end_point.lng }}
                            onClick={(props, marker, e) => onMarkerClick(props, marker, e, end_point, "End")}
                            icon={{
                                url: reddot,
                                anchor: new props.google.maps.Point(14, 14),
                                scaledSize: new props.google.maps.Size(25, 25),
                            }}
                        />
                    )}
                    {points.map((waypoint, index) => (
                        waypoint.lat && waypoint.lng && (
                            <Marker
                                key={index}
                                onClick={(props, marker, e) => onMarkerClick(props, marker, e, waypoint, `Waypoint - ${index + 1}`)}
                                position={{ lat: waypoint.lat, lng: waypoint.lng }}
                                icon={{
                                    url: bluedot,
                                    anchor: new props.google.maps.Point(14, 14),
                                    scaledSize: new props.google.maps.Size(25, 25),
                                }}
                            />
                        )
                    ))}
                    <Polyline
                        path={coords}
                        strokeColor="green"
                        strokeOpacity='6.0'
                        strokeWeight='4'
                    />
                    <InfoWindow
                        visible={info}
                        marker={active_marker}
                    >
                        <div style={{ width: 200, marginRight: 12, marginBottom: 12, border: "1px solid grey", borderRadius: 5 }}>
                            <div style={classes.info_cont}>
                                <p style={{ textAlign: 'center', ...classes.value }}>{Label} - {active_item.name}</p>
                            </div>

                        </div>
                    </InfoWindow>
                </Map>
                {assign_fence &&
                    <Assign_fence open={true} onClose={() => { setAssign_fence(false) }} fence_val={edit_route_list} apply={apply} />
                }
            </Box>
        </>
    )
}




export function Assign_fence(props) {
    const cookies = new Cookies();
    var fence_val = props.fence_val;
    const [veh, setVeh] = useState([])
    const [rfence_vmap, setRfence_vmap] = useState({})


    const { enqueueSnackbar } = useSnackbar();


    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }

    useEffect(() => {
        get_v_map_data(fence_val?.gfence_pk)
        get_vehicle()

    }, []);

    const get_v_map_data = (fence_id) => {
        if (fence_id) {
            fetchData.get_rfence_vmap({ "fence_id": fence_id }).then((res) => {
                var { rfence_vmap } = res;
                setRfence_vmap(rfence_vmap)
            }).catch((error) => {
                console.log(error)
            })
        }
    }


    const get_vehicle = () => {
        fetchData.vehicle({ "ac_fk": fence_val?.ac_fk, "category_fk": fence_val?.cat_fk, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }).then((res) => {
            var { vehicle } = res;
            vehicle.unshift({ "label": "SELECT", "value": 0 });
            setVeh(vehicle)
        }).catch((error) => {
            console.log(error)
        })
    }


    const route_fence_veh_put = () => {
        if (!rfence_vmap?.vehicle_fk) {
            alert_trigger("error", "Please select vehicle")
            return;
        }
        if (!rfence_vmap?.idlet) {
            alert_trigger("error", "Please enter valid idle time limit")
            return;
        }
        if (rfence_vmap?.sms_alert) {
            if (rfence_vmap?.mobile_no?.length == 0) {
                alert_trigger("error", "Please fill mobile number")
                return;
            }
        }
        rfence_vmap["rfence_fk"] = fence_val?.gfence_pk
        if (!rfence_vmap?.sms_alert) {
            rfence_vmap["sms_alert"] = 0
        }
        if (!rfence_vmap?.mobile_no) {
            rfence_vmap["mobile_no"] = null
        }
        console.log(rfence_vmap)

        fetchData.put_rfence_vmap(rfence_vmap).then((res) => {
            var { rval, msg } = res;
            if (rval > 0) {
                alert_trigger("success", msg)
                props.onClose()
            }
            else {
                alert_trigger("error", msg)
            }
        }).catch((error) => {
            console.log(error)
        })
    }


    return (
        <Dialog
            open={true}
            onClose={props.onClose}
        >
            <Box sx={{ width: 450 }}>
                <DialogTitle sx={{ fontSize: 15 }}>Assign Route Fence</DialogTitle>
                <DialogContent >
                    <Box sx={classes.container1}>
                        <Box sx={{ display: "flex", flex: 1, width: 1, mt: 2 }}>
                            <Box sx={{ display: "flex", flex: 1, width: 1, flexDirection: "column" }}>
                                <Typography variant="subtitle2" sx={{ fontSize: 12, ml: 0.5 }}>District</Typography>
                                <Box sx={{ height: 40, width: "96%", backgroundColor: "divider", borderRadius: 2, display: "flex", alignItems: "center", pl: 2 }}>
                                    <Typography variant="subtitle1" sx={classes.vsum_value}>{fence_val?.dname}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", flex: 1, width: 1, flexDirection: "column" }}>
                                <Typography variant="subtitle2" sx={{ fontSize: 12, ml: 0.5 }}>Assembly</Typography>
                                <Box sx={{ height: 40, width: "96%", backgroundColor: "divider", borderRadius: 2, display: "flex", alignItems: "center", pl: 2 }}>
                                    <Typography variant="subtitle1" sx={classes.vsum_value}>{fence_val?.ac_name}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", flex: 1, width: 1, mt: 2 }}>
                            <Box sx={{ display: "flex", flex: 1, width: 1, flexDirection: "column" }}>
                                <Typography variant="subtitle2" sx={{ fontSize: 12, ml: 0.5 }}>Category</Typography>
                                <Box sx={{ height: 40, width: "98%", backgroundColor: "divider", borderRadius: 2, display: "flex", alignItems: "center", pl: 2 }}>
                                    <Typography variant="subtitle1" sx={classes.vsum_value}>{fence_val?.category}</Typography>
                                </Box>
                            </Box>

                        </Box>
                        <Box sx={{ display: "flex", flex: 1, width: 1, mt: 3 }}>
                            <FormControl size='small' sx={{ flex: 1, mr: 1 }}>
                                <InputLabel >Vehicle</InputLabel>
                                <Select
                                    size='small'
                                    label="Vehicle"
                                    value={Number(rfence_vmap?.vehicle_fk)}
                                    onChange={(e) => { setRfence_vmap({ ...rfence_vmap, vehicle_fk: e.target.value }); }}
                                >
                                    {
                                        veh?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl size='small' sx={{ flex: 1, mx: 1 }}>
                                <TextField
                                    value={rfence_vmap?.idlet}
                                    size='small'
                                    InputLabelProps={{ shrink: true }}
                                    label="Idle Time Limit (mins)"
                                    onChange={(e) => { setRfence_vmap({ ...rfence_vmap, idlet: e.target.value }); }}
                                    variant="outlined" />
                            </FormControl>

                        </Box>
                        <Box sx={{ display: "flex", flex: 1, width: 1, mt: 2 }}>
                            <FormControlLabel control={<Checkbox checked={rfence_vmap?.sms_alert ? true : false} />}
                                label="Allow Mobile Alerts"
                                sx={classes.checkbox}
                                onChange={(e) => { setRfence_vmap({ ...rfence_vmap, sms_alert: e.target.checked }); }}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={true} />}
                                label="Allow Web Alerts"
                                sx={classes.checkbox}
                            />
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            {rfence_vmap?.sms_alert ?
                                <FormControl size='small' sx={{ flex: 1, mr: 1, mt: 2 }}>
                                    <TextField
                                        value={rfence_vmap?.mobile_no}
                                        size='small'
                                        type="number"
                                        InputLabelProps={{ shrink: true }}
                                        label="Mobile Number"
                                        onChange={(e) => { setRfence_vmap({ ...rfence_vmap, mobile_no: e.target.value }); }}
                                        variant="outlined" />
                                </FormControl> : null
                            }
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            <Button variant="contained" sx={classes.full_width} onClick={() => { route_fence_veh_put() }} fullWidth>Submit</Button>
                            <Button variant="contained" sx={classes.full_width} onClick={() => { props.onClose() }} fullWidth>Close</Button>
                        </Box>
                    </Box>
                </DialogContent>

            </Box>
        </Dialog>
    )
}



export default RouteAssign({
    apiKey: mapkey.mapKey
})(Assign)