import React from 'react';
import { useRef, useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Box, Typography, DialogActions, TextField, IconButton, DialogTitle, Dialog, DialogContent, MenuItem, Select, FormControl, InputLabel, FormControlLabel, Checkbox } from '@mui/material';
import { Map, InfoWindow, Marker, GoogleApiWrapper as FenceScreen, Circle, Polygon } from 'google-maps-react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { ThreeDots } from 'react-loader-spinner'
import Cookies from 'universal-cookie';
import SearchIcon from '@mui/icons-material/Search';
import encryptStorage from "../encryptStorage";

// components
import { Header } from './component/header';
import { Filters } from './component/filters';

//Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import reddot from '../assets/reddot.png'

// colors
import colors from '../config/colors';

// map API Key
import mapkey from '../config/map_key';

// Api
import fetchData from '../api/fetchData';

// Styles
const classes = {
    container: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        flexDirection: "row"
    },

    map: {
        position: "absolute",
        top: 120,
        left: 450,
        right: 0,
        bottom: 0,
    },
    vlist: {
        position: "absolute",
        top: 220,
        left: 0,
        width: 450,
        bottom: 0,
        backgroundColor: colors.dark1,
        overflow: "auto", overflowY: "scroll",
        justifyContent: "center",
    },
    fields: {
        position: "absolute",
        top: 120,
        left: 0,
        width: 450,
        height: 100,
        backgroundColor: colors.dark1,
        overflow: "auto",
        justifyContent: "center",
        flexDirection: "column",
        px: 1,
        borderBottom: 1,
        borderColor: "divider"
    },
    v_item: {
        flex: 1,
        display: "flex",
        p: 0.8,
        borderRadius: 1,
        pl: 1.5,
        m: 1,
        justifyContent: "space-between",
        border: 1,
        borderColor: "divider",
        alignItems: "center"
    },
    vsum_value:
    {
        fontSize: 13, flex: 1, color: "grey.300"
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99999,
        backgroundColor: "rgba(0,0,0,0.7)"
    },
    filter: {
        m: 1,
    },
    coord_search: {
        display: 'flex', boxShadow: 1, backgroundColor: "white",
        borderRadius: 1, ml: 8, width: 300, mt: 5
    },

    search_btn_dialog: {
        boxShadow: 1,
        height: 41, width: 41, borderRadius: 1,
        position: "absolute", top: 130, right: 65, zIndex: 999,
        backgroundColor: "white", color: "grey",
        "&:hover": {
            backgroundColor: 'white'
        }
    }
}

var are = []
var shape;
var mapp;

export const Fence = (props) => {
    const [is_circle, setIsCircle] = useState(false)
    const [coords, setCoords] = useState({})
    const [radius, setRadius] = useState(1000)
    const [loader, setLoader] = useState(false)
    const [fence_list, setFence_list] = useState([]);
    const [bounds, setBounds] = useState([])
    const [zoom, setZoom] = useState(8)
    const [active_item, setActive_item] = useState({ 'tollerence': 100 })
    const [edit, setEdit] = useState(false)
    const [fence_alert, setFence_alert] = useState(false)
    const [open, setOpen] = useState(false);
    const [alert_det, setAlert_det] = useState({})
    const [delete_fk, setDelete_fk] = useState('')
    const [delete_alert, setDelete_alert] = useState(false)
    const [veh, setVeh] = useState([])
    const [veh_val, setVeh_val] = useState(0)
    const [fence_type, setFence_type] = useState(1)
    const [fence_area, setFence_area] = useState([])
    const [search_lat, setSearch_lat] = useState("")
    const [search_lon, setSearch_lon] = useState("")
    const [filter_data, setFilter_data] = useState({})
    const [cat, setCat] = useState([])
    const [cat_val, setCat_val] = useState(0)
    const [selectedOption, setSelectedOption] = useState('select');
    const [startLocation, setStartLocation] = useState(null);
    const cookies = new Cookies();
    const inputStartRef = useRef();


    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const appSetting = encryptStorage.getItem("appSetting");
    const map_default1 = { "lat":16.2160, "lon":  77.3566, "zoom": 6 }
    const map_default2 = appSetting?.map_default


    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }

    useEffect(() => {
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        }
        get_category()
    }, []);

    const get_category = () => {
        fetchData.category({}).then((res) => {
            var { category } = res;
            if (category.length > 1) {
                category.unshift({ "label": "All", "value": 0 });
            }
            if (category.length == 1) {
                setCat_val(category[0].value)
            }
            setCat(category)

        }).catch((error) => {
            console.log(error)
        })
    }

    const get_vehicle = (ac_fk, cat_fk) => {
        console.log(ac_fk)
        if (!ac_fk) {
            return
        }
        setVeh_val(0)
        fetchData.vehicle({ "ac_fk": ac_fk, "category_fk": cat_fk, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }).then((res) => {
            var { vehicle } = res;
            vehicle.unshift({ "label": "All", "value": 0 });
            setVeh(vehicle)
        }).catch((error) => {
            console.log(error)
        })
    }

    const onMapClicked = (mapProps, map, e) => {
        if (fence_type == 2) {
            are.push({ lat: e.latLng.lat(), lng: e.latLng.lng() })
            if (shape)
                shape.setMap(null);
            shape = new props.google.maps.Polygon({
                paths: are,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
            });
            shape.setMap(mapp.map);
        }
        else {
            console.log(e.latLng.lat().toString())
            console.log(e.latLng.lng().toString())
            const coords = { lat: e.latLng.lat(), lng: e.latLng.lng() };
            setCoords(coords)
            setIsCircle(true)
        }
    }

    const search_loc = () => {
        if (inputStartRef.current && inputStartRef.current.getPlaces) {
            const startPlaces = inputStartRef.current.getPlaces();
            if (startPlaces && startPlaces.length > 0) {
                const startPlace = startPlaces[0];
                setSearch_lat(startPlace.geometry.location.lat);
                setSearch_lon(startPlace.geometry.location.lng);
            }
        }
    }

    const search_coord = () => {
        if (!search_lat || !search_lon) {
            alert_trigger("error", "Please enter valid coordinates !")
            return
        }
        const coords = { lat: parseFloat(search_lat), lng: parseFloat(search_lon) };
        setCoords(coords)
        setIsCircle(true)
        var bounds = new props.google.maps.LatLngBounds();
        bounds.extend(coords);
        setBounds(extendBounds(bounds, 0.01))
        setZoom(1)
        setOpen(false);
    }

    const handleClose = () => {
        setFence_alert(false);

    };

    const handleAlertClose = () => {
        setDelete_alert(false)
    }

    useEffect(() => {
    }, []);

    const apply = (data) => {
        console.log(data)
        console.log("apply ", data)
        setFilter_data(data)
        var priv = cookies.get("priv")
        // if ((!data.dist_fk || !data.ac_fk) && (priv == 0 || priv == 1)) {
        //     setLoader(false)
        //     alert_trigger("info", "Please select District and Assembly")
        //     return
        // }
        // if (!data.ac_fk) {
        //     setLoader(false)
        //     alert_trigger("info", "Please select Assembly")
        // if ((!data.dist_fk || !data.ac_fk) && (priv == 0 || priv == 1)) {
        //     setLoader(false)
        //     alert_trigger("info", "Please select District and Assembly")
        //     return
        // }
        // }
        //  if (!data.dist_fk) {
        //     setLoader(false)
        //     alert_trigger("info", "Please select District")
        //     return
        // }
        fetchData.get_fence(data).then((res) => {
            var { fence_list } = res;
            setFence_list(fence_list)
            setLoader(false)

        }).catch((error) => {
            console.log(error)
        })
    }

    const edit_fence = (item) => {
        setEdit(true)
        setActive_item(item)
        setCoords([])
        setIsCircle(false)
        setFence_type(item.fence_type_fk)
        if (shape)
            shape.setMap(null);
        var { value, tollerence } = item
        if (item.fence_type_fk == 1) {
            var latlon = value.replace('(', '').replace(')', '').split(",")
            var coordinate = { lat: parseFloat(latlon[0]), lng: parseFloat(latlon[1]), };
            console.log(coordinate)
            setCoords(coordinate)
            setRadius(tollerence)
            setIsCircle(true)
            var bounds = new props.google.maps.LatLngBounds();
            bounds.extend(coordinate);
            setBounds(extendBounds(bounds, 0.01))
            setZoom(1)
        }
        else if(item.fence_type_fk == 4) {
            console.log("+++++++++++")
            var values=value.split("|")
            console.log(values.length)
            for(var j=0;j<values.length-1;j++){
                var latlon = values[j].replace(/\(/g, '').replace(/\)/g, '').replace(/\#/g, ',').split(",")
                var polygon = []
                are = []
                for (var i = 0; i < latlon.length - 1; i = i + 2) {
                    are.push({ lat: parseFloat(latlon[i]), lng: parseFloat(latlon[i + 1]) })
                }
                // if (shape)
                //     shape.setMap(null);
                let sp = new props.google.maps.Polygon({
                    paths: are,
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.35,
                });
                sp.setMap(mapp.map);
                var bounds = new props.google.maps.LatLngBounds();
                for (var i = 0; i < are.length; i++) {
                    bounds.extend(are[i]);
                }
                setBounds(bounds)
            }
            
        }
        else {
            var latlon = value.replace(/\(/g, '').replace(/\)/g, '').replace(/\#/g, ',').split(",")
            var polygon = []
            are = []
            for (var i = 0; i < latlon.length - 1; i = i + 2) {
                are.push({ lat: parseFloat(latlon[i]), lng: parseFloat(latlon[i + 1]) })
            }
            if (shape)
                shape.setMap(null);
            shape = new props.google.maps.Polygon({
                paths: are,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
            });
            shape.setMap(mapp.map);
            var bounds = new props.google.maps.LatLngBounds();
            for (var i = 0; i < are.length; i++) {
                bounds.extend(are[i]);
            }
            setBounds(bounds)
        }
    }

    const change_type = (e) => {
        setFence_type(e)
        setCoords([])
        setIsCircle(false)
        are = []
        if (shape)
            shape.setMap(null);
    }

    const extendBounds = (bounds, radius) => {

        var point1 = new props.google.maps.LatLng(
            bounds.getNorthEast().lat() + radius,
            bounds.getNorthEast().lng() + radius
        )
        var point2 = new props.google.maps.LatLng(
            bounds.getSouthWest().lat() - radius,
            bounds.getSouthWest().lng() - radius
        )
        bounds.extend(point1);
        bounds.extend(point2);
        return bounds
    }

    const add_edit_fence = (typ) => {
        console.log(typ)
        var val = "";
        if (fence_type == 1) {
            if (!coords.lat || !coords.lng) {
                alert_trigger("error", "Please draw fence ! ")
                return
            }
            val = "(" + coords.lat + "," + coords.lng + ")#"
        }
        else {
            if (are.length < 1) {
                alert_trigger("error", "Please draw fence ! ")
                return
            }
            are.map((item) => {
                val = val + '(' + item.lat + ',' + item.lng + ")#";
            })
        }
        if (!filter_data.ac_fk) {
            alert_trigger("error", "Please select district, assembly and apply! ")
            return
        }
        console.log(filter_data)

        var data = active_item
        data["value"] = val
        data["act"] = typ
        data["f_type"] = fence_type
        if (typ == "insert") {
            data["ac_fk"] = filter_data.ac_fk
            // data["cat_fk"] = filter_data.category_fk
        }

        if (!data["cat_fk"]) {
            // data["cat_fk"] = 0
        }

        if (!data.value || !data.gfence_name || !data.tollerence) {
            alert_trigger("error", "Please fill all the required fields")
            return
        }
        console.log(data)
        fetchData.put_fence(data).then((res) => {
            var { rval, msg } = res;
            console.log(res)
            if (rval > 0) {
                alert_trigger("success", msg)
                apply(filter_data)
            }
            else {
                alert_trigger("error", msg)
            }

        }).catch((error) => {
            console.log(error)
            alert_trigger("error", "Someting went wrong")
        })
    }

    const delete_fence = (val) => {
        fetchData.delete_fence({ "gfence_pk": val }).then((res) => {
            var { rval, msg } = res;
            console.log(res)
            if (rval > 0) {
                alert_trigger("success", msg)
                apply(filter_data)
            }
            else {
                alert_trigger("error", msg)
            }
            setDelete_alert(false)
        }).catch((error) => {
            console.log(error)
            alert_trigger("error", "Someting went wrong")
            setDelete_alert(false)
        })
    }

    const assign_fence = () => {
        var det = alert_det
        det["fence_id"] = active_item.gfence_pk
        det["ac_fk"] = active_item.ac_fk
        det["vehicle_fk"] = veh_val
        det["cat_fk"] = cat_val
        if (!det["fence_alert_id"]) {
            det["fence_alert_id"] = 0
            det["act"] = "insert"
        }
        else {
            det["act"] = "update"
        }
        console.log(det)
        fetchData.put_fence_alert(det).then((res) => {
            var { rval, msg } = res;
            console.log(res)
            if (rval > 0) {
                alert_trigger("success", msg)
                apply(filter_data)
            }
            else {
                alert_trigger("error", msg)
            }
            setFence_alert(false)
        }).catch((error) => {
            console.log(error)
            alert_trigger("error", "Someting went wrong")
            setFence_alert(false)
        })
    }

    const get_fence_alert = (fence_id) => {
        fetchData.get_fence_alert({ "fence_id": fence_id }).then((res) => {
            var { f_alert_list } = res;
            if (f_alert_list) {
                setAlert_det(f_alert_list)
                setVeh_val(f_alert_list.vehicle_fk)
                setCat_val(f_alert_list.cat_fk)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const clear_fence = () => {
        setEdit(false);
        setCoords([]);
        setActive_item({ ...active_item, ["gfence_name"]: "", ["gfence_pk"]: 0 })
        setCoords([])
        setIsCircle(false)
        are = []
        if (shape)
            shape.setMap(null);
    }

    const handleSearchClickOpen = () => {
        setOpen(true);
    };

    const handleSearchClose = () => {
        setOpen(false);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <Box sx={classes.container}>
            <Header ></Header>
            <IconButton variant="contained" sx={classes.search_btn_dialog} onClick={handleSearchClickOpen}  >
                <SearchIcon />
            </IconButton>
            {fence_type == 1 &&
                <IconButton variant="contained" sx={classes.search_btn_dialog} onClick={handleSearchClickOpen}  >
                    <SearchIcon />
                </IconButton>
            }
            <Dialog open={open} onClose={handleSearchClose}>
                <DialogTitle sx={{ fontSize: 15 }}>Search Your Location</DialogTitle>
                <DialogContent sx={{ width: 450, flexDirection: "coloumn" }}>
                    <FormControl fullWidth size='small' sx={{ mt: 2 }}>
                        <InputLabel>Search by</InputLabel>
                        <Select
                            fullWidth
                            MenuProps={{ style: { zIndex: 35001 } }}
                            defaultValue='select'
                            size='small'
                            value={selectedOption}
                            onChange={handleSelectChange}
                            label="Search by"
                        >
                            <MenuItem value="select">Select</MenuItem>
                            <MenuItem value="latlong">Latitude & Longitude</MenuItem>
                            <MenuItem value="address">Address</MenuItem>
                        </Select>
                    </FormControl>
                    {selectedOption === 'latlong' && (
                        <Box sx={{ display: "flex", mt: 4 }}>
                            <TextField
                                sx={{ flex: 1, mr: 1 }}
                                size='small'
                                label='Lattitude'
                                placeholder="Latitude"
                                value={search_lat}
                                onChange={(e) => { setSearch_lat(e.target.value) }}
                            />
                            <TextField
                                size='small'
                                label='Longitude'
                                sx={{ flex: 1, ml: 1 }}
                                placeholder="Longitude"
                                value={search_lon}
                                onChange={(e) => { setSearch_lon(e.target.value) }}
                            />
                        </Box>)}
                    {selectedOption === 'address' && (
                        <StandaloneSearchBox
                            onLoad={ref => {
                                inputStartRef.current = ref;
                            }}
                            onPlacesChanged={() => { search_loc() }}
                        >
                            <TextField
                                placeholder='Enter Your Address'
                                size='small'
                                fullWidth
                                sx={{ mt: 4 }}
                            />
                        </StandaloneSearchBox>
                    )}
                    <Box sx={{ display: "flex", mt: 4 }}>
                        <Button onClick={() => { search_coord() }} fullWidth variant="contained" sx={{ mr: 1, flex: 1 }}>Search</Button>
                        <Button onClick={handleSearchClose} fullWidth variant="contained" sx={{ ml: 1, flex: 1 }}>Cancel</Button>
                    </Box>



                </DialogContent>

            </Dialog>

            <Filters page={location.state} apply={apply} options={"District,Assembly,Fencetype,Nav"} ></Filters>
            <Box sx={classes.vlist}>
                {
                    fence_list?.map((item) => {
                        return (
                            <Box sx={classes.v_item} >
                                <Typography variant="subtitle1" sx={classes.vsum_value}>{item.gfence_name} ({item.fence_type})</Typography>
                                <IconButton >
                                    <EditIcon onClick={() => { edit_fence(item) }} sx={{ color: "grey.400" }} />
                                </IconButton>
                                <IconButton onClick={() => { get_vehicle(item.ac_fk, item.cat_fk); setAlert_det({}); setActive_item(item); setFence_alert(true); get_fence_alert(item.gfence_pk); setVeh_val(0); setVeh([]) }}>
                                    <NotificationsActiveIcon sx={{ color: "grey.400" }} />
                                </IconButton>
                                <IconButton >
                                    <DeleteIcon sx={{ color: colors.offline_cl }} onClick={() => { setDelete_alert(true); setDelete_fk(item.gfence_pk) }} />
                                </IconButton>
                            </Box>
                        )
                    })
                }
            </Box>

            <Box sx={classes.fields}>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mt: 1, flex: 1 }}>
                    <FormControl size='small' sx={{ flex: 1 }}
                    >
                        <InputLabel >Fence Type</InputLabel>
                        <Select
                            sx={{ flex: 1, mr: 1 }}
                            size='small'
                            label="Fence Type"
                            value={fence_type}
                            onChange={(e) => { change_type(e.target.value) }}
                        >
                            <MenuItem value={1}>Point</MenuItem>
                            <MenuItem value={2}>Area</MenuItem>

                        </Select>
                    </FormControl>
                    <TextField
                        sx={{ flex: 1, mr: 1 }}
                        value={active_item?.gfence_name}
                        size='small'
                        InputLabelProps={{ shrink: true }}
                        label="Name"
                        onChange={(e) => {
                            console.log(e)
                            setActive_item({ ...active_item, ["gfence_name"]: e.target.value })
                        }}
                        variant="outlined" />
                    <TextField
                        sx={{ flex: 0.8 }}
                        value={active_item?.tollerence}
                        size='small'
                        InputLabelProps={{ shrink: true }}
                        label="Radius(Meter)"
                        onChange={(e) => {
                            console.log(e)
                            setActive_item({ ...active_item, ["tollerence"]: e.target.value })
                        }}
                        variant="outlined" />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mt: 1, flex: 1 }}>
                    {/* <Button variant="contained" fullWidth onClick={() => add_edit_fence("insert")} sx={{ mr: 0.5 }}>Add New</Button>
                    <Button variant="contained" fullWidth onClick={() => add_edit_fence("update")} sx={{ ml: 0.5 }}>Update</Button> */}
                    {
                        active_item.gfence_pk ?
                            <>
                                <Button variant="contained" fullWidth onClick={() => add_edit_fence("update")} sx={{ ml: 0.5 }}>Update</Button>
                                <Button variant="contained" fullWidth onClick={() => clear_fence()} sx={{ ml: 0.5 }}>Reset</Button>

                            </>
                            :
                            <Button variant="contained" fullWidth onClick={() => add_edit_fence("insert")} sx={{ ml: 0.5 }}>Add New</Button>
                    }
                </Box>
            </Box>

            {
                loader &&
                <Box sx={[classes.map, classes.loader]}>
                    <ThreeDots
                        height="100"
                        width="100"
                        radius="7"
                        color="#0288d1"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </Box>
            }
            <Box sx={classes.map}
            >
                <Map
                    ref={(mapr) => mapp = mapr}

                    bounds={bounds}
                    onClick={(onMapClicked)}
                    initialCenter={{ lat:map_default2?.lat?map_default2?.lat:map_default1?.lat, lng: map_default2?.lon?map_default2?.lon:map_default1?.lon }}
                    google={props.google}
                    zoom={map_default2?.zoom?map_default2?.zoom:map_default1?.zoom}
                >
                    {is_circle &&
                        <Circle
                            radius={Number(active_item?.tollerence)}
                            center={coords}
                            strokeColor='red'
                            strokeOpacity={5}
                            strokeWeight={5}
                            fillColor='red'
                            fillOpacity={0.2}
                        />
                    }
                    {is_circle &&

                        <Marker
                            id={1}
                            position={coords}
                            icon={{
                                url: reddot,
                                anchor: new props.google.maps.Point(16, 16),
                                scaledSize: new props.google.maps.Size(25, 25),
                            }}
                        >
                        </Marker>
                    }
                </Map>

                {fence_alert &&
                    <Dialog open={true} onClose={handleClose}>
                        <DialogTitle sx={{ fontSize: 15 }}>Fence Alert</DialogTitle>
                        <DialogContent sx={{ width: 400, display: "flex", flexDirection: "column" }}>
                            <TextField size='small' label="District" sx={classes.filter} variant="outlined" value={active_item.dname} disabled={true} />
                            <TextField size='small' label="Assembly" sx={classes.filter} variant="outlined" value={active_item.ac_name} disabled={true} />
                            {/* <TextField size='small' label="Category" sx={classes.filter} variant="outlined" value={active_item.category} disabled={true} /> */}
                            <FormControl size='small' sx={classes.filter}>
                                <InputLabel >Category</InputLabel>
                                <Select

                                    size='small'
                                    label="Category"
                                    value={cat_val}
                                    onChange={(e) => { setCat_val(e.target.value); get_vehicle(active_item.ac_fk, e.target.value) }}
                                >
                                    {
                                        cat?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>

                            <FormControl size='small' sx={classes.filter}>
                                <InputLabel >Vehicle</InputLabel>
                                <Select
                                    size='small'
                                    label="Vehicle"
                                    value={veh_val}
                                    onChange={(e) => { setVeh_val(e.target.value) }}
                                >
                                    {
                                        veh?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <Box sx={{ display: "flex", ml: 2 }}>
                                <FormControlLabel sx={{ flex: 1 }} control={<Checkbox onChange={() => { setAlert_det({ ...alert_det, ["entry"]: !alert_det?.entry }) }} checked={alert_det.entry ? true : false} />} label="Entry" />
                                <FormControlLabel sx={{ flex: 1 }} control={<Checkbox onChange={() => { setAlert_det({ ...alert_det, ["exit"]: !alert_det?.exit }) }} checked={alert_det.exit ? true : false} />} label="Exit" />
                                {/* <FormControlLabel sx={{ flex: 1 }} control={<Checkbox onChange={() => { setAlert_det({ ...alert_det, ["sms"]: !alert_det?.sms }) }} checked={alert_det.sms ? true : false} />} label="SMS Alert" /> */}

                            </Box>
                            {/* {alert_det.sms &&
                                <TextField onChange={(e) => { setAlert_det({ ...alert_det, ["mobile1"]: e.target.value }) }} type='number' size='small' label="Mobile" sx={classes.filter} variant="outlined" value={alert_det.mobile1} />
                            } */}

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={() => assign_fence()} >Assign</Button>
                        </DialogActions>
                    </Dialog>
                }

                {
                    delete_alert &&
                    <Dialog
                        open={true}
                        onClose={handleAlertClose}
                    >
                        <Box sx={{ width: 450 }}>
                            <DialogTitle sx={{ fontSize: 15 }}>Delete Fence</DialogTitle>
                            <DialogContent >
                                <Typography variant="subtitle2" sx={{ mt: 2 }} >Are you sure you want to delete fence data?</Typography>
                            </DialogContent>
                            <DialogActions sx={{ pr: 3, pb: 2 }}>
                                <Button onClick={handleAlertClose}>Cancel</Button>
                                <Button onClick={() => { delete_fence(delete_fk) }} autoFocus>
                                    Delete
                                </Button>
                            </DialogActions>
                        </Box>
                    </Dialog>
                }
            </Box>
        </Box>
    )
}


export default FenceScreen({
    apiKey: mapkey.mapKey
})(Fence)