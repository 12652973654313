import React from 'react';
import { useState, useEffect } from "react";
import { Box, Typography } from '@mui/material';
import { Map, Marker, InfoWindow } from 'google-maps-react';
import { running_img, idle_img, stop_img } from '../screens/image'

var mapp;

const classes = {
    label: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400,
    },
    value: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400
    },
    info_cont: {
        display: "flex",
        border: 1,
        borderBottom: "1px solid grey",
        paddingLeft: 8,
        paddingRight: 8
    },
    info_cont1: {
        display: "flex",
        border: 1,
        paddingLeft: 8,
        paddingRight: 8
    },
}
export const Map_multi = (props) => {
    const [info, setInfo] = useState(false)
    const [active_marker, setActive_marker] = useState(null)

    useEffect(() => {
    }, []);


    const onMarkerClick = (props, marker, e) => {
        setInfo(true)
        setActive_marker(marker)
    }


    const get_icon = (data) => {
        var type = 0
        var icon;
        if (data.name == "Bus") {
            type = 4
        }
        else if (data.name == "Truck") {
            type = 2
        }
        else if (data.name == "Motorcycle") {
            type = 3
        }
        else {
            type = 1
        }
        if (data.v_stat == "Running") {
            icon = running_img[type]
        }
        else if (data.v_stat == "Idle") {
            icon = idle_img[type]

        }
        else {
            icon = stop_img[type]
        }

        return icon
    }

    return (
        <Box sx={props?.sx}>
            <Map
                initialCenter={{ lat:parseFloat(props?.data?.lat), lng: parseFloat(props?.data?.lon) }}
                google={props.google}
                zoom={17}
                center={{ lat: parseFloat(props?.data?.lat), lng: parseFloat(props?.data?.lon) }}
                ref={(mapr) => mapp = mapr}
            >
                <Marker
                    title={props?.data?.v_no}
                    id={props?.data?.vsumm_pk_id}
                    position={{ lat: parseFloat(props?.data?.lat), lng: parseFloat(props?.data?.lon) }}
                    icon={{
                        url: get_icon(props?.data),
                    }}
                    ref={(marker) => marker = marker}
                    onClick={onMarkerClick}
                >
                </Marker>
                <InfoWindow
                    visible={info}
                    marker={active_marker}

                >
                    <div style={{ margin: 2, flex: 1, marginRight: 10, marginBottom: 10, border: "1px solid grey", borderRadius: 5, width: 250 }}>
                        <div style={classes.info_cont}>
                            <p style={classes.label}>Vehicle No</p>
                            <p style={classes.value}>{props?.data?.v_no}</p>
                        </div>

                        <div style={classes.info_cont}>
                            <p style={classes.label}>Category</p>
                            <p style={classes.value}>{props?.data?.category}</p>
                        </div>
                        <div style={classes.info_cont}>
                            <p style={classes.label}>Speed</p>
                            <p style={classes.value}>{props?.data?.speed} Km/Hr</p>
                        </div>
                        <div style={classes.info_cont}>
                            <p style={classes.label}>Vehicle Status</p>
                            <p style={classes.value}>{props?.data?.v_stat}</p>
                        </div>
                        <div style={classes.info_cont}>
                            <p style={classes.label}>Last Updated</p>
                            <p style={classes.value}>{props?.data?.dt}</p>
                        </div>
                    </div>
                </InfoWindow>
            </Map>
            <Box sx={{ height: 30, width: 1, position: "absolute", bottom: 0, zIndex: 999, backgroundColor: "#424242", alignItems: "center", display: "flex", alignItems: "center", justifyContent: "center", }}>
                <Typography sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                    fontWeight: 400, 
                    fontSize: 17,
                    ml:1.5
                }}
                    title={"V No : " + props?.data?.v_no + " , DT : " + props?.data?.dname + ", AC : " + props?.data?.ac_name + ", CT : " + props?.data?.category + ", ST : " + props?.data?.v_stat}
                    nowrap >
                    {"V No : " + props?.data?.v_no + " , DT : " + props?.data?.dname + ", AC : " + props?.data?.ac_name + ", CT : " + props?.data?.category + ", ST : " + props?.data?.v_stat}
                </Typography>

            </Box>
        </Box>
    )
}

