import * as React from 'react';
import { Box, Typography, Fab, IconButton } from '@mui/material';
import { useState, useEffect, useRef } from "react";
import encryptStorage from "../../encryptStorage";
import Cookies from 'universal-cookie';

// Icons
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// api
import fetchData from "../../api/fetchData";

// colors
import colors from "../../config/colors";

import { UserContext } from '../../App';


//Styles
const classes = {
    container: {
        display: "flex",
    },
    notification: {
        position: "absolute",
        right: 60,
        width: 20,
        height: 20,
        margin:'16px'
    },
}


var interval_alert;
var NFW;


export function Live_alert(props) {
    const { alert_open, setAlert_open } = React.useContext(UserContext);
    const { alert_val, setAlert_val } = React.useContext(UserContext);
    const [scroll, setScroll] = useState('paper');
    const cookies = new Cookies();

    useEffect(() => {
        if (alert_open) {
            get_alert()
        }
        return (() => {
            clearInterval(interval_alert)
        })
    }, []);

    const handleClickOpen = (scrollType) => () => {
        setAlert_open(true);
        setScroll(scrollType);
        get_alert()
    };

    const handleClose = () => {
        setAlert_open(false);
        clearInterval(interval_alert)
    };

    const alert_expand = () => {
        setAlert_open(false);
        clearInterval(interval_alert)
        openPage("/notification")
    }


    function openPage(openPage) {
        var windowW = window.screen.availWidth;
        var windowH = window.screen.availHeight;
        var ews = "width=" + windowW + ",height=" + (windowH - 10);
        var style = "toolbar=no,status=yes,location=no,border=1,menubar=no,scrollbars=yes,resizable=no,maximized=yes,managepassword=no" + ews;
        var urlPop = openPage;
        NFW = window.open(urlPop, "popFrameless", style);
        NFW.moveTo(-3, -3);
        NFW.resizeTo(NFW.screen.availWidth + 18, NFW.screen.availHeight + 8);
        if (NFW != null) {
            window.opener = window;
        } else {
            document.write('<BR><BR><br><br><br><TABLE  align="center" border="0" width=100%><tr align="center"><td class="userInfo" align="center"><img src="/img/warningIcon.gif"></img>&nbsp;&nbsp;&nbsp;&nbsp;<B>Please enable Pop-up in your Browser.</B>&nbsp;</td></tr><tr align="center"><td>&nbsp;&nbsp;&nbsp;&nbsp;<B>Click on Tools->Pop-up Blocker->Turn off Pop-up Blocker.</B>&nbsp;</td></tr><tr align="center"><td>&nbsp;&nbsp;&nbsp;&nbsp&nbsp;</td></tr></TABLE>');
        }
        NFW.focus();
    }

    const get_alert = () => {
        const appSetting = encryptStorage.getItem("appSetting");

        const conf_inter = appSetting?.live_alert?.interval
        console.log(conf_inter, "conf_inter")
        if (isNaN(conf_inter) || conf_inter < 5 || conf_inter == undefined)
            conf_inter = 30;

        const conf_limit = appSetting?.live_alert?.limit
        console.log(conf_limit, "conf_limit")

        if (isNaN(conf_limit) || conf_limit == undefined)
            conf_limit = 30;

        var data = { "lim": conf_limit, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }
        fetchData.notification_alerts(data).then((res) => {
            setAlert_val(res.alerts)
            get_addr(res.alerts)
        }).catch((error) => {
            console.log(error)
        })
        interval_alert = setInterval(function () {
            fetchData.notification_alerts(data).then((res) => {
                setAlert_val(res.alerts)
                get_addr(res.alerts)
            }).catch((error) => {
                console.log(error)
            })
        }, (conf_inter ?? 30) * 1000)
    }

    const get_addr = (alert_list) => {
        var cords = []
        alert_list?.map((item) => {
            cords.push({ "lat": parseFloat(item.lat), "lon": parseFloat(item.lon), "id": item.id })
        })
        fetchData.v_addr({ "latlon": cords }).then((res) => {
            var addr = res.addr
            var addr_alert_list = []
            alert_list?.map((item) => {
                var it = item
                var act = addr.find(({ id }) => id == item.id);
                it["pattern"] = it["pattern"].replace("<addr>", act.formattedAddress)
                addr_alert_list.push(item)
            })
            setAlert_val(addr_alert_list)

        }).catch((error) => {
            console.log(error)
        })
    }


    return (
        <>
            <IconButton sx={classes.notification} onClick={handleClickOpen('paper')}>
                <NotificationsActiveIcon sx={{ width: 20, height: 20, color: colors.red_cl }} />
            </IconButton>
            {
                alert_open &&
                <Box
                    sx={{
                        position: "absolute",
                        top: 70,
                        width: 450,
                        height: 250,
                        right: 1,
                        backgroundColor: colors.dark1,
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: 2,
                        boxShadow: 4,
                        zIndex: 9999
                    }
                    }
                >
                    <Box sx={{ width: 1, display: "flex", justifyContent: "space-between", px: 2, flex: 1, borderBottom: 1, borderColor: "divider", alignItems: "center", py: 0.5 }}>
                        <Typography variant="subtitle2"  >Alerts</Typography>
                        <Box>
                            <IconButton
                                aria-label="close"
                                onClick={alert_expand}
                            >
                                <InfoOutlinedIcon sx={{ height: 20, width: 20 }} />
                            </IconButton>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                            >
                                <CloseOutlinedIcon sx={{ height: 20, width: 20 }} />
                            </IconButton>
                        </Box>
                    </Box>
                    {alert_val.length > 0 ?
                        <Box sx={{ m: 2, p: 0, overflowY: "scroll", flex: 5 }}>
                            {
                                alert_val?.map((element, index) => (
                                    <Box sx={{ p: 1, border: 1, borderColor: "divider", mb: 1 }}>
                                        <Typography variant="subtitle2" sx={{ fontSize: 13 }}>{element?.pattern}</Typography>
                                    </Box>
                                ))
                            }
                        </Box> :
                        <Box sx={{ m: 2, p: 0, overflowY: "scroll", flex: 5, display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography sx={{ fontSize: 14, color: "#7d7979" }}>No Alerts</Typography>
                        </Box>}
                </Box>
            }
        </>
    )
}

