import React from 'react';
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Box, Typography, Grid } from '@mui/material';
import { ThreeDots } from 'react-loader-spinner'
import Cookies from 'universal-cookie';

import encryptStorage from "../encryptStorage";
// components
import { Header } from './component/header';
import { Filters } from './component/filters';
// colors
import colors from '../config/colors';

//icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// Api
import fetchData from '../api/fetchData';

const classes = {
    container: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    data_table: {
        position: "absolute",
        top: 130,
        left: 10,
        right: 10,
        bottom: 10,
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll"

    },
    loader: {
        position: "absolute",
        top: 60,
        left: 10,
        right: 10,
        bottom: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99999,
        backgroundColor: "rgba(0,0,0,0.7)"
    },
    tot_container: {
        display: "flex", alignSelf: "center", width: "40%", backgroundColor: colors.dark1, p: 2
    },
    grid_inter: {
        flex: 1, p: 2, display: "flex", alignItems: "center", flexDirection: "column",
    },
    label: {
        fontSize: 14,
        fontWeight: 400,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
        whiteSpace: "nowrap"

    },
    value: {
        fontSize: 14,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',

    }
}

var interval;

export default function Dashboard(props) {
    const cookies = new Cookies();
    const [dash, setDash] = useState([]);
    const [loader, setLoader] = useState(true)
    const [display_type, setDisplay_type] = useState("all")
    const [dist, setDist] = useState({})
    const [ac, setAc] = useState({})
    const [total_dash, setTotal_dash] = useState({})
    const [v_stat, setV_stat] = useState({ "total": "Total", "running": "", "idle": "", "offline": "" })
    const navigate = useNavigate();
    const location = useLocation();
    const [app_conf, setApp_conf] = useState(encryptStorage.getItem("appSetting"));


    useEffect(() => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        }
        else if (cookies.get("priv") == 2) {
            setDisplay_type("dist")
            setDist({ "id": cookies.get("priv"), "name": "" })
            v_status()
            apply({ "display_type": "dist", "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }, "")
        }
        else {
            v_status()
            apply({ "display_type": display_type, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }, "")
        }
        return (() => {
            clearInterval(interval)
        })

    }, []);


    const v_status = () => {
        fetchData.v_status({}).then((res) => {
            var { v_status } = res;
            let v_stat = {}
            if (v_status.length > 0) {
                v_stat["total"] = "Total"
                v_stat["running"] = v_status[0]?.label
                v_stat["idle"] = v_status[1]?.label
                v_stat["offline"] = v_status[2]?.label

            }
            setV_stat(v_stat)

        }).catch((error) => {
            console.log(error)
        })
    }

    const apply = (data, ac) => {
        let conf_inter = app_conf?.interval?.Dash
        if (isNaN(conf_inter) || conf_inter < 5 || conf_inter == undefined)
            conf_inter = 30;
        clearInterval(interval)
        api_call(data, ac)
        interval = setInterval((marker) => {
            api_call(data, ac)
        }, (conf_inter ?? 30) * 1000)


    }

    const api_call = (data, ac) => {
        fetchData.dashboard(data).then((res) => {
            var { dash } = res;
            setDash(dash)
            if (ac == "ac")
                calc_total1(dash)
            else
                calc_total(dash)

            setLoader(false)

        }).catch((error) => {
            console.log(error)
        })
    }

    const calc_total = (dash) => {
        var tot = 0;
        var idle = 0;
        var running = 0;
        var offline = 0;
        dash?.map((item) => {
            tot += item.total
            idle += item.idle
            running += item.running
            offline += item.offline
        })
        setTotal_dash({ "total": tot, "idle": idle, "running": running, "offline": offline })
    }

    const calc_total1 = (dash) => {
        var tot = 0;
        var idle = 0;
        var running = 0;
        var offline = 0;
        dash?.map((item) => {
            tot++
            if (item.v_stat == v_stat?.idle)
                idle++
            else if (item.v_stat == v_stat?.running)
                running++
            else
                offline++
        })
        setTotal_dash({ "total": tot, "idle": idle, "running": running, "offline": offline })
    }


    const get_ac = (item) => {
        setLoader(true)
        setDisplay_type("dist")
        setDist({ "id": item.district_pk_id, "name": item.dname })
        apply({ "display_type": "dist", "dist_fk": item.district_pk_id, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }, "")
    }

    const go_back = () => {
        setLoader(true)
        if (display_type == "dist") {
            setDisplay_type("all")
            apply({ "display_type": "all", "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") })
        }
        else {
            setDisplay_type("dist")
            apply({ "display_type": "dist", "dist_fk": dist.id, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }, "")
        }
    }

    const get_vehicle = (item) => {
        console.log(item)
        setLoader(true)
        setDisplay_type("ac")
        setAc({ "id": item.ac_pk_id, "name": item.ac_name })
        apply({ "display_type": "ac", "ac_fk": item.ac_pk_id, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }, "ac")
    }




    return (
        <Box sx={classes.container}>
            <Header ></Header>
            <Filters page={location.state} options={"Nav"}></Filters>
            {loader && <Box sx={classes.loader}>
                <ThreeDots
                    height="100"
                    width="100"
                    radius="7"
                    color="#0288d1"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                /></Box>}
            <Box sx={classes.data_table}>
                <Box sx={classes.tot_container}>
                    {(display_type == "dist" && cookies.get("priv") != 2) &&
                        <Box sx={[classes.grid_inter, { backgroundColor: 'black', flex: 4 }]}>
                            <Typography variant="subtitle1" sx={classes.value} >{dist?.name}</Typography>
                            <Typography variant="subtitle1" sx={classes.label}>District</Typography>
                        </Box>}
                    {display_type == "ac" &&
                        <Box sx={[classes.grid_inter, { backgroundColor: 'black', flex: 4 }]}>
                            <Typography variant="subtitle1" sx={classes.value}>{ac?.name}</Typography>
                            <Typography variant="subtitle1" sx={classes.label}>Assembly</Typography>
                        </Box>}
                    <Box sx={[classes.grid_inter, { backgroundColor: '#444' }]}>
                        <Typography variant="subtitle1" sx={classes.value}>{total_dash?.total}</Typography>
                        <Typography variant="subtitle1" sx={classes.label}>{v_stat?.total}</Typography>
                    </Box>
                    <Box sx={[classes.grid_inter, { backgroundColor: colors.running_cl }]}>
                        <Typography variant="subtitle1" sx={classes.value}>{total_dash?.running}</Typography>
                        <Typography variant="subtitle1" sx={classes.label}>{v_stat?.running}</Typography>
                    </Box>
                    <Box sx={[classes.grid_inter, { backgroundColor: colors.idle_cl }]}>
                        <Typography variant="subtitle1" sx={classes.value}>{total_dash?.idle}</Typography>
                        <Typography variant="subtitle1" sx={classes.label}>{v_stat?.idle}</Typography>
                    </Box>
                    <Box sx={[classes.grid_inter, { backgroundColor: colors.offline_cl }]}>
                        <Typography variant="subtitle1" sx={classes.value}>{total_dash?.offline}</Typography>
                        <Typography variant="subtitle1" sx={classes.label}>{v_stat?.offline}</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {display_type == "all" || (display_type == "dist" && cookies.get("priv") == 2) ? null :
                        <Button startIcon={<ArrowBackIcon />} sx={{ width: 100, backgroundColor: colors.dark1, color: "white" }} onClick={() => { go_back() }}>
                            Back
                        </Button>
                    }
                    {display_type == "all" ?
                        <Box sx={{ width: 100, ml: 2 }}>
                            <Typography variant="subtitle1" >District</Typography>
                        </Box> :
                        display_type == "dist" ?
                            <Box sx={{ width: 100, ml: 2 }}>
                                <Typography variant="subtitle1" >Assembly</Typography>
                            </Box> :
                            <Box sx={{ width: 100, ml: 2 }}>
                                <Typography variant="subtitle1" >Vehicle</Typography>
                            </Box>
                    }
                </Box>
                {display_type != "ac" ?
                    <Grid container sx={{ backgroundColor: colors.dark1, mt: 2, p: 0.8 }}>
                        {
                            dash?.map((item) => {
                                if (display_type == "all") {
                                    var name = item.dname
                                }
                                else {
                                    var name = item.ac_name

                                }
                                return (
                                    <Grid onClick={() => {
                                        if (display_type == "all") {
                                            get_ac(item)
                                        }
                                        else if (display_type == "dist") {
                                            get_vehicle(item)
                                        }
                                    }} item xs={4} sx={{ display: "flex", alignSelf: "center", backgroundColor: colors.dark1, p: 0.8, cursor: "pointer" }}>
                                        <Box sx={[classes.grid_inter, { backgroundColor: "black", p: 1, flex: 3.5, justifyContent: "center", overflow: "hidden" }]}>
                                            <Typography variant="subtitle1" sx={classes.value}>{name}</Typography>
                                        </Box>
                                        <Box sx={[classes.grid_inter, { backgroundColor: "#444", p: 1, }]}>
                                            <Typography variant="subtitle1" sx={classes.value}>{item.total}</Typography>
                                            <Typography variant="subtitle1" sx={classes.label}>{v_stat?.total}</Typography>
                                        </Box>
                                        <Box sx={[classes.grid_inter, { backgroundColor: colors.running_cl, p: 1, }]}>
                                            <Typography variant="subtitle1" sx={classes.value}>{item.running}</Typography>
                                            <Typography variant="subtitle1" sx={classes.label}>{v_stat?.running}</Typography>
                                        </Box>
                                        <Box sx={[classes.grid_inter, { backgroundColor: colors.idle_cl, p: 1, }]}>
                                            <Typography variant="subtitle1" sx={classes.value}>{item.idle}</Typography>
                                            <Typography variant="subtitle1" sx={classes.label}>{v_stat?.idle}</Typography>
                                        </Box>
                                        <Box sx={[classes.grid_inter, { backgroundColor: colors.offline_cl, p: 1, }]}>
                                            <Typography variant="subtitle1" sx={classes.value}>{item.offline}</Typography>
                                            <Typography variant="subtitle1" sx={classes.label}>{v_stat?.offline}</Typography>
                                        </Box>
                                    </Grid>)
                            })
                        }
                    </Grid> :
                    <Grid container sx={{ backgroundColor: colors.dark1, mt: 2, p: 0.8 }}>
                        {
                            dash?.map((item) => {

                                return (
                                    <Grid item xs={4} sx={{ display: "flex", alignSelf: "center", backgroundColor: colors.dark1, p: 0.8, }}>
                                        <Box sx={[classes.grid_inter, { backgroundColor: "black", justifyContent: "center", borderRight: 0.5, borderColor: "divider", p: 0 }]}>
                                            <Box sx={{ borderTop: 0.5, borderColor: "divider", flex: 1, width: 1, display: "flex", justifyContent: "center", alignItems: "center", p: 0.5, }}>
                                                <Typography variant="subtitle1" sx={[classes.label, { fontSize: 12, }]}>Vehicle No</Typography>
                                            </Box>
                                            <Box sx={{ flex: 1, width: 1, display: "flex", justifyContent: "center", alignItems: "center", p: 0.5 }}>
                                                <Typography variant="subtitle1" sx={classes.value}>{item.v_no}</Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={[classes.grid_inter, { backgroundColor: "black", p: 1, justifyContent: "center", borderRight: 0.5, borderColor: "divider", p: 0 }]}>
                                            <Box sx={{ borderTop: 0.5, borderColor: "divider", flex: 1, width: 1, display: "flex", justifyContent: "center", alignItems: "center", p: 0.5 }}>
                                                <Typography variant="subtitle1" sx={[classes.label, { fontSize: 12 }]}>Category</Typography>
                                            </Box>
                                            <Box sx={{ flex: 1, width: 1, display: "flex", justifyContent: "center", alignItems: "center", p: 0.5 }}>
                                                <Typography variant="subtitle1" sx={classes.value}>{item.category}</Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={[classes.grid_inter, { borderRight: 0.5, borderColor: "divider", backgroundColor: item.v_stat == "Idle" ? colors.idle_cl : item.v_stat == "Running" ? colors.running_cl : colors.offline_cl, p: 0, }]}>
                                            <Box sx={{ borderTop: 0.5, borderColor: "divider", flex: 1, width: 1, display: "flex", justifyContent: "center", alignItems: "center", p: 0.5 }}>
                                                <Typography variant="subtitle1" sx={[classes.label, { fontSize: 12 }]}>Vehicle Status</Typography>
                                            </Box>
                                            <Box sx={{ flex: 1, width: 1, display: "flex", justifyContent: "center", alignItems: "center", p: 0.5 }}>
                                                <Typography variant="subtitle1" sx={classes.value}>{item.v_stat}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>)
                            })
                        }
                    </Grid>
                }
            </Box>
        </Box >
    )
}