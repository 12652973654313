import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider, useSnackbar } from 'notistack';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton } from '@mui/material';
import "./index.css";

function SnackbarCloseButton({ snackbarKey }) {
    const { closeSnackbar } = useSnackbar();
    return (
        <IconButton onClick={() => closeSnackbar(snackbarKey)}>
            <CloseOutlinedIcon sx={{ color: "white", width: 18, height: 18 }} />
        </IconButton>
    );
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <SnackbarProvider autoHideDuration={3000} maxSnack={3} action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey}  iconVariant={{
        success: '✅',
        error: '✖️',
        warning: '⚠️',
        info: 'ℹ️',
      }}/>}>
        <App />
    </SnackbarProvider>
);

reportWebVitals();
