import React from 'react';
import { useState, useEffect, useRef } from "react";
import { useSnackbar } from 'notistack';
import { Navigate, useNavigate } from "react-router-dom";
import { Button, Box, Typography, DialogActions, TextField, IconButton, DialogTitle, Dialog, DialogContent, MenuItem, Select, FormControl, InputLabel, FormControlLabel, Checkbox } from '@mui/material';
import { StandaloneSearchBox } from '@react-google-maps/api';

export const Location_search_popup = (props) => {

    const [selectedOption, setSelectedOption] = useState('select');
    const [search_lat, setSearch_lat] = useState("")
    const [search_lon, setSearch_lon] = useState("")

    const inputStartRef = useRef();
    const { enqueueSnackbar } = useSnackbar();

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }


    const search_coord = () => {
     
        if (!search_lat || !search_lon) {
            alert_trigger("error", "Please enter valid coordinates !")
            return
        }
        const coords = { lat: parseFloat(search_lat), lng: parseFloat(search_lon) };
        props.apply(coords)

    }

    const search_loc = () => {
        if (inputStartRef.current && inputStartRef.current.getPlaces) {
            const startPlaces = inputStartRef.current.getPlaces();
            if (startPlaces && startPlaces.length > 0) {
                const startPlace = startPlaces[0];
                setSearch_lat(startPlace.geometry.location.lat);
                setSearch_lon(startPlace.geometry.location.lng);
            }
        }
    }
    return (
        <Dialog open={true} onClose={() => props?.onClose()}>
            <DialogTitle sx={{ fontSize: 15 }}>Add New Point</DialogTitle>
            <DialogContent sx={{ width: 450, flexDirection: "coloumn" }}>
                
                <FormControl fullWidth size='small' sx={{ mt: 3 }}>
                    <InputLabel>Search by</InputLabel>
                    <Select
                        fullWidth
                        MenuProps={{ style: { zIndex: 35001 } }}
                        defaultValue='select'
                        size='small'
                        value={selectedOption}
                        onChange={handleSelectChange}
                        label="Search by"
                    >
                        <MenuItem value="select">Select</MenuItem>
                        <MenuItem value="latlong">Latitude & Longitude</MenuItem>
                        <MenuItem value="address">Address</MenuItem>
                    </Select>
                </FormControl>
                {selectedOption === 'latlong' && (
                    <Box sx={{ display: "flex", mt: 3 }}>
                        <TextField
                            sx={{ flex: 1, mr: 1 }}
                            size='small'
                            label='Lattitude'
                            placeholder="Latitude"
                            value={search_lat}
                            onChange={(e) => { setSearch_lat(e.target.value) }}
                        />
                        <TextField
                            size='small'
                            label='Longitude'
                            sx={{ flex: 1, ml: 1 }}
                            placeholder="Longitude"
                            value={search_lon}
                            onChange={(e) => { setSearch_lon(e.target.value) }}
                        />
                    </Box>)}
                {selectedOption === 'address' && (
                    <StandaloneSearchBox
                        onLoad={ref => {
                            inputStartRef.current = ref;
                        }}
                        onPlacesChanged={() => { search_loc() }}
                    >
                        <TextField
                            placeholder='Enter Your Address'
                            size='small'
                            fullWidth
                            sx={{ mt: 3 }}
                        />
                    </StandaloneSearchBox>
                )}
                <Box sx={{ display: "flex", mt: 3 }}>
                    <Button onClick={() => { search_coord() }} fullWidth variant="contained" sx={{ mr: 1, flex: 1 }}>Submit</Button>
                    <Button onClick={() => { props.onClose() }} fullWidth variant="contained" sx={{ ml: 1, flex: 1 }}>Cancel</Button>
                </Box>
            </DialogContent>
        </Dialog>

    );
}