import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Navigate, useNavigate } from "react-router-dom";
import { Button, TextField, Box, IconButton, MenuItem, Select, FormControl, InputLabel, FormControlLabel, RadioGroup, Radio, Typography, AppBar } from '@mui/material';
import { Map, Polyline, Marker, GoogleApiWrapper as Trip_Create, Circle, InfoWindow } from 'google-maps-react';
import { ThreeDots } from 'react-loader-spinner'
import greendot from '../assets/green_dot1.png'
import bluedot from '../assets/blue_dot.png'
import move from '../assets/move1.png'
import LoadingButton from '@mui/lab/LoadingButton';
import Cookies from 'universal-cookie';
import encryptStorage from "../encryptStorage";

// components
import { Header } from './component/header';
import { Filters } from './component/filters';

//Icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import reddot from '../assets/reddot.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// colors
import colors from '../config/colors';

// map API Key
import mapkey from '../config/map_key';

// Api
import fetchData from '../api/fetchData';
import { Location_search_popup } from './component/search_loc';
import TripF_Create from './trip_fence_add';

// Styles
const classes = {
    container: {
        position: "absolute",
        top: 170,
        left: 0,
        right: 0,
        bottom: 0,
        flexDirection: "row"
    },
    route_filter: {
        width: 360,
        flex: 1,
        m: 2,
    },
    add_btn: {
        width: 360,
        flex: 1,
        mx: 2, my: 0.5,
        color: "white", backgroundColor: "divider"
    },
    points_filter: {
        maxWidth: 300,
        flex: 1,
        my: 2,
    },
    map: {
        position: "absolute",
        top: 60,
        left: 400,
        right: 0,
        bottom: 0,
    },
    map1: {
        position: "absolute",
        top: 60,
        left: 400,
        right: 0,
        bottom: 0,
        zIndex: 9999999,
        backgroundColor: "white"
    },
    route_list: {
        position: "absolute",
        top: 60,
        left: 0,
        width: 400,
        bottom: 0,
        backgroundColor: colors.dark1,
        overflow: "auto", overflowY: "scroll",
        justifyContent: "center", pt: 2
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99999,
        backgroundColor: "rgba(0,0,0,0.7)"
    },
    addicon: {
        height: 25,
        width: 25,
        color: "white"
    },
    removeicon: {
        height: 25,
        width: 25,
    },
    point_list: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: 'divider', borderRadius: 1, position: "relative",
        p: 2,
        m: 2
    },
    info_cont: {
        display: "flex",
        border: 1,
        borderBottom: "1px solid grey",
        paddingLeft: 8,
        paddingRight: 8
    },
    info_cont1: {
        display: "flex",
        border: 1,
        paddingLeft: 8,
        paddingRight: 8
    },
    label: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400,
    },
    value: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400
    },
}



var mapp;
var mapp1;

export const Trip_Create_sub = (props) => {
    var [loading_btn, setLoading_btn] = useState(false);
    const [route_name, setRoute_name] = useState("")
    const [start_point, setStart_point] = useState({ "type": 0 })
    const [end_point, setEnd_point] = useState({ "type": 0 })
    const [points, setPoints] = useState([]);
    const [loader, setLoader] = useState(false)
    const [trip_points_user, setTrip_points_user] = useState([]);
    const [trip_points_system, setTrip_points_system] = useState([]);

    const [bounds, setBounds] = useState([])
    const [bounds1, setBounds1] = useState([])
    const [new_point_name, setNew_point_name] = useState('')

    const [coords, setCoords] = useState([])
    const [Label, setLabel] = useState(null);
    const [active_marker, setActive_marker] = useState(null)
    const [active_item, setActive_item] = useState({})
    const [info, setInfo] = useState(false)
    const [datas, setDatas] = useState({})

    const [map_fence, setMap_fence] = useState(false)
    const [point_coords, setPoint_coords] = useState({})

    const [search_loc_popup, setSearch_loc_popup] = useState(false)


    const appSetting = encryptStorage.getItem("appSetting");
    const map_default1 = { "lat": 16.2160, "lon": 77.3566, "zoom": 6 }
    const map_default2 = appSetting?.map_default


    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }


    useEffect(() => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        }
    }, []);

    const apply = (data) => {
        if (!data.ac_fk) {
            alert_trigger("error", "Please select assembly")
            return
        }

        setDatas(data)
        fetchData.get_trip_points(data).then((res) => {
            var { fence_list_user, fence_list_system } = res;
            setTrip_points_user(fence_list_user)
            setTrip_points_system(fence_list_system)
        }).catch((error) => {
            console.log(error)
        })
    }



    const handleStartpoint = (e) => {
        var data = get_current_point_data(e, start_point?.type)
        var item = { "label": "Start Point", "lat": parseFloat(data?.lat), "lng": parseFloat(data?.lon), "name": data?.label, "type": start_point?.type }
        console.log(item, "start_point")
        setStart_point(item)
        draw_route_markers(item, end_point, points)

    }

    const handleEndpoint = (e) => {
        var data = get_current_point_data(e, end_point?.type)
        var item = { "label": "End Point", "lat": parseFloat(data?.lat), "lng": parseFloat(data?.lon), "name": data?.label, "type": end_point?.type }
        console.log(item, "end_point")
        setEnd_point(item)
        draw_route_markers(start_point, item, points)

    }


    const addpointFields = () => {
        setPoints([...points, { "label": "", "lat": "", "lng": "", "name": "", "type": 0 }])
    }

    const removepointFields = (i) => {
        let newFormValues = [...points];
        newFormValues.splice(i, 1);
        setPoints(newFormValues)
    }

    const pointshandleChange = (i, e) => {
        let newFormValues = [...points];
        var data = get_current_point_data(e, newFormValues[i]?.type)
        newFormValues[i]["label"] = (parseInt(i) + 1)
        newFormValues[i]["lat"] = parseFloat(data?.lat)
        newFormValues[i]["lng"] = parseFloat(data?.lon)
        newFormValues[i]["name"] = data?.label
        setPoints(newFormValues);
        draw_route_markers(start_point, end_point, newFormValues)
    }

    const get_current_point_data = (e, type) => {
        if (type == 0) {
            var item = trip_points_system.find(({ value }) => value == e.target.value)

        }
        else {
            var item = trip_points_user.find(({ value }) => value == e.target.value)

        }
        return item
    }


    const apply_route_fence = () => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (route_name == "") {
            alert_trigger("error", "Please enter the route name")
            return
        }
        if (!datas.ac_fk) {
            alert_trigger("error", "Please select assembly")
            return
        }

        let way_points_ar = []
        way_points_ar.push(start_point)
        points?.map((item) => {
            way_points_ar.push(item)
        })
        way_points_ar.push(end_point)
        var rp = ''


        coords.map((item) => {
            rp = rp + '(' + item.lat() + ',' + item.lng() + ")#";
        })
        var data = { "route_name": route_name, "way_points": way_points_ar, "route_point": rp, "cat_fk": datas.category_fk, "ac_fk": datas.ac_fk, "uid": uid }
        console.log(data, "data_submit")
        setLoading_btn(true)
        fetchData.put_trip_points(data).then((res) => {
            var { rval, msg } = res;
            if (rval > 0) {
                alert_trigger("success", msg)
                props.onClose()
                props.onRefresh()

            } else {
                alert_trigger("error", msg)
            }
            setLoading_btn(false)
        }).catch((error) => {
            console.log(error)
            setLoading_btn(false)
        })
    }


    const draw_route_markers = (start, end, waypoints) => {
        const waypts = waypoints.map(waypoint => ({
            location: new window.google.maps.LatLng(waypoint.lat, waypoint.lng),
            stopover: true
        }));

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route({
            origin: start,
            destination: end,
            waypoints: waypts,
            travelMode: window.google.maps.TravelMode.DRIVING,
            provideRouteAlternatives: true,

        }, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                const path = result.routes[0].overview_path;
                setCoords(path);
                var res_ar = []
                path.map((items) => {
                    res_ar.push({ "lat": items.lat(), "lon": items.lng() })
                })
                console.log("res_ar", res_ar)
                const bounds = new window.google.maps.LatLngBounds();
                result.routes[0].overview_path.forEach(function (point) {
                    bounds.extend(point);
                    setBounds(bounds)
                });
            } else {
                console.error('Error fetching directions:', result);
            }
        });
    };

    const onMarkerClick = (props, marker, e, point, label) => {
        setLabel(label)
        setActive_marker(marker)
        setInfo(true)
        setActive_item(point)
    }

    const pointshandletypeChange = (i, e) => {
        let newFormValues = [...points];
        newFormValues[i]["type"] = e
        setPoints(newFormValues);
    }

    const add_fence = (coords) => {
        setMap_fence(true)
        setSearch_loc_popup(false)
        console.log(coords)
        setPoint_coords(coords)

    }


    return (
        <>
            
            <Filters page={"Trip"} options={"District,Assembly,Apply"} btn={"Add"} apply={apply}></Filters>

            <Box sx={classes.route_list}>
                <FormControl fullWidth size='small' sx={classes.route_filter}>
                    <TextField
                        InputLabelProps={{ "shrink": true }}
                        size='small'
                        label="Route Name *"
                        onChange={(e) => {
                            setRoute_name(e.target.value)
                        }}
                        variant="outlined" /></FormControl>
                <Box sx={classes.point_list}>
                    <RadioGroup row value={start_point?.type}
                        onChange={(e) => {
                            setStart_point({ ...start_point, "type": e.target.value })

                        }}
                    >
                        <FormControlLabel value={0} control={<Radio />} label="System Default" />
                        <FormControlLabel value={1} control={<Radio />} label="User Defined" />
                    </RadioGroup>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <FormControl fullWidth size='small' sx={classes.points_filter}>
                            <InputLabel shrink>Start Point</InputLabel>
                            <Select
                                notched
                                size='small'
                                label="Start Point"
                                value={start_point.value}
                                onChange={(e) => { handleStartpoint(e) }}
                            >
                                {
                                    start_point?.type == 0 ?
                                        trip_points_system?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        }) :
                                        trip_points_user?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })
                                }
                            </Select>
                        </FormControl>
                        {start_point?.type == 1 &&
                            <IconButton sx={{ height: 40, width: 40, ml: 0.5 }} onClick={() => { setSearch_loc_popup(true) }}>
                                <AddCircleIcon sx={classes.removeicon} />
                            </IconButton>
                        }
                    </Box>

                </Box>
                {points?.map((element, index) => (
                    <Box sx={classes.point_list}>
                        <RadioGroup row value={element?.type}
                            onChange={(e) => {
                                pointshandletypeChange(index, e.target.value)
                            }}
                        >
                            <FormControlLabel value={0} control={<Radio />} label="System Default" />
                            <FormControlLabel value={1} control={<Radio />} label="User Defined" />
                        </RadioGroup>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                            <FormControl fullWidth size='small' sx={classes.points_filter}>
                                <InputLabel shrink>{"Waypoint - " + (parseInt(index) + 1)}</InputLabel>
                                <Select
                                    notched
                                    size='small'
                                    label={"Waypoint - " + (parseInt(index) + 1)}
                                    value={element.value}
                                    name={"Waypoint"}
                                    onChange={(e) => { pointshandleChange(index, e) }}
                                >
                                    {
                                        element?.type == 0 ?
                                            trip_points_system?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                                )
                                            }) :
                                            trip_points_user?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                                )
                                            })

                                    }
                                </Select>
                            </FormControl>
                            {element?.type == 1 &&
                                <IconButton sx={{ height: 40, width: 40, ml: 0.5 }} onClick={() => { setSearch_loc_popup(true) }}>
                                    <AddCircleIcon sx={classes.removeicon} />
                                </IconButton>
                            }
                        </Box>


                        <IconButton onClick={() => { removepointFields() }} sx={{ position: "absolute", top: 5, right: 5 }}>
                            <RemoveCircleIcon sx={classes.removeicon} />
                        </IconButton>
                    </Box>
                ))}
                <Box sx={classes.point_list}>
                    <RadioGroup row value={end_point?.type}
                        onChange={(e) => {
                            setEnd_point({ ...end_point, "type": e.target.value })

                        }}
                    >

                        <FormControlLabel value={0} control={<Radio />} label="System Default" />
                        <FormControlLabel value={1} control={<Radio />} label="User Defined" />
                    </RadioGroup>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <FormControl fullWidth size='small' sx={classes.points_filter}>
                            <InputLabel shrink>End Point</InputLabel>
                            <Select
                                notched
                                size='small'
                                label="End Point"
                                value={end_point.value}
                                onChange={(e) => { handleEndpoint(e) }}
                            >
                                {
                                    end_point?.type == 0 ?
                                        trip_points_system?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        }) :
                                        trip_points_user?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })

                                }
                            </Select>
                        </FormControl>
                        {end_point?.type == 1 &&
                            <IconButton sx={{ height: 40, width: 40, ml: 0.5 }} onClick={() => { setSearch_loc_popup(true) }}>
                                <AddCircleIcon sx={classes.removeicon} />
                            </IconButton>
                        }
                    </Box>

                </Box>
                <Button onClick={() => { addpointFields() }} sx={classes.add_btn}>
                    Add Waypoint
                </Button>
                {loading_btn ?
                    <LoadingButton
                        loading
                        loadingPosition="center"
                        variant="contained"
                        sx={classes.route_filter}
                    >Loading...
                    </LoadingButton> :
                    <Button variant="contained" fullWidth sx={classes.route_filter} onClick={() => apply_route_fence()}>Submit</Button>
                }
            </Box>
            {loader &&
                <Box sx={[classes.map, classes.loader]}>
                    <ThreeDots
                        height="100"
                        width="100"
                        radius="7"
                        color="#0288d1"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </Box>}

            {map_fence &&
                <TripF_Create point_coords={point_coords} onCancel={() => { setMap_fence(false) }} data={datas} onRefresh={() => { apply(datas) }}></TripF_Create>
            }
            <Box sx={classes.map}>
                <Map
                    ref={(mapr) => mapp = mapr}
                    bounds={bounds}
                    initialCenter={{ lat: map_default2?.lat ? map_default2?.lat : map_default1?.lat, lng: map_default2?.lon ? map_default2?.lon : map_default1?.lon }}
                    google={props.google}
                    zoom={map_default2?.zoom ? map_default2?.zoom : map_default1?.zoom}
                >
                    {start_point.lat && start_point.lng && (
                        <Marker
                            position={{ lat: start_point.lat, lng: start_point.lng }}
                            onClick={(props, marker, e) => onMarkerClick(props, marker, e, start_point, "Start")}
                            icon={{
                                url: greendot,
                                anchor: new props.google.maps.Point(14, 14),
                                scaledSize: new props.google.maps.Size(25, 25),
                            }}
                        />
                    )}
                    {end_point.lat && end_point.lng && (
                        <Marker
                            position={{ lat: end_point.lat, lng: end_point.lng }}
                            onClick={(props, marker, e) => onMarkerClick(props, marker, e, end_point, "End")}
                            icon={{
                                url: reddot,
                                anchor: new props.google.maps.Point(14, 14),
                                scaledSize: new props.google.maps.Size(25, 25),
                            }}
                        />
                    )}
                    {points.map((waypoint, index) => (
                        waypoint.lat && waypoint.lng && (
                            <Marker
                                key={index}
                                onClick={(props, marker, e) => onMarkerClick(props, marker, e, waypoint, `Waypoint - ${index + 1}`)}
                                position={{ lat: waypoint.lat, lng: waypoint.lng }}
                                icon={{
                                    url: bluedot,
                                    anchor: new props.google.maps.Point(14, 14),
                                    scaledSize: new props.google.maps.Size(25, 25),
                                }}
                            />
                        )
                    ))}

                    <Polyline
                        path={coords}
                        strokeColor="green"
                        strokeOpacity='6.0'
                        strokeWeight='4'
                    />

                    <InfoWindow
                        visible={info}
                        marker={active_marker}
                    >
                        <div style={{ width: 200, marginRight: 12, marginBottom: 12, border: "1px solid grey", borderRadius: 5 }}>
                            <div style={classes.info_cont}>
                                <p style={{ textAlign: 'center', ...classes.value }}>{Label} - {active_item.name}</p>
                            </div>

                        </div>
                    </InfoWindow>
                </Map>
            </Box>

            <Button startIcon={<ArrowBackIcon/>} variant="contained" fullWidth sx={{ position: "absolute",top:12,right:10,width:100 }} onClick={() => props.onClose()}>Back</Button>

            {search_loc_popup &&
                <Location_search_popup onClose={() => setSearch_loc_popup(false)} apply={(coords) => add_fence(coords)} />
            }
        </>
    )
}

export default Trip_Create({
    apiKey: mapkey.mapKey
})(Trip_Create_sub)