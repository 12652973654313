import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Box, IconButton } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { ThreeDots } from 'react-loader-spinner'
import Cookies from 'universal-cookie';
import { Navigate, useNavigate } from "react-router-dom";


// components
import { Filters } from './component/filters';
import Reportroute from './report_route'
// colors
import colors from '../config/colors';


// Api
import fetchData from '../api/fetchData';

//icons
import MyLocationIcon from '@mui/icons-material/MyLocation';


const classes = {
    container: {
        position: "absolute",
        top: 10,
        left: 0,
        right: 0,
        bottom: 0,
        '& .super-app-theme--header': {
            backgroundColor: colors.dark1,
        },
    },
    data_table: {
        position: "absolute",
        top: 130,
        left: 10,
        right: 10,
        bottom: 10,
    },
    loader: {
        position: "absolute",
        top: 130,
        left: 10,
        right: 10,
        bottom: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}

var vehicle_info1 = {}

export default function EngineReport() {
    const [vehicle_details, setVehicle_details] = useState([]);
    const [proc_id, setProc_id] = useState("")
    const [paginationModel, setPaginationModel] = useState({ pageSize: 50, page: 0 })
    const [rowCountState, setRowCountState] = useState(0)
    const [datas, setDatas] = useState([])
    const [loader, setLoader] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [active_item, setActive_item] = React.useState([]);
    const [column, setColumn] = React.useState([]);
    const navigate = useNavigate();


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }

    useEffect(() => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        } else {
            set_table_col(vehicle_info1)
        }
    }, []);


    const set_table_col = (vehicle_info) => {
        const column = [
            { field: 'district', headerName: 'District', flex:1, headerClassName: 'super-app-theme--header', renderCell: (params) => vehicle_info?.dname, valueFormatter: (value) => vehicle_info?.dname },
            { field: 'ac_name', headerName: 'Assembly', flex:1, headerClassName: 'super-app-theme--header', renderCell: (params) => vehicle_info?.ac_name, valueFormatter: (value) => vehicle_info?.ac_name },
            { field: 'category', headerName: 'Category', flex:1, headerClassName: 'super-app-theme--header', renderCell: (params) => vehicle_info?.category, valueFormatter: (value) => vehicle_info?.category },
            { field: 'no', headerName: 'Vehicle No', flex:1, headerClassName: 'super-app-theme--header', renderCell: (params) => vehicle_info?.v_no, valueFormatter: (value) => vehicle_info?.v_no },
            { field: 'name', headerName: 'Vehicle Type', flex:1, headerClassName: 'super-app-theme--header', renderCell: (params) => vehicle_info?.name, valueFormatter: (value) => vehicle_info?.name },

            { field: 'stdt', headerName: 'Engine ON Time', flex:1, headerClassName: 'super-app-theme--header' },
            { field: 'endt', headerName: 'Engine OFF Time', flex:1, headerClassName: 'super-app-theme--header' },
            { field: 'dist', headerName: 'Duration H:M:S', flex:1, headerClassName: 'super-app-theme--header' },
            { field: 'odo', headerName: 'Distance (Km)', flex:1, headerClassName: 'super-app-theme--header' },
            { field: 'addr_s', headerName: 'Start Address', flex:2, headerClassName: 'super-app-theme--header', },
            { field: 'addr_e', headerName: 'End Address', flex:2, headerClassName: 'super-app-theme--header' },
            {
                headerName: '',
                disableExport: true,
                width: 100, headerClassName: 'super-app-theme--header',
                renderCell: (params) =>
                    <>
                        <IconButton onClick={(e) => { open_map(params.row) }}><MyLocationIcon color="primary" />
                        </IconButton >
                    </>
            },
        ]
        setColumn(column)
    }



    const apply = (data) => {
        fetchData.vehicle_info({ "vehicle_fk": data.vehicle_fk }).then((res) => {
            var { vehicle_info } = res;
            vehicle_info1 = vehicle_info
            set_table_col(vehicle_info1)

        }).catch((error) => {
            console.log(error)
            setLoader(false)
        })
        if (!data.vehicle_fk) {
            alert_trigger("error", "Please select vehicle to view report")
            return
        }
        else if (!data.from) {
            alert_trigger("error", "Please select date range to view report")
            return
        }
        else if (!data.to) {
            alert_trigger("error", "Please select date range to view report")
            return
        }
        setLoader(true)
        if (datas.vehicle_fk != data.vehicle_fk||datas.from != data.from || datas.to != data.to) {
            data["proc_id"] = ""
        } 
        else {
            data["proc_id"] = proc_id
        }
        data["pageSize"] = data.pageSize ? data.pageSize : paginationModel.pageSize
        data["page"] = data.page ? data.page : paginationModel.page
        setDatas(data)

        fetchData.engine_report(data).then((res) => {
            var { engine_lst, proc_id, total_count } = res;
            setProc_id(proc_id)
            setRowCountState(total_count)
            setVehicle_details(engine_lst)
            setLoader(false)
            get_addr(engine_lst)

        }).catch((error) => {
            console.log(error)
            setLoader(false)
        })
    }

    const get_addr = (vehicle_list) => {
        var cords = []
        vehicle_list?.map((item) => {
            cords.push({ "lat": parseFloat(item.s_lat), "lon": parseFloat(item.s_lon), "id": item.id + "_s" })
            cords.push({ "lat": parseFloat(item.e_lat), "lon": parseFloat(item.e_lon), "id": item.id + "_e" })

        })
        fetchData.v_addr({ "latlon": cords }).then((res) => {
            var addr = res.addr
            var v_new_list = []
            vehicle_list?.map((item) => {
                var it = item
                var act = addr.find(({ id }) => id == item.id + "_s");
                it["addr_s"] = act.formattedAddress
                var act = addr.find(({ id }) => id == item.id + "_e");
                it["addr_e"] = act.formattedAddress
                v_new_list.push(item)
            })
            setVehicle_details(v_new_list)


        }).catch((error) => {
            console.log(error)
        })
    }

    const changePagination = (e) => {
        console.log(e)
        setPaginationModel(e);
        var data1 = datas
        data1["pageSize"] = String(e.pageSize)
        data1["page"] = String(e.page)
        apply(data1)
    }


    const open_map = (e) => {
        console.log(e.st)
        console.log(e.et)
        console.log(datas.vehicle_fk)
        setActive_item({ "from": e.st.replace("T", " "), "to": e.et.replace("T", " "), "vehicle_fk": vehicle_info1.vehicle_fk, "start_a": e.addr_s, "end_a": e.addr_e, "v_no": vehicle_info1.v_no, "name": vehicle_info1.name });
        setOpen(true)
    }


    return (
        <Box sx={classes.container}>
            <Filters page={"Report"} apply={apply} options={"District,Assembly,Category,Vehicle,From,To,Apply"}></Filters>
            {loader ? <Box sx={classes.loader}>
                <ThreeDots
                    height="100"
                    width="100"
                    radius="7"
                    color="#0288d1"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                /></Box>
                : <Box sx={classes.data_table}>
                    <DataGrid
                        disableColumnSelector
                        disableDensitySelector
                        disableSelectionOnClick
                        disableRowSelectionOnClick 
                        pageSizeOptions={[10, 20, 50]}
                        initialState={{
                            pagination: { paginationModel: { pageSize: paginationModel.pageSize } },
                        }}
                        paginationModel={paginationModel}
                        rowCount={rowCountState}
                        paginationMode="server"
                        onPaginationModelChange={(e) => { changePagination(e) }}
                        rows={vehicle_details}
                        columns={column}
                        slots={{
                            toolbar: CustomToolbar,
                        }}
                        slotProps={{ toolbar: { csvOptions: { allColumns: true } } }}
                    />
                </Box>
            }
            {open &&

                <Reportroute handleClose={handleClose} item={active_item} />
            }
        </Box>
    )
}

function CustomToolbar() {
    return (
        <GridToolbarContainer sx={{ backgroundColor: colors.dark1, justifyContent: "flex-end", pt: 1 }}>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} sx={{ mr: 3, backgroundColor: "black", height: 39, width: 90,color:"white",fontSize:14 }} />
    </GridToolbarContainer>
    );
}