import React from 'react';
import { useState, useEffect } from "react";
import { Button, TextField, Box, Typography } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import Cookies from 'universal-cookie';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from "react-router-dom";
import encryptStorage from "../encryptStorage";

import { useSnackbar } from 'notistack';
import logo from "../assets/logo.png"
import l1 from "../assets/l1.png"
import l2 from "../assets/l2.png"
import l3 from "../assets/l3.png"

import platform from "platform"

// api
import fetchData from '../api/fetchData'

// colors
import colors from '../config/colors';

const classes = {
    container: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${logo})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },
    login: {
        p: 5,
        width: 350,
        backgroundColor: colors.dark1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        borderRadius: 3,
        boxShadow: 5,
        py: 8,
        // ml: 75,
        position: "absolute",
        right: 250,

    },
    btn: {
        marginTop: 2,
        marginInline: 1
    },
    heading: {
        fontSize: 23, mx: 1, position: "absolute", top: 20, textShadow: "0px 0px 8px #000"
    },
    logo_container: {
        position: "absolute",
        bottom: 12, right: 170,
        height: 170,
        display: "flex", flexDirection: "row"
    }
}


export const Login = (props) => {
    const [uname, setUname] = useState('');
    const [pwd, setPwd] = useState('');
    const [ipAddress, setIPAddress] = useState('');
    const [browser, setBrowser] = useState('');
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("");


    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }


    useEffect(() => {
        const cookies = new Cookies();
        cookies.set('user_id', '', { path: '/' })
        get_ip_browser();
        setBrowser(platform.name)
        load_config1()


    }, []);

    const get_ip_browser = () => {
        fetch('https://api.ipify.org?format=json').then(response =>
            response.json()
        )
            .then(data =>
                setIPAddress(data.ip)
            )
            .catch(error =>
                console.log(error)
            )
    }

    const callApi = () => {
        if (!uname || !pwd) {
            setLoading(false)
            alert_trigger("error", "Please enter username and password !")
            return

        }
        setLoading(true)
        var data = { "action": "login", "uname": uname, "pwd": pwd, "ip": ipAddress, "browser": browser }
        fetchData.login(data).then((result) => {
            if (result.rval > 0) {
                const cookies = new Cookies();
                cookies.set('uname', result.uname, { path: '/' })
                cookies.set('user_id', result.user_id, { path: '/' })
                cookies.set('priv', result.priv, { path: '/' })
                cookies.set('priv_id', result.priv_id, { path: '/' })
                cookies.set('is_admin', result.is_admin, { path: '/' })

                load_config(result.is_admin)

            }
            else {
                alert_trigger("error", result.msg)
                setLoading(false)
            }
        }).catch((err) => {
            setLoading(false)
            console.log("error-", err);
        });
    }



    const load_config = (isAdmin = 0) => {
        var url = window.location.origin
        var result = url.replace(/(^\w+:|^)\/\//, '');
        console.log(result)
        var data = { "path": result }
        fetchData.app_config(data).then((result) => {
            if (result) {
                encryptStorage.setItem("versionInfo", result.version);
                encryptStorage.setItem("appSetting", result.conf);
               
                let appSetting = JSON.parse(result.conf);
                if (isAdmin)
                    navigate("/admin")
                else
                    navigate(appSetting?.feature[appSetting.menu[0]]?.navigate ?? "", { state: appSetting.menu[0] ?? "" })
            }
        }).catch((err) => {
            console.log("error-", err);
            setLoading(false)
        });
    }

    const load_config1 = () => {
        var url = window.location.origin
        var result = url.replace(/(^\w+:|^)\/\//, '');
        console.log(result)
        var data = { "path": result }
        fetchData.app_config(data).then((result) => {
            if (result?.conf) {
                var resconf=JSON.parse(result?.conf)
                console.log(resconf)
                setTitle(resconf?.title)
                document.title=resconf?.title
            }
        }).catch((err) => {
            console.log("error-", err);
        });
    }

    return (
        <Box sx={classes.container}>
             <Typography variant="subtitle1" sx={classes.heading} >{title}
            </Typography>


            <Box sx={classes.login}>

                <TextField sx={{ m: 1 }} required label="User Name" variant="standard" size='small' type="mail" onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        callApi();
                    }
                }} onChange={(e) => setUname(e.target.value)} />
                <TextField sx={{ m: 1 }} required label="Password" variant="standard" type="password" size='small' onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        callApi();
                    }
                }} onChange={(e) => setPwd(e.target.value)} />
                {loading ?
                    <LoadingButton
                        sx={classes.btn}
                        loading
                        loadingPosition="end"
                        variant="contained"

                    >
                        Loading..
                    </LoadingButton> :
                    <Button sx={classes.btn} variant="contained" endIcon={<LoginIcon />} onClick={() => { callApi(); }}>Login</Button>
                }

            </Box>
            {/*<Box sx={classes.logo_container}>
                <img src={l1} alt="ECI" ></img>
                <img src={l2} alt="ECI" ></img>
                <img src={l3} alt="ECI" ></img> 
            </Box> */}
        </Box>
    )
}



