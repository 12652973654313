import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Navigate, useNavigate } from "react-router-dom";
import { Button, TextField, Box, Typography, DialogActions, IconButton, DialogTitle, Dialog, DialogContent, MenuItem, Select, FormControl, InputLabel, FormControlLabel, Checkbox } from '@mui/material';
import { Map, InfoWindow, Marker, GoogleApiWrapper as FenceScreen } from 'google-maps-react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { ThreeDots } from 'react-loader-spinner'
import Cookies from 'universal-cookie';


// components
import { Header } from './component/header';
// colors
import colors from '../config/colors';


// Api
import fetchData from '../api/fetchData';

const classes = {
    container: {
        flex:1
    },
    filter: {
        width: 200,
        my: 4,
        mr: 4
    },
    checkbox: {
        width: 200,
        mr: 4
    },
    full_width: {
        my: 1,
        width: 370
    },
}


export default function Alert_settings(props) {
    const cookies = new Cookies();
    const [ac, setAc] = useState([])
    const [ac_val, setAc_val] = useState(0)
    const [veh, setVeh] = useState([{ "label": "Select", "value": 0 }])
    const [veh_val, setVeh_val] = useState(0)
    const [idlet, setIdlet] = useState("")
    const [speedlim, setSpeedlim] = useState("")
    const [mobile_no, setMobile_no] = useState("")
    const [sms_alert, setSms_alert] = useState(false)
    const { enqueueSnackbar } = useSnackbar();


    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }

    useEffect(() => {
        get_ac()
    }, []);


    const get_ac = () => {
        var data = { "dist_fk": 0, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }
        fetchData.ac(data).then((res) => {
            var { assembly } = res;
            if (assembly.length > 1) {
                assembly.unshift({ "label": "Select", "value": 0 });
            }
            if (assembly.length == 1) {
                setAc_val(assembly[0].value)
                get_vehicle(assembly[0].value)
            } else {
                setAc_val(0)
            }
            setAc(assembly)
        }).catch((error) => {
            console.log(error)
        })
    }

    const get_vehicle = (ac_fk) => {
        fetchData.vehicle({ "ac_fk": ac_fk, "category_fk": 0, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }).then((res) => {
            var { vehicle } = res;
            vehicle.unshift({ "label": "All", "value": 0 });
            setVeh(vehicle)
        }).catch((error) => {
            console.log(error)
        })
    }

    const alerts_settings_get = (ac_fk,vfk) => {
        fetchData.alerts_settings_get({ "ac_fk": ac_fk, "vehicle_fk": vfk }).then((res) => {
            var { speedlim,idlet } = res;
            if(!speedlim)
            {
                setSpeedlim("")
            }
            else{
                setSpeedlim(speedlim)
            }
            if(!idlet)
            {
                setIdlet("")
            }
            else{
                setIdlet(idlet)
            }
        }).catch((error) => {
            console.log(error)
        })
    }
    const alerts_settings_put = (alert_typ) => {
        if (sms_alert) {
            if (mobile_no.length == 0) {
                alert_trigger("error", "Please fill mobile number")
                return;
            }
        }
        var data = { "ac_fk": ac_val, "vehicle_fk": veh_val, "alert_typ": alert_typ, "speedlim": speedlim, "idlet": idlet, "mobile_no": mobile_no, "sms_alert": sms_alert ? 1 : 0 }
        fetchData.alerts_settings_put(data).then((res) => {
            var { rval, msg } = res;
            if (rval > 0) {
                alert_trigger("success", msg)
                alerts_settings_get(ac_val,veh_val)
            }
            else {
                alert_trigger("error", msg)
            }
        }).catch((error) => {
            console.log(error)
        })
    }



    return (
        <Dialog
            open={true}
            onClose={() => props.onClose()}
        >
            <Box sx={{ width: 450 }}>
                <DialogTitle sx={{ fontSize: 15 }}>Alert Settings</DialogTitle>
                <DialogContent >

                    <Box sx={classes.container}>
                        <Box sx={{ display: "flex", flex: 1, width: 1 }}>
                            <FormControl size='small' sx={classes.filter}>
                                <InputLabel >Assembly</InputLabel>
                                <Select
                                    size='small'
                                    label="Assembly"
                                    value={ac_val}
                                    onChange={(e) => { setAc_val(e.target.value); get_vehicle(e.target.value); alerts_settings_get(e.target.value, veh_val) }}
                                >
                                    {
                                        ac?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl size='small' sx={classes.filter}>
                                <InputLabel >Vehicle</InputLabel>
                                <Select
                                    size='small'
                                    label="Vehicle"
                                    value={veh_val}
                                    onChange={(e) => { setVeh_val(e.target.value); alerts_settings_get(ac_val, e.target.value) }}
                                >
                                    {
                                        veh?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Typography variant="subtitle1" >Idle Alert Settings</Typography>
                        <Box sx={{ display: "flex", flex: 1, width: 1 }}>
                            <FormControl size='small' sx={classes.filter}>
                                <TextField
                                    value={idlet}
                                    size='small'
                                    InputLabelProps={{ shrink: true }}
                                    label="Idle Time Limit (mins)"
                                    onChange={(e) => {
                                        setIdlet(e.target.value)
                                    }}
                                    variant="outlined" />
                            </FormControl>
                            <FormControlLabel control={<Checkbox checked={sms_alert} />}
                                label="Allow Mobile Alerts"
                                sx={classes.checkbox}
                                onChange={(e) => { setSms_alert(e.target.checked) }}
                            />
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            {sms_alert &&
                                <FormControl size='small' sx={classes.full_width} fullWidth>
                                    <TextField
                                        value={mobile_no}
                                        size='small'
                                        type="number"
                                        InputLabelProps={{ shrink: true }}
                                        label="Mobile Number"
                                        onChange={(e) => {
                                            setMobile_no(e.target.value)
                                        }}
                                        variant="outlined" />
                                </FormControl>
                            }
                        </Box>

                        {/* <Typography variant="subtitle1" >Overspeed Alert Settings</Typography>
                        <Box sx={{ display: "flex", flex: 1, width: 1 }}>
                            <FormControl size='small' sx={classes.filter}>
                                <TextField
                                    value={speedlim}
                                    size='small'
                                    InputLabelProps={{ shrink: true }}
                                    label="Speed Limit (km)"
                                    onChange={(e) => {
                                        setSpeedlim(e.target.value)
                                    }}
                                    variant="outlined" />
                            </FormControl>
                            <Button variant="contained" sx={classes.filter} onClick={() => { alerts_settings_put("speed") }}>Save</Button>
                        </Box> */}

                    </Box>
                </DialogContent>
                <DialogActions sx={{ pr: 3, pb: 2 }}>
                    <Button onClick={() => { alerts_settings_put("idle") }}>Save</Button>
                    <Button onClick={() => props.onClose()}>Cancel</Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}