export default{
   dark1:"#222222",
   running_cl:"#28a745",
   // running_bg:"rgba( 213,255,223,0.2)",2b9aaa99
   running_bg:"#40d86433",
   idle_cl:"#17a2b8",
   // idle_bg:"rgba(209,249,255,0.6)",
   idle_bg:"#27818e99",

   offline_cl:"#ff5d1d",
   offline_bg:"rgba(255,93,29,0.2)",
   primary:"#015f92",
   red: "#b71c1c",
   red_light:"#ffcdd2",
   red_cl:"#ff0000"
}