import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Box, IconButton, Typography, Checkbox, FormControlLabel, CircularProgress, Menu, MenuItem } from '@mui/material';
import { Map, GoogleApiWrapper as Routeplay } from 'google-maps-react';
import html2canvas from 'html2canvas';
import Cookies from 'universal-cookie';
import Draggable from 'react-draggable';
import encryptStorage from "../encryptStorage";

// components
import { Header } from './component/header';
import { Filters } from './component/filters';


// Api
import fetchData from '../api/fetchData';

// colors
import colors from '../config/colors';

// map API Key
import mapkey from '../config/map_key';

// icons
import start from '../assets/start1.png'
import stop from '../assets/stop1.png'
import move from '../assets/move.png'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import DownloadIcon from '@mui/icons-material/Download';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import green from '../assets/greendot.png'
import parking from '../assets/parking.png'

var interval


// Styles

const classes = {
    container: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        flexDirection: "row"
    },
    map: {
        position: "absolute",
        top: 120,
        left: 0,
        right: 0,
        bottom: 0,
    },
    info_cont: {
        display: "flex",
        border: 1,
        borderBottom: "1px solid grey",
        paddingLeft: 8,
        paddingRight: 8
    },
    info_cont1: {
        display: "flex",
        border: 1,
        paddingLeft: 8,
        paddingRight: 8
    },
    label: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400,
    },
    value: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400
    },
    animate_btn: {
        position: "absolute",
        top: 130,
        left: 200,
        width: 40,
        height: 40,
        backgroundColor: "white",
        color: "#444",
        boxShadow: 1,
        '&:hover': {
            backgroundColor: 'white',
            color: '#444',
        },
        '&:active': {
            backgroundColor: 'white',
            color: '#444',
        },

    },
    statistics: {
        position: "absolute", top: 150, left: 10, backgroundColor: colors.dark1, borderRadius: 2, pb: 1
    },
    minimise: {
        position: "absolute", top: 150, left: 10, backgroundColor: "white",
        color: "#444",
        boxShadow: 1,
        '&:hover': {
            backgroundColor: 'white',
            color: '#444',
        }, borderRadius: 2, pb: 1
    }
}




var RotateIcon = function (options) {
    this.options = options || {};
    this.rImg = options.img || new Image();
    this.rImg.src = this.rImg.src || this.options.url || '';
    this.options.width = this.options.width || this.rImg.width || 52;
    this.options.height = this.options.height || this.rImg.height || 60;
    var canvas = document.createElement("canvas");
    canvas.width = this.options.width;
    canvas.height = this.options.height;
    this.context = canvas.getContext("2d");
    this.canvas = canvas;
};
RotateIcon.makeIcon = function (url) {
    return new RotateIcon({ url: url });
};
RotateIcon.prototype.setRotation = function (options) {
    var canvas = this.context,
        angle = options.deg ? options.deg * Math.PI / 180 :
            options.rad,
        centerX = this.options.width / 2,
        centerY = this.options.height / 2;
    canvas.clearRect(0, 0, this.options.width, this.options.height);
    canvas.save();
    canvas.translate(centerX, centerY);
    canvas.rotate(angle);
    canvas.translate(-centerX, -centerY);
    canvas.drawImage(this.rImg, 0, 0);
    canvas.restore();
    return this;
};
RotateIcon.prototype.getUrl = function () {
    return this.canvas.toDataURL('image/png');
};
var route;
var marker;
var mapp;
var information;
var green_dot_marker = []
var parking_marker = []

var start_marker;
var stop_marker;
var start_stop_info;

export const MapSub = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [vsumm, setVsumm] = useState([])
    const [coords, setCoords] = useState([])
    const [bounds, setBounds] = useState([])
    const [is_animate, setIs_animate] = useState(false)
    const [ind1, setInd1] = useState(0)
    const [dat, setDat] = useState({})
    const [park, setPark] = useState([])
    const [dash, setDash] = useState({})
    const [park_switch, setPark_switch] = React.useState(false);
    const [loader, setLoader] = React.useState(false);
    const [dwn_anchorEl, setDwn_anchorEl] = React.useState(null);
    const dwn_open = Boolean(dwn_anchorEl);
    const [minimise, setMinimise] = useState(false);

    const appSetting = encryptStorage.getItem("appSetting");
    const map_default1 = { "lat":16.2160, "lon":  77.3566, "zoom": 6 }
    const map_default2 = appSetting?.map_default

    const handleClick_Dwn = (event) => {
        setDwn_anchorEl(event.currentTarget);
    };
    const handleClose_Dwn = () => {
        setDwn_anchorEl(null);
    };

    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }
    const handleClickTakeScreenShot = () => {
        const { cropPositionTop, cropPositionLeft, cropWidth, cropHeigth } = {
            cropPositionTop: 0,
            cropPositionLeft: 0,
            cropWidth: 1800,
            cropHeigth: 1800
        };
        const input = document.getElementById('divToPrint');

        html2canvas(input,
            {
                useCORS: true,
                allowTaint: false,
                backgroundColor: null,
                ignoreElements: (node) => {
                    return node.nodeName === 'IFRAME';
                }
            }
        ).then(canvas => {
            let croppedCanvas = document.createElement("canvas");
            let croppedCanvasContext = croppedCanvas.getContext("2d");

            croppedCanvas.width = cropWidth;
            croppedCanvas.height = cropHeigth;

            croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop);

            const a = document.createElement("a");
            a.href = croppedCanvas.toDataURL();
            a.download = "route.png";
            a.click();
        });
    };


    useEffect(() => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        } else {
            return (() => {
                clearInterval(interval)
            })
        }
    }, []);




    const change_layout = (e) => {
        setPark_switch(e.target.checked)
        if (e.target.checked) {
            green_dot_marker?.map((mark) => {
                mark.setMap(null)
            })
        }
        else {
            if (vsumm.length > 1) {
                if (start_marker)
                    start_marker.setMap(null)
                if (stop_marker)
                    stop_marker.setMap(null)
                start_end_marker_draw(vsumm)
                let cords = []
                i = 0
                green_dot_marker?.map((mark) => {
                    mark.setMap(null)
                })
                var i = 0
                vsumm?.map((item) => {
                    cords.push({ "lat": parseFloat(item.lat), "lng": parseFloat(item.lon) })
                    if (i % 3 == 0) {
                        var mark = new props.google.maps.Marker({
                            position: { lat: item.lat, lng: item.lon },
                            map: mapp.map,
                            icon: {
                                url: green,
                                origin: new props.google.maps.Point(0, 0),
                                anchor: new props.google.maps.Point(7, 7),
                            },
                        })
                        mark.addListener("click", () => {
                            show_information(mark, item)
                        });
                        green_dot_marker.push(mark)
                    }
                    i++
                })
                if (route)
                    route.setMap(null);
                if (marker)
                    marker.setMap(null)

                route = new props.google.maps.Polyline({
                    path: cords,
                    strokeColor: colors.running_cl,
                    strokeOpacity: 2,
                    strokeWeight: 5,
                });

                route.setMap(mapp.map);
            }
        }
    }



    const download_kml = () => {
        fetchData.route_play_kml(dat).then((response) => {
            const href = window.URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'Route.kml');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }).catch((error) => {
            console.log(error)
        })

    }

    const apply = (data) => {
        setLoader(true)
        setDat(data)
        if (!data.vehicle_fk) {
            alert_trigger("error", "Please select vehicle to view playback")
            setLoader(false)
            return
        }
        else if (!data.from) {
            alert_trigger("error", "Please select date range to view playback")
            setLoader(false)
            return
        }
        else if (!data.to) {
            alert_trigger("error", "Please select date range to view playback")
            setLoader(false)
            return
        }
        setIs_animate(false)
        setInd1(0)
        setPark_switch(false)
        clearInterval(interval)

        fetchData.route_play(data).then((res) => {
            setLoader(false)
            var { route_play, dash } = res;
            setDash(dash)
            if (route_play.length > 1) {
                if (start_marker)
                    start_marker.setMap(null)
                if (stop_marker)
                    stop_marker.setMap(null)
                start_end_marker_draw(route_play)
                setVsumm(route_play)
                console.log(route_play)
                let cords = []
                i = 0

                green_dot_marker?.map((mark) => {
                    mark.setMap(null)
                })

                route_play?.map((item) => {
                    cords.push({ "lat": parseFloat(item.lat), "lng": parseFloat(item.lon) })
                    if (i % 3 == 0) {
                        var mark = new props.google.maps.Marker({
                            position: { lat: item.lat, lng: item.lon },
                            map: mapp.map,
                            icon: {
                                url: green,
                                origin: new props.google.maps.Point(0, 0),
                                anchor: new props.google.maps.Point(7, 7),
                            },
                        })
                        mark.addListener("click", () => {
                            show_information(mark, item)
                        });
                        green_dot_marker.push(mark)
                    }
                    i++
                })
                setCoords(cords)
                var bounds = new props.google.maps.LatLngBounds();
                for (var i = 0; i < cords.length; i++) {
                    bounds.extend(cords[i]);
                }
                setBounds(bounds)
                if (route)
                    route.setMap(null);
                if (marker)
                    marker.setMap(null)

                route = new props.google.maps.Polyline({
                    path: cords,
                    strokeColor: colors.running_cl,
                    strokeOpacity: 2,
                    strokeWeight: 5,
                });

                route.setMap(mapp.map);

            }
            else {
                alert_trigger("info", "No data available")

            }

        }).catch((error) => {
            console.log(error)
            setLoader(false)

        })
        fetchData.route_play_parking(data).then((res) => {
            setLoader(false)
            var { route_play_parking } = res;
            setPark(route_play_parking)
            parking_marker?.map((mark) => {
                if (mark)
                    mark.setMap(null)
            })
            route_play_parking?.map((item, index) => {
                console.log(index)
                var mark = new props.google.maps.Marker({
                    position: { lat: item.lat, lng: item.lon },
                    label: { text: String(index + 1), color: "white" },
                    map: mapp.map,
                })
                mark.addListener("click", () => {
                    show_information_parking(mark, item, "Parking - " + (index + 1))
                });
                parking_marker.push(mark)

            })
        }).catch((error) => {
            console.log(error)
            setLoader(false)

        })
    }


    const start_end_marker_draw = (v_summ) => {
        start_marker = new props.google.maps.Marker({
            position: { lat: v_summ[0].lat, lng: v_summ[0].lon },
            map: mapp.map,
            icon: {
                url: start,
                origin: new props.google.maps.Point(0, 0),
                anchor: new props.google.maps.Point(10, 24),
                scale: 2,
            },
        });
        start_marker.addListener("click", () => {
            show_start_end_info(start_marker, "Start", v_summ[0])
        });
        stop_marker = new props.google.maps.Marker({
            position: { lat: v_summ[v_summ.length - 1]?.lat, lng: v_summ[v_summ.length - 1]?.lon },
            map: mapp.map,
            icon: {
                url: stop,
                origin: new props.google.maps.Point(0, 0),
                anchor: new props.google.maps.Point(10, 24),
                scale: 2,
            },
        });
        stop_marker.addListener("click", () => {
            show_start_end_info(stop_marker, "Stop", v_summ[v_summ.length - 1])

        });
    }


    const map_onclick = () => {
        if (start_stop_info)
            start_stop_info.close()
    }


    const show_start_end_info = (marker, marker_type, item) => {
        if (start_stop_info)
            start_stop_info.close()
        start_stop_info = new props.google.maps.InfoWindow();
        start_stop_info.open({
            anchor: marker,
            map: mapp.map,
            shouldFocus: true,
        });
        start_stop_info.setContent(content_start_stop(item, marker_type))
    }

    const show_information = (marker, item) => {
        if (information)
            information.close()
        console.log("cfgvhbjnkml,", marker)
        information = new props.google.maps.InfoWindow();
        information.open({
            anchor: marker,
            map: mapp.map,
            shouldFocus: true,
        });
        information.setContent(content(item))
    }
    const show_information_parking = (marker, item, title) => {
        if (information)
            information.close()
        console.log("cfgvhbjnkml,", marker)
        information = new props.google.maps.InfoWindow();
        information.open({
            anchor: marker,
            map: mapp.map,
            shouldFocus: true,
        });
        information.setContent(parking_content(item, title))
    }

    const animate = () => {
        green_dot_marker?.map((mark) => {
            mark.setMap(null)
        })
        clearInterval(interval)
        if (is_animate) {
            setIs_animate(false)
            return
        }
        route.setMap(null);
        if (marker)
            marker.setMap(null)
        console.log(mapp)
        var cords = []
        setIs_animate(true)

        marker = new props.google.maps.Marker({
            position: { lat: vsumm[0].lat, lng: vsumm[0].lon },
            map: mapp.map,
            icon: {
                url: move,
                size: new props.google.maps.Size(32, 32),
                origin: new props.google.maps.Point(0, 0),
                anchor: new props.google.maps.Point(16, 16),
                scale: 2,
            },

        });
        var ind = ind1

        interval = setInterval(() => {
            let d = vsumm.slice(0, ind + 1)
            let poly_data = []
            d?.map((item) => {
                poly_data.push({ "lat": parseFloat(item.lat), "lng": parseFloat(item.lon) })
            })
            route.setMap(null);

            route = new props.google.maps.Polyline({
                path: poly_data,
                strokeColor: colors.running_cl,
                strokeOpacity: 2,
                strokeWeight: 3,
            });
            var bounds = new props.google.maps.LatLngBounds();
            vsumm?.map((item) => {
                bounds.extend({ "lat": parseFloat(item.lat), "lng": parseFloat(item.lon) });
            })
            setBounds(bounds)
            route.setMap(mapp.map);
            var myposition = { lat: vsumm[ind].lat, lng: vsumm[ind].lon };
            var image = RotateIcon.makeIcon(move).setRotation({ deg: Number(vsumm[ind].cog) }).getUrl();
            var icn = {
                url: move,
                size: new props.google.maps.Size(32, 32),
                origin: new props.google.maps.Point(0, 0),
                anchor: new props.google.maps.Point(16, 16),
                scale: 2,
            };
            if (image.length > 1000)
                icn.url = image;
            marker.setOptions({
                position: myposition,
                icon: icn,
            });
            if (ind != vsumm.length - 2) {
                ind++
                setInd1(ind)
            }
            else {
                clearInterval(interval);
                var i = 0
                vsumm?.map((item) => {
                    if (i % 3 == 0) {
                        var mark = new props.google.maps.Marker({
                            position: { lat: item.lat, lng: item.lon },
                            map: mapp.map,
                            icon: {
                                url: green,
                                origin: new props.google.maps.Point(0, 0),
                                anchor: new props.google.maps.Point(7, 7),
                            },
                        })
                        mark.addListener("click", () => {
                            show_information(mark, item)
                        });
                        green_dot_marker.push(mark)
                    }
                    i++
                })
                setIs_animate(false)
                setInd1(0)
                marker.setMap(null)
            }
        }, 50)
    }

    const content = (item) => {
        var contentString = '<div style="margin: 2; width: 320px; margin-right: 10px; margin-bottom: 10px; border: 1px solid grey;border-radius: 5px">'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> Speed</p><p  style="font-size:12px;flex:1;font-weight:400">' + item.speed + ' Km/hr </p></div>'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> Date Time</p><p  style="font-size:12px;flex:1;font-weight:400">' + item.dt + ' </p></div>'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> Distance</p><p  style="font-size:12px;flex:1;font-weight:400">' + item.odo + ' Km</p></div>'
            + '</div>'
        return contentString
    }
    const parking_content = (item, title) => {
        var contentString = '<div style="margin: 2; width: 320px; margin-right: 10px; margin-bottom: 10px; border: 1px solid grey;border-radius: 5px">'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400">' + title + '</p></div>'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> Duration</p><p  style="font-size:12px;flex:1;font-weight:400">' + item.duration + '</p></div>'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> Start</p><p  style="font-size:12px;flex:1;font-weight:400">' + item.stdt + ' </p></div>'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> End</p><p  style="font-size:12px;flex:1;font-weight:400">' + item.endt + ' </p></div>'
            + '</div>'
        return contentString
    }

    const content_start_stop = (item, type) => {
        var contentString = '<div style="margin: 2; width: 320px; margin-right: 10px; margin-bottom: 10px; border: 1px solid grey;border-radius: 5px">'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p  style="font-size:12px;flex:1;font-weight:400">' + type + '</p></div>'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> Date Time</p><p  style="font-size:12px;flex:1;font-weight:400">' + item.dt + ' </p></div>'
            + '</div>'
        return contentString
    }

    return (
        <Box sx={classes.container}>
            <Header ></Header>
            <Filters page={location.state} apply={apply} options={"District,Assembly,Category,Vehicle,From,To,Apply,Nav"}></Filters>
            <Box sx={classes.map} id="divToPrint">
                <Map
                    ref={(mapr) => mapp = mapr}
                    initialCenter={{ lat:map_default2?.lat?map_default2?.lat:map_default1?.lat, lng: map_default2?.lon?map_default2?.lon:map_default1?.lon }}
                    google={props.google}
                    zoom={map_default2?.zoom?map_default2?.zoom:map_default1?.zoom}
                    bounds={bounds}
                    onClick={() => { map_onclick() }}
                >
                </Map>
                {vsumm.length > 0 && !minimise &&
                    <Box sx={classes.statistics} width="320px">
                        <Box   sx={{ display: "flex", p: 1, backgroundColor: "divider",  alignItems: "center", justifyContent: "space-between" }}>
                            <Typography >Statistics</Typography> <RemoveOutlinedIcon sx={{cursor: "pointer"}} onClick={() => setMinimise(true)} />
                        </Box>
                        <Box sx={{ display: "flex", p: 1.3, borderBottom: 1, borderColor: "divider" }}>
                            <Typography sx={{ flex: 2 }}>Kilometer Travelled</Typography>
                            <Typography sx={{ flex: 2 }}>{dash?.km} Km</Typography>
                        </Box>
                        <Box sx={{ display: "flex", p: 1.3, borderBottom: 1, borderColor: "divider" }}>
                            <Typography sx={{ flex: 2 }}>Idle Time</Typography>
                            <Typography sx={{ flex: 2 }}>{dash?.engine_idle} Min</Typography>
                        </Box>
                        <Box sx={{ display: "flex", p: 1.3, borderBottom: 1, borderColor: "divider" }}>
                            <Typography sx={{ flex: 2 }}>Running Time</Typography>
                            <Typography sx={{ flex: 2 }}>{dash?.engine_time} Min</Typography>
                        </Box>
                        <Box sx={{ display: "flex", p: 1.3, borderBottom: 1, borderColor: "divider" }}>
                            <Typography sx={{ flex: 2 }}>Start Date Time</Typography>
                            <Typography sx={{ flex: 2 }}>{dash?.st}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", p: 1.3 }}>
                            <Typography sx={{ flex: 2 }}>End Date Time</Typography>
                            <Typography sx={{ flex: 2 }}>{dash?.et}</Typography>
                        </Box>
                    </Box>}
                {vsumm.length > 0 && minimise && <IconButton sx={classes.minimise} onClick={() => setMinimise(false)}><InfoOutlinedIcon /></IconButton>}
            </Box>
            {vsumm.length > 0 &&
                <IconButton sx={classes.animate_btn} onClick={() => { animate() }}>{is_animate ? <PauseIcon fontSize="inherit" />
                    : <PlayArrowIcon fontSize="inherit" />
                }</IconButton >
            }
            {is_animate &&
                <IconButton sx={[classes.animate_btn, { left: 250 }]} onClick={() => { apply(dat) }}>
                    <StopIcon fontSize="inherit" />
                </IconButton >
            }
            {/* {(vsumm.length > 0 && !is_animate) &&
                <Button variant="contained1" endIcon={<DownloadIcon />} sx={{ position: "absolute", top: 180, left: 10, backgroundColor: "white", color: "#444", boxShadow: 1, width: 170 }} onClick={() => { handleClickTakeScreenShot() }}>
                    Download
                </Button>
            }
            {(vsumm.length > 0 && !is_animate) &&
                <Button variant="contained1" endIcon={<DownloadIcon />} sx={{ position: "absolute", top: 280, left: 10, backgroundColor: "white", color: "#444", boxShadow: 1, width: 170 }} onClick={() => { download_kml() }}>
                    Download
                </Button>
            } */}

            {(vsumm.length > 0 && !is_animate) &&
                <>
                    <Button variant="contained1"
                        endIcon={<DownloadIcon />}
                        sx={{ position: "absolute", top: 180, left: 10, backgroundColor: "white", color: "#444", boxShadow: 1, width: 170 }}
                        onClick={handleClick_Dwn}
                        aria-controls={dwn_open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={dwn_open ? 'true' : undefined}
                    >
                        Download
                    </Button>
                    <Menu
                        sx={{
                            "& .MuiPaper-root": {
                                backgroundColor: "white"
                            }
                        }}
                        id="basic-menu"
                        anchorEl={dwn_anchorEl}
                        open={dwn_open}
                        onClose={handleClose_Dwn}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem sx={{ width: 170, backgroundColor: "white", color: "#444" }}
                            onClick={() => { handleClose_Dwn(); handleClickTakeScreenShot() }}>Image</MenuItem>
                        <MenuItem sx={{ width: 170, backgroundColor: "white", color: "#444" }}
                            onClick={() => { handleClose_Dwn(); download_kml() }}>KML</MenuItem>
                    </Menu>
                </>
            }
            {loader &&
                <Box sx={{ position: "absolute", top: "50%", left: "50%", backgroundColor: colors.dark1, p: 1.5, borderRadius: 50, boxShadow: 5, display: "flex", justifyContent: "center", alignItems: "center" }} >
                    <CircularProgress size={30} />
                </Box>
            }
            {(vsumm.length > 0 && !is_animate) &&
                <Box sx={{ position: "absolute", borderRadius: 1, top: 180, left: 200, backgroundColor: "white", color: "#444", boxShadow: 1, height: 36, px: 1, display: "flex", alignContent: "center", justifyContent: "center" }} >
                    <FormControlLabel
                        sx={{ color: "black" }}
                        control={
                            <Checkbox sx={{ color: colors.dark1 }} checked={park_switch} onChange={(e) => change_layout(e)} name="Parking Only" />
                        }
                        label="Parking Only"
                    />
                </Box>
            }

        </Box>
    )
}


export default Routeplay({
    apiKey: mapkey.mapKey
})(MapSub)