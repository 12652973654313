import React from 'react';
import { useEffect, useState } from "react";
import { Button, TextField, Box, Typography, DialogActions, IconButton, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import mapkey from '../config/map_key';
import { Map, GoogleApiWrapper as Multi } from 'google-maps-react';
import Cookies from 'universal-cookie';
import { ThreeDots } from 'react-loader-spinner'
import encryptStorage from "../encryptStorage";

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
//api 
import fetchData from '../api/fetchData';

// screen
import { Map_multi } from './map_multi';
import colors from '../config/colors';

// Styles
const classes = {
    container: {
        position: "absolute", top: 0, right: 0, bottom: 0, left: 0, backgroundColor: "white", display: "flex", flexDirection: "column", overflow: "hidden"
    },

    filter_box: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        pl: 1,
        mb: 3
    },
    filter: {
        width: 340,
        m: 2,
    },
    filter1: {
        flex: 1,
        m: 0.2,
        mt: 2
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99999,
        backgroundColor: "rgba(0,0,0,0.2)",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        position: "absolute"

    }

}

var interval;
export const MapSub = (props) => {
    const cookies = new Cookies();

    const [layout, setLayout] = useState({ "r": 2, "c": 3 });
    const [map_dom, setmap_dom] = useState([]);
    const [vehicle_details, setVehicle_details] = useState([]);
    const [filter, setFilter] = useState(false);
    const [dist, setDist] = useState([])
    const [dist_val, setDist_val] = useState(0)
    const [ac, setAc] = useState([])
    const [ac_val, setAc_val] = useState(0)
    const [cat, setCat] = useState([])
    const [cat_val, setCat_val] = useState(0)
    const [loader, setLoader] = useState(true)
    const [total_count, setTotal_count] = useState(0)
    const [prev_data, setPrev_data] = useState({})
    const [app_conf, setApp_conf] = useState(encryptStorage.getItem("appSetting"));


    useEffect(() => {
        apply({ "cnt_s": 0, "cnt_e": layout.r * layout.c, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") })
        get_district(0)
        get_category()
        return (() => {
            clearInterval(interval)
        })
    }, []);


    const get_district = (stat) => {
        if (cookies.get("priv") == 2) {
            setDist_val(0)
            get_ac(0)
            return
        }
        var data = { "status": stat, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv"), }
        fetchData.district(data).then((res) => {
            var { dist } = res;
            if (dist.length > 1) {
                dist.unshift({ "label": "All", "value": 0 });
            }
            if (dist.length == 1) {
                setDist_val(dist[0].value)
            }
            setDist(dist)
            get_ac(0)

        }).catch((error) => {
            console.log(error)
        })
    }

    const get_ac = (stat) => {
        var data = { "dist_fk": stat, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }

        fetchData.ac(data).then((res) => {
            var { assembly } = res;
            if (assembly.length > 1) {
                assembly.unshift({ "label": "All", "value": 0 });
            }
            if (assembly.length == 1) {
                setAc_val(assembly[0].value)
                // if ((props.page == "Map") &&  cookies.get("priv") == 2) {
                //     ac_change(assembly[0].value)
                // }

            } else {
                setAc_val(0)
            }
            setAc(assembly)

        }).catch((error) => {
            console.log(error)
        })
    }

    const get_category = () => {
        fetchData.category({}).then((res) => {
            var { category } = res;
            if (category.length > 1) {
                category.unshift({ "label": "All", "value": 0 });
            }
            if (category.length == 1) {
                setCat_val(category[0].value)
            }
            setCat(category)

        }).catch((error) => {
            console.log(error)
        })
    }


    const apply = (data) => {
        let conf_inter = app_conf?.interval?.Multi
        if (isNaN(conf_inter) || conf_inter < 5 || conf_inter == undefined)
            conf_inter = 30;
        console.log(conf_inter,"++++++++++++")
        setPrev_data(data)
        setLoader(true)

        fetchData.vehicle_list_multi(data).then((res) => {
            var { vehicle_list, total_count } = res;
            setVehicle_details(vehicle_list)
            setTotal_count(total_count)
            draw_layout(vehicle_list)


        }).catch((error) => {
            console.log(error)
        })
        clearInterval(interval)
        interval = setInterval(function () {

            fetchData.vehicle_list_multi(data).then((res) => {
                var { vehicle_list, total_count } = res;
                setVehicle_details(vehicle_list)
                setTotal_count(total_count)
                draw_layout(vehicle_list)


            }).catch((error) => {
                console.log(error)
            })
        }, (conf_inter ?? 15) * 1000)
    }

    const prev_next = (typ) => {
        if (typ == "next") {
            if (prev_data['cnt_e'] >= total_count) {
                prev_data['cnt_s'] = 0
                prev_data['cnt_e'] = layout.r * layout.c
                apply(prev_data)
                return

            }
            prev_data['cnt_s'] = prev_data['cnt_e']
            prev_data['cnt_e'] = prev_data['cnt_e'] + layout.r * layout.c
            apply(prev_data)



        }
        if (typ == "prev") {
            prev_data['cnt_s'] = prev_data['cnt_s'] - layout.r * layout.c
            prev_data['cnt_e'] = prev_data['cnt_e'] - layout.r * layout.c
            if (prev_data['cnt_s'] < 0 || prev_data['cnt_e'] < 1) {
                prev_data['cnt_s'] = total_count - 6
                prev_data['cnt_e'] = total_count
            }
            apply(prev_data)
        }

    }

    const filter_apply = () => {
        setFilter(false)
        apply({ "dist_fk": dist_val, "ac_fk": ac_val, "category_fk": cat_val, "cnt_s": 0, "cnt_e": layout.r * layout.c, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") })
    }

    const draw_layout = (vehicle_list) => {
        var grid_row = []
        var cnt = 0
        for (var i = 0; i < layout.r; i++) {
            var grid_col = []
            for (var j = 0; j < layout.c; j++) {
                if (vehicle_list[cnt]) {
                    grid_col.push(
                        <Map_multi data={vehicle_list[cnt]} google={props.google} sx={{ flex: 1, alignItems: "center", display: "flex", justifyContent: "center", border: 0.2, borderColor: "white", flexDirection: "column", position: "relative" }} />
                    )
                }
                else {
                    grid_col.push(
                        <Box data={{}} google={props.google} sx={{ flex: 1, alignItems: "center", display: "flex", justifyContent: "center", border: 0.2, borderColor: "white", flexDirection: "column", position: "relative", backgroundColor: "white" }} />
                    )
                }
                cnt++
            }
            grid_row.push(<Box sx={{ flex: 1, display: "inline-flex", flexDirection: "row", }}>{grid_col}</Box>)
        }
        setmap_dom(grid_row)
        setLoader(false)

    }

    return (
        <Box sx={classes.container}>
            <Box sx={{ position: "absolute", bottom: 30, left: 0, zIndex: 9999, width: 50, height: 50, backgroundColor: colors.dark1, boxShadow: 1, display: "flex", alignItems: "center", justifyContent: "center", borderTopRightRadius: 7, borderBottomRightRadius: 7 }}>
                <IconButton onClick={() => { prev_next("prev") }} sx={{ width: 40, height: 40, }}>
                    <ArrowBackIosIcon />
                </IconButton>
            </Box>
            <Box sx={{ position: "absolute", bottom: 30, right: 0, zIndex: 9999, width: 50, height: 50, backgroundColor: colors.dark1, boxShadow: 1, display: "flex", alignItems: "center", justifyContent: "center", borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}>
                <IconButton onClick={() => { prev_next("next") }} sx={{ width: 40, height: 40, }}>
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>

            {loader ?
                <Box sx={classes.loader}>
                    <ThreeDots
                        height="100"
                        width="100"
                        radius="7"
                        color="#0288d1"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </Box> :
                map_dom

            }
            {!filter ?
                <Box sx={{ position: "absolute", top: 70, right: 0, zIndex: 9999, width: 50, height: 50, backgroundColor: colors.dark1, boxShadow: 1, display: "flex", alignItems: "center", justifyContent: "center", borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}>
                    <IconButton onClick={() => setFilter(true)} sx={{ width: 40, height: 40, }}>
                        <FilterAltIcon />
                    </IconButton>
                </Box> :
                <Box sx={{ position: "absolute", top: 70, right: 0, zIndex: 9999, width: 380, backgroundColor: colors.dark1, boxShadow: 1, display: "flex", alignItems: "center", justifyContent: "center", borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}>
                    <Box sx={classes.filter_box}>
                        <Box sx={{ height: 50, display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: 1, borderColor: "divider", pl: 3 }}>
                            <Typography variant='subtitle1'>
                                Filters
                            </Typography>
                            <IconButton onClick={() => setFilter(false)} sx={{ mr: 1 }}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        {(cookies.get("priv") == 0 || cookies.get("priv") == 1) &&
                            <FormControl fullWidth size='small' sx={classes.filter}>
                                <InputLabel >District</InputLabel>
                                <Select
                                    MenuProps={{
                                        style: { zIndex: 35001 }
                                    }}
                                    size='small'
                                    label="District"
                                    value={dist_val}
                                    onChange={(e) => { setDist_val(e.target.value); get_ac(e.target.value) }}
                                >
                                    {
                                        dist?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        }
                        <FormControl fullWidth size='small' sx={classes.filter}>
                            <InputLabel >Assembly</InputLabel>
                            <Select
                                MenuProps={{
                                    style: { zIndex: 35001 }
                                }}
                                size='small'
                                label="Assembly"
                                value={ac_val}
                                onChange={(e) => { setAc_val(e.target.value); }}
                            >
                                {
                                    ac?.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>

                        <FormControl fullWidth size='small' sx={classes.filter}>
                            <InputLabel >Category</InputLabel>
                            <Select
                                MenuProps={{
                                    style: { zIndex: 35001 }
                                }}
                                size='small'
                                label="Category"
                                value={cat_val}
                                onChange={(e) => { setCat_val(e.target.value); }}
                            >
                                {
                                    cat?.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            <Button variant="contained" sx={classes.filter1} onClick={() => filter_apply()}>Apply</Button>

                        </FormControl>
                    </Box>
                </Box>
            }
        </Box>

    )
}


export default Multi({
    apiKey: mapkey.mapKey
})(MapSub)


