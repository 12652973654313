import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Navigate, useNavigate } from "react-router-dom";
import { Button, TextField, Box, IconButton, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { Map, Polyline, Marker, GoogleApiWrapper as RouteFence, Circle, InfoWindow } from 'google-maps-react';
import { ThreeDots } from 'react-loader-spinner'
import reddot from '../assets/reddot.png'
import greendot from '../assets/green_dot1.png'
import bluedot from '../assets/blue_dot.png'
import move from '../assets/move1.png'
import LoadingButton from '@mui/lab/LoadingButton';
import Cookies from 'universal-cookie';
import encryptStorage from "../encryptStorage";

// components
import { Header } from './component/header';
import { Filters } from './component/filters';

//Icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
// colors
import colors from '../config/colors';

// map API Key
import mapkey from '../config/map_key';

// Api
import fetchData from '../api/fetchData';

// Styles
const classes = {
    container: {
        position: "absolute",
        top: 170,
        left: 0,
        right: 0,
        bottom: 0,
        flexDirection: "row"
    },
    route_filter: {
        width: 270,
        flex: 1,
        m: 2,
    },
    add_btn: {
        width: 270,
        flex: 1,
        mx: 2, my: 1,
        color: "white", backgroundColor: "divider"
    },
    points_filter: {
        maxWidth: 270,
        flex: 1,
        m: 2,
    },
    map: {
        position: "absolute",
        top: 120,
        left: 350,
        right: 0,
        bottom: 0,
    },
    route_list: {
        position: "absolute",
        top: 170,
        left: 0,
        width: 350,
        bottom: 0,
        backgroundColor: colors.dark1,
        overflow: "auto", overflowY: "scroll",
        justifyContent: "center", pt: 2
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99999,
        backgroundColor: "rgba(0,0,0,0.7)"
    },
    addicon: {
        height: 25,
        width: 25,
        color: "white"
    },
    removeicon: {
        height: 25,
        width: 25,
    },
    point_list: {
        "display": "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    info_cont: {
        display: "flex",
        border: 1,
        borderBottom: "1px solid grey",
        paddingLeft: 8,
        paddingRight: 8
    },
    info_cont1: {
        display: "flex",
        border: 1,
        paddingLeft: 8,
        paddingRight: 8
    },
    label: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400,
    },
    value: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400
    },
}



var mapp;

export const Route_Fence_Create = (props) => {
    var [loading_btn, setLoading_btn] = useState(false);
    const [route_name, setRoute_name] = useState("")
    const [start_point, setStart_point] = useState({})
    const [end_point, setEnd_point] = useState({})
    const [points, setPoints] = useState([]);
    const [loader, setLoader] = useState(false)
    const [trip_points, setTrip_points] = useState([]);
    const [bounds, setBounds] = useState([])
    const [coords, setCoords] = useState([])
    const [Label, setLabel] = useState(null);
    const [active_marker, setActive_marker] = useState(null)
    const [active_item, setActive_item] = useState({})
    const [info, setInfo] = useState(false)
    const [datas, setDatas] = useState({})

    const appSetting = encryptStorage.getItem("appSetting");
    const map_default1 = { "lat":16.2160, "lon":  77.3566, "zoom": 6 }
    const map_default2 = appSetting?.map_default


    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }


    useEffect(() => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        }
    }, []);

    const apply = (data) => {
        if (!data.ac_fk) {
            alert_trigger("error", "Please select assembly")
            return
        }
        if (!data.category_fk) {
            alert_trigger("error", "Please select category")
            return
        }
        setDatas(data)
        setLoader(true)
        fetchData.get_trip_points(data).then((res) => {
            var { fence_list } = res;
            setTrip_points(fence_list)
            setLoader(false)
        }).catch((error) => {
            console.log(error)
        })
    }



    const handleStartpoint = (e) => {
        var data = get_current_point_data(e)
        var item = { "label": "Start Point", "lat": parseFloat(data?.lat), "lng": parseFloat(data?.lon), "name": data?.label, }
        console.log(item, "start_point")
        setStart_point(item)
        draw_route_markers(item, end_point, points)

    }

    const handleEndpoint = (e) => {
        var data = get_current_point_data(e)
        var item = { "label": "End Point", "lat": parseFloat(data?.lat), "lng": parseFloat(data?.lon), "name": data?.label, }
        console.log(item, "end_point")
        setEnd_point(item)
        draw_route_markers(start_point, item, points)

    }


    const addpointFields = () => {
        setPoints([...points, { "label": "", "lat": "", "lng": "", "name": "" }])
    }

    const removepointFields = (i) => {
        let newFormValues = [...points];
        newFormValues.splice(i, 1);
        setPoints(newFormValues)
    }

    const pointshandleChange = (i, e) => {
        let newFormValues = [...points];
        var data = get_current_point_data(e)
        newFormValues[i]["label"] = (parseInt(i) + 1)
        newFormValues[i]["lat"] = parseFloat(data?.lat)
        newFormValues[i]["lng"] = parseFloat(data?.lon)
        newFormValues[i]["name"] = data?.label

        setPoints(newFormValues);
        draw_route_markers(start_point, end_point, newFormValues)

    }

    const get_current_point_data = (e) => {
        var item = trip_points.find(({ value }) => value == e.target.value)
        return item
    }


    const apply_route_fence = () => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (route_name == "") {
            alert_trigger("error", "Please enter the route name")
            return
        }
        if (!datas.ac_fk) {
            alert_trigger("error", "Please select assembly")
            return
        }
        if (!datas.category_fk) {
            alert_trigger("error", "Please select category")
            return
        }
        let way_points_ar = []
        way_points_ar.push(start_point)
        points?.map((item) => {
            way_points_ar.push(item)
        })
        way_points_ar.push(end_point)
        var rp = ''


        coords.map((item) => {
            rp = rp + '(' + item.lat() + ',' + item.lng() + ")#";
        })
        var data = { "route_name": route_name, "way_points": way_points_ar, "route_point": rp, "cat_fk": datas.category_fk, "ac_fk": datas.ac_fk, "uid": uid }
        console.log(data, "data_submit")
        setLoading_btn(true)
        fetchData.put_trip_points(data).then((res) => {
            var { rval, msg } = res;
            if (rval > 0) {
                alert_trigger("success", msg)
            } else {
                alert_trigger("error", msg)
            }
            setLoading_btn(false)
        }).catch((error) => {
            console.log(error)
            setLoading_btn(false)
        })
    }


    const draw_route_markers = (start, end, waypoints) => {
        const waypts = waypoints.map(waypoint => ({
            location: new window.google.maps.LatLng(waypoint.lat, waypoint.lng),
            stopover: true
        }));

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route({
            origin: start,
            destination: end,
            waypoints: waypts,
            travelMode: window.google.maps.TravelMode.DRIVING,
            provideRouteAlternatives: true,

        }, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                const path = result.routes[0].overview_path;
                setCoords(path);
                var res_ar = []
                path.map((items) => {
                    res_ar.push({ "lat": items.lat(), "lon": items.lng() })
                })
                console.log("res_ar", res_ar)
                const bounds = new window.google.maps.LatLngBounds();
                result.routes[0].overview_path.forEach(function (point) {
                    bounds.extend(point);
                    setBounds(bounds)
                });
            } else {
                console.error('Error fetching directions:', result);
            }
        });
    };

    const onMarkerClick = (props, marker, e, point, label) => {
        setLabel(label)
        setActive_marker(marker)
        setInfo(true)
        setActive_item(point)
    }


    return (
        <>
            <Header ></Header>
            <Filters page={"Trip"} options={"District,Assembly,Category,Apply,Nav"} btn={"Add"} apply={apply}></Filters>
            <Box sx={{ position: "absolute", top: 120, left: 0, width: 350, height: 50,  justifyContent: "center",}} display="flex" >
                <Button sx={{ borderBottom: 4, width: 120, borderColor: "primary", borderRadius: 0, flex: 1 }}>Create</Button>
                <Button onClick={() => { navigate("/route-assign") }} sx={{ borderBottom: 4, width: 120, borderColor:'#1A1A1A', borderRadius: 0, flex: 1 }}>Assign</Button>
            </Box>
            <Box sx={classes.route_list}>
                <FormControl fullWidth size='small' sx={classes.route_filter}>
                    <TextField
                        InputLabelProps={{ "shrink": true }}
                        size='small'
                        label="Route Name"
                        onChange={(e) => {
                            setRoute_name(e.target.value)
                        }}
                        variant="outlined" /></FormControl>
                <Box sx={classes.point_list}>
                    <FormControl fullWidth size='small' sx={classes.points_filter}>
                        <InputLabel shrink>Start Point</InputLabel>
                        <Select
                            notched
                            size='small'
                            label="Start Point"
                            value={start_point.value}
                            onChange={(e) => { handleStartpoint(e) }}
                        >
                            {
                                trip_points?.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>

                </Box>
                {points?.map((element, index) => (
                    <Box sx={classes.point_list}>
                        <FormControl fullWidth size='small' sx={classes.points_filter}>
                            <InputLabel shrink>{"Waypoint - " + (parseInt(index) + 1)}</InputLabel>
                            <Select
                                notched
                                size='small'
                                label={"Waypoint - " + (parseInt(index) + 1)}
                                value={element.value}
                                name={"Waypoint"}
                                onChange={(e) => { pointshandleChange(index, e) }}
                            >
                                {
                                    trip_points?.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>

                        <IconButton onClick={() => { removepointFields() }} >
                            <RemoveCircleIcon sx={classes.removeicon} />
                        </IconButton>
                    </Box>
                ))}
                <Box sx={classes.point_list}>

                    <FormControl fullWidth size='small' sx={classes.points_filter}>
                        <InputLabel shrink>End Point</InputLabel>
                        <Select
                            notched
                            size='small'
                            label="End Point"
                            value={end_point.value}
                            onChange={(e) => { handleEndpoint(e) }}
                        >
                            {
                                trip_points?.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>

                </Box>
                <Button onClick={() => { addpointFields() }} sx={classes.add_btn}>
                    Add Waypoint
                </Button>
                {loading_btn == true ?
                    <LoadingButton
                        loading
                        loadingPosition="center"
                        variant="contained"
                        sx={classes.route_filter}
                    >Loading...
                    </LoadingButton> :
                    <Button variant="contained" fullWidth sx={classes.route_filter} onClick={() => apply_route_fence()}>Submit</Button>
                }
            </Box>
            {loader &&
                <Box sx={[classes.map, classes.loader]}>
                    <ThreeDots
                        height="100"
                        width="100"
                        radius="7"
                        color="#0288d1"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                    />
                </Box>}
            <Box sx={classes.map}>
                <Map
                    ref={(mapr) => mapp = mapr}
                    bounds={bounds}
                    initialCenter={{ lat:map_default2?.lat?map_default2?.lat:map_default1?.lat, lng: map_default2?.lon?map_default2?.lon:map_default1?.lon }}
                    google={props.google}
                    zoom={map_default2?.zoom?map_default2?.zoom:map_default1?.zoom}
                >
                    {start_point.lat && start_point.lng && (
                        <Marker
                            position={{ lat: start_point.lat, lng: start_point.lng }}
                            onClick={(props, marker, e) => onMarkerClick(props, marker, e, start_point, "Start")}
                            icon={{
                                url: greendot,
                                anchor: new props.google.maps.Point(14, 14),
                                scaledSize: new props.google.maps.Size(25, 25),
                            }}
                        />
                    )}
                    {end_point.lat && end_point.lng && (
                        <Marker
                            position={{ lat: end_point.lat, lng: end_point.lng }}
                            onClick={(props, marker, e) => onMarkerClick(props, marker, e, end_point, "End")}
                            icon={{
                                url: reddot,
                                anchor: new props.google.maps.Point(14, 14),
                                scaledSize: new props.google.maps.Size(25, 25),
                            }}
                        />
                    )}
                    {points.map((waypoint, index) => (
                        waypoint.lat && waypoint.lng && (
                            <Marker
                                key={index}
                                onClick={(props, marker, e) => onMarkerClick(props, marker, e, waypoint, `Waypoint - ${index + 1}`)}
                                position={{ lat: waypoint.lat, lng: waypoint.lng }}
                                icon={{
                                    url: bluedot,
                                    anchor: new props.google.maps.Point(14, 14),
                                    scaledSize: new props.google.maps.Size(25, 25),
                                }}
                            />
                        )
                    ))}

                    <Polyline
                        path={coords}
                        strokeColor="green"
                        strokeOpacity='6.0'
                        strokeWeight='4'
                    />

                    <InfoWindow
                        visible={info}
                        marker={active_marker}
                    >
                        <div style={{ width: 200, marginRight: 12, marginBottom: 12, border: "1px solid grey", borderRadius: 5 }}>
                            <div style={classes.info_cont}>
                                <p style={{ textAlign: 'center', ...classes.value }}>{Label} - {active_item.name}</p>
                            </div>

                        </div>
                    </InfoWindow>


                </Map>
            </Box>
        </>
    )
}

export default RouteFence({
    apiKey: mapkey.mapKey
})(Route_Fence_Create)