import React from 'react';
import { useState, useEffect, useRef } from "react";
import { Button, TextField, Box, Typography, DialogActions, Avatar, IconButton, } from '@mui/material';
import { Map, InfoWindow, Marker, GoogleApiWrapper as Reportroute, Polyline } from 'google-maps-react';
import CloseIcon from '@mui/icons-material/Close';
import Cookies from 'universal-cookie';
import { Navigate, useNavigate } from "react-router-dom";


// Api
import fetchData from '../api/fetchData';

//Icons
import start from '../assets/start1.png'
import stop from '../assets/stop1.png'

// colors
import colors from '../config/colors';

// map API Key
import mapkey from '../config/map_key';

// Styles
const classes = {
    container: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        flexDirection: "row",
        zIndex: 999,
        backgroundColor: "white"
    },
    closebtn: {
        position: "absolute",
        top: 0,
        alignSelf: "center",
        zIndex: 999,
        backgroundColor: "white",
        left: '49%',
        boxShadow: 2,
        color: "grey"

    },
    info_cont: {
        display: "flex",
        border: 1,
        borderBottom: "1px solid grey",
        paddingLeft: 8,
        paddingRight: 8
    },
    info_cont1: {
        display: "flex",
        border: 1,
        paddingLeft: 8,
        paddingRight: 8
    },
    label: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400,
    },
    value: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400
    },
}




var marker;
var info;

export const MapSub = (props) => {
    var mapp = useRef();
    const [vsumm, setVsumm] = useState([])
    const [coords, setCoords] = useState([])
    const [bounds, setBounds] = useState([])
    const [act_marker, setAct_marker] = useState([])
    const [act_info, setAct_info] = useState(false)
    const navigate = useNavigate();


    var start_marker = useRef();

    var stop_marker = useRef();
    useEffect(() => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        } else {
            apply()
        }
    }, []);




    const apply = () => {
        fetchData.route_play(props.item).then((res) => {
            var { route_play } = res;
            if (route_play.length > 1) {
                setVsumm(route_play)
                console.log(route_play)
                let cords = []
                route_play?.map((item) => {
                    cords.push({ "lat": parseFloat(item.lat), "lng": parseFloat(item.lon) })
                })
                setCoords(cords)
                var bounds = new props.google.maps.LatLngBounds();
                for (var i = 0; i < cords.length; i++) {
                    bounds.extend(cords[i]);
                }
                setBounds(bounds)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const onMarkerClick = (props, marker, e) => {
        if (marker.id == 1) {
            setAct_info(true)
            setAct_marker(start_marker.marker)
            console.log(start_marker)
        }
        else {
            setAct_info(true)
            setAct_marker(stop_marker.marker)
        }

    }




    return (
        <Box sx={classes.container}>
            <Map
                initialCenter={{ lat: 20.5937, lng: 78.9629 }}
                google={props.google}
                ref={(mapr) => mapp = mapr}
                zoom={14}
                bounds={bounds}
                onClick={() => { setAct_marker(5); setAct_info(false) }}

            >
                <Polyline
                    path={coords}
                    strokeColor={colors.running_cl}
                    strokeOpacity={2}
                    strokeWeight={3} />
                <Marker
                    ref={(marker) => start_marker = marker}
                    onClick={onMarkerClick}
                    title={"Start"}
                    id={1}
                    position={{ lat: parseFloat(vsumm[0]?.lat), lng: parseFloat(vsumm[0]?.lon) }}
                    icon={{
                        url: start,
                        scaledSize: new props.google.maps.Size(35, 35),
                    }}
                >
                </Marker>
                <Marker
                    ref={(marker) => stop_marker = marker}
                    onClick={onMarkerClick}
                    title={"Stop"}
                    id={2}
                    position={{ lat: parseFloat(vsumm[vsumm.length - 1]?.lat), lng: parseFloat(vsumm[vsumm.length - 1]?.lon) }}
                    icon={{
                        url: stop,
                        // anchor: new props.google.maps.Point(32, 32),
                        scaledSize: new props.google.maps.Size(35, 35),
                    }}
                >
                </Marker>
                <InfoWindow
                    visible={act_info}
                    marker={act_marker}
                >
                    <div style={{ margin: 2, width: 300, marginRight: 10, marginBottom: 10, border: "1px solid grey", borderRadius: 5, }}>
                        <div style={classes.info_cont}>
                            <p style={classes.label}>{act_marker.id == 1 ? "Start" : "Stop"}</p>
                        </div>
                        <div style={classes.info_cont}>
                            <p style={classes.label}>Vehicle No</p>
                            <p style={classes.value}>{props.item?.v_no}</p>
                        </div>
                        <div style={classes.info_cont}>
                            <p style={classes.label}>Vehicle Type</p>
                            <p style={classes.value}>{props.item?.name}</p>
                        </div>
                        <div style={classes.info_cont}>
                            <p style={classes.label}>Date and Time</p>
                            <p style={classes.value}>{act_marker.id == 1 ? vsumm[0]?.dt : vsumm[vsumm.length - 1]?.dt}</p>
                        </div>
                        <div style={classes.info_cont1}>
                            <p style={classes.label}>Location</p>
                            <p style={classes.value}>{act_marker.id == 1 ? props.item?.start_a : props.item?.end_a}</p>
                        </div>
                    </div>
                </InfoWindow>
            </Map>
            <IconButton sx={classes.closebtn} onClick={props.handleClose}>
                <CloseIcon />
            </IconButton>
        </Box>
    )
}


export default Reportroute({
    apiKey: mapkey.mapKey
})(MapSub)

