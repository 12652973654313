import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { lightBlue } from '@mui/material/colors';

// Import the functions you need from the SDKs you need

// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// import { getMessaging, getToken, onMessage } from "firebase/messaging";



//Component
import { Login } from "./screens/login";
import MapScreen from "./screens/map";
import ListScreen from './screens/list'
import FenceScreen from './screens/fence'
import Routeplay from "./screens/routeplay";
import Route_Fence_Create from './screens/route_create'
import EngineReport from "./screens/engine_report";
import ParkingReport from "./screens/parking_report";
import OverSpeedReport from "./screens/overspeed_report";
import Dashboard from "./screens/dashboard";
import Notif_report from "./screens/notif_report";
import Alert_settings from "./screens/alert_settings";
import Admin from "./screens/admin";
import Logs from "./screens/logs";
import Summary_report from "./screens/summary_report";
import Multi from "./screens/multi";
import Route_assign from "./screens/route_assign";
import Alerts from "./screens/alerts"
import Trip from "./screens/trip"
import Trip_Create  from "./screens/trip_create";
import Manage_Alerts from "./screens/manage_alerts";
import Report_Idle from "./screens/report_idle";
import Report_Speed from "./screens/report_speed";

// const firebaseConfig = {
//   apiKey: "AIzaSyDw8oUa5DxdChWIdlV34ub3o7_6B4uFSDo",
//   authDomain: "tracking-web-ele.firebaseapp.com",
//   projectId: "tracking-web-ele",
//   storageBucket: "tracking-web-ele.appspot.com",
//   messagingSenderId: "1070984078437",
//   appId: "1:1070984078437:web:b2d40601711e0b5011128c",
//   measurementId: "G-BC2MBDH3M7"
// };
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const messaging = getMessaging(app);
// function requestPermission() {
//   console.log('Requesting permission...');
//   Notification.requestPermission().then((permission) => {
//     if (permission === 'granted') {
//       console.log('Notification permission granted.');
//     }
//   })
// }
// requestPermission()
// getToken(messaging, { vapidKey: '<YOUR_PUBLIC_VAPID_KEY_HERE>' }).then((currentToken) => {
//   if (currentToken) {
//     // Send the token to your server and update the UI if necessary
//     // ...
//   } else {
//     // Show permission request UI
//     console.log('No registration token available. Request permission to generate one.');
//     // ...
//   }
// }).catch((err) => {
//   console.log('An error occurred while retrieving token. ', err);
//   // ...
// });

// onMessage(messaging, (payload) => {
//   console.log('Message received. ', payload);
//   // ...
// });


const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: lightBlue[700],
    },

  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    htmlFontSize: 17,
    subtitle1: {
      fontWeight: 600,
    },
    subtitle: {
      fontWeight: 600,
      fontSize: 15,
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 15,
    },
    subtitle3: {
      fontWeight: 300,
      fontSize: 14,
    },
    p: {
      fontWeight: 400,
      fontSize: 13,
    },
    button: {
      fontWeight: 500,
      textTransform: "capitalize",
      fontSize: 14,
    },
  },

});


export const UserContext = React.createContext(null);

export default function App() {
  const [alert_open, setAlert_open] = React.useState(false);
  const [alert_val, setAlert_val] = React.useState([]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <UserContext.Provider value={{ alert_open: alert_open, setAlert_open: setAlert_open, alert_val: alert_val, setAlert_val: setAlert_val }}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/map" element={<MapScreen />} />
            <Route path="/list" element={<ListScreen />} />
            <Route path="/fence" element={<FenceScreen />} />
            <Route path="/route-fence" element={<Route_Fence_Create />} />
            <Route path="/route-assign" element={<Route_assign />} />
            <Route path="/routeplay" element={<Routeplay />} />
            <Route path="/engine_report" element={<EngineReport />} />
            <Route path="/parking_report" element={<ParkingReport />} />
            <Route path="/overspeed_report" element={<OverSpeedReport />} />
            <Route path="/notification" element={<Notif_report />} />
            <Route path="/alert_settings" element={<Alert_settings />} />
            <Route path="/logs" element={<Logs />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/summary" element={<Summary_report />} />
            <Route path="/multi" element={<Multi />} />
            <Route path="/alerts" element={<Alerts />}/>
            <Route path="/trip" element={<Trip />}/>
            <Route path="/trip-create" element={<Trip_Create />} />
            <Route path="/manage_alerts" element={<Manage_Alerts/>} />
            <Route path="/report_idle" element={<Report_Idle/>} />
            <Route path="/report_speed" element={<Report_Speed/>} />
          </Routes>
        </UserContext.Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

