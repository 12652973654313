import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Box, IconButton, TextField, Button, Typography, FormControl } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { ThreeDots } from 'react-loader-spinner'


// components
import { Filters } from './component/filters';
import Reportmap from './report_map';

// colors
import colors from '../config/colors';

// Api
import fetchData from '../api/fetchData';

//icons
import MyLocationIcon from '@mui/icons-material/MyLocation';


const classes = {
    container: {
        position: "absolute",
        top: 10,
        left: 0,
        right: 0,
        bottom: 0,
        '& .super-app-theme--header': {
            backgroundColor: colors.dark1,
        },
    },
    data_table: {
        position: "absolute",
        top: 80,
        left: 10,
        right: 10,
        bottom: 10,
    },
    loader: {
        position: "absolute",
        top: 80,
        left: 10,
        right: 10,
        bottom: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    filter_box: {
        flex: 2,
        display: "flex",
        flexDirection: "row",
        pl: 1
    },
    filter: {
        maxWidth: 200,
        flex: 1,
        m: 1,
    },
    text_box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    text_field: {
        color: 'grey.400'
    }
}

export default function Logs() {
    const [value, setValue] = useState(0)
    const [imei_val, setImei_val] = useState("")
    const [device_status, setDevice_status] = useState("")
    const [device_lst, setDevice_lst] = useState([]);
    const [loader, setLoader] = useState(false)

    const column = [
        { field: 'imei', headerName: "IMEI", flex: 1, headerClassName: 'super-app-theme--header', sortable: false, renderCell: (params) => imei_val, },
        { field: 'servertime', headerName: 'Date Time', flex: 1, headerClassName: 'super-app-theme--header', sortable: false },
        { field: 'latitude', headerName: 'Latitude', flex: 1, headerClassName: 'super-app-theme--header', sortable: false },
        { field: 'longitude', headerName: 'Longitude', flex: 1, headerClassName: 'super-app-theme--header', sortable: false },
    ]


    const apply = () => {
        setLoader(true)
        var data = { "imei": imei_val }
        fetchData.device_log(data).then((res) => {
            var { device_lst, status } = res;
            setDevice_lst(device_lst)
            setDevice_status(status)
            setValue(1)
            setLoader(false)
        }).catch((error) => {
            console.log(error)
            setLoader(false)
        })
    }



    return (
        <Box sx={classes.container}>
            <Box sx={classes.filter_box}>
                <FormControl fullWidth size='small' sx={classes.filter}><TextField size='small' label="IMEI" variant="outlined" InputLabelProps={{ shrink: true }}
                    onChange={(e) => { setImei_val(e.target.value) }} value={imei_val} autoComplete='off'
                /></FormControl>
                <Button variant="contained" sx={classes.filter} onClick={() => apply()}>Submit</Button>
                {device_status != "" &&
                    <TextField size='small' label="Status" sx={classes.filter} variant="outlined" value={device_status} disabled={true} />
                }
            </Box>
            {loader && <Box sx={classes.loader}>
                <ThreeDots
                    height="100"
                    width="100"
                    radius="7"
                    color="#0288d1"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                /></Box>
            }
            {device_lst.length > 0 ?
                <Box sx={classes.data_table}>
                    <DataGrid
                        disableColumnSelector
                        disableDensitySelector
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                        rows={device_lst}
                        columns={column}
                    />
                </Box>
                : <Box sx={classes.text_box}><Typography variant="h6" sx={classes.text_field}>
                    {value > 0 ? "No Data received yet from the device (or) Invalid IMEI" : ""}
                </Typography></Box>
            }
        </Box>
    )
}
