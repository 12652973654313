import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Box, IconButton } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { ThreeDots } from 'react-loader-spinner'
import Cookies from 'universal-cookie';
import { Navigate, useNavigate } from "react-router-dom";


// components
import { Filters } from './component/filters';

// colors
import colors from '../config/colors';


// Api
import fetchData from '../api/fetchData';




const classes = {
    container: {
        position: "absolute",
        top: 10,
        left: 0,
        right: 0,
        bottom: 0,
        '& .super-app-theme--header': {
            backgroundColor: colors.dark1,
        },
    },
    data_table: {
        position: "absolute",
        top: 70,
        left: 10,
        right: 10,
        bottom: 10,
    },
    loader: {
        position: "absolute",
        top: 70,
        left: 10,
        right: 10,
        bottom: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}

var vehicle_info1 = {}

export default function Report_Speed() {
    const [vehicle_details, setVehicle_details] = useState([]);
    const [loader, setLoader] = useState(false)
    const [column, setColumn] = React.useState([]);
    const navigate = useNavigate();


    

    useEffect(() => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        } else {
            set_table_col(vehicle_info1)
        }
    }, []);


    const set_table_col = (vehicle_info) => {
        const column = [
            { field: 'dname', headerName: 'District', width:200, headerClassName: 'super-app-theme--header', },
            { field: 'ac_name', headerName: 'Assembly', width:200, headerClassName: 'super-app-theme--header', },
            { field: 'an_no', headerName: 'AC No', width:80, headerClassName: 'super-app-theme--header', },
            { field: 'category', headerName: 'Category', width:200, headerClassName: 'super-app-theme--header', },
            { field: 'v_no', headerName: 'Vehicle No', width:150, headerClassName: 'super-app-theme--header', },
            { field: 'name', headerName: 'Vehicle Type', width:100, headerClassName: 'super-app-theme--header', },
            { field: 'driver_name', headerName: 'Driver Name', width:200, headerClassName: 'super-app-theme--header' },
            { field: 'driver_no', headerName: 'Driver No', width:150, headerClassName: 'super-app-theme--header' },
            { field: 'incharge_name', headerName: 'SO Name ', width:200, headerClassName: 'super-app-theme--header', },
            { field: 'incharge_no', headerName: 'SO No', width:150, headerClassName: 'super-app-theme--header', },
            { field: 'speed', headerName: 'Speed (km/hr)', width:200, headerClassName: 'super-app-theme--header', },
            { field: 'addr', headerName: 'Current Location', width:300, headerClassName: 'super-app-theme--header', },   
        ]
        setColumn(column)
    }


    const apply = (data) => {
        setLoader(true) 
        fetchData.speed_report_top(data).then((res) => {
            var { vehicle_list } = res;
            setVehicle_details(vehicle_list)
            setLoader(false)
            get_addr(vehicle_list)

        }).catch((error) => {
            console.log(error)
        })
    }

    const get_addr = (vehicle_list) => {
        var cords = []
        vehicle_list?.map((item) => {
            cords.push({ "lat": parseFloat(item.lat), "lon": parseFloat(item.lon), "id": item.id })
        })
        fetchData.v_addr({ "latlon": cords }).then((res) => {
            var addr = res.addr
            var v_new_list = []
            vehicle_list?.map((item) => {
                var it = item
                var act = addr.find(({ id }) => id == item.id);

                it["addr"] = act.formattedAddress
                v_new_list.push(item)
            })
            setVehicle_details(v_new_list)


        }).catch((error) => {
            console.log(error)
        })
    }


    return (
        <Box sx={classes.container}>
                        <Filters page={"List"} apply={apply} options={"District,Assembly,Category,Apply"}></Filters>

            {loader ? <Box sx={classes.loader}>
                <ThreeDots
                    height="100"
                    width="100"
                    radius="7"
                    color="#0288d1"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                /></Box>
                : <Box sx={classes.data_table}>
                      <DataGrid
                        disableColumnSelector
                        disableDensitySelector
                        disableSelectionOnClick
                        disableRowSelectionOnClick 
                        rows={vehicle_details}
                        columns={column}
                    />
                </Box>
            }
        </Box>
    )
}
