import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Button, TextField, Box, DialogTitle, Dialog, DialogContent, MenuItem, Select, FormControl, InputLabel, FormControlLabel, Checkbox } from '@mui/material';
import Cookies from 'universal-cookie';

// Api
import fetchData from '../api/fetchData';

// Styles
const classes = {
    vsum_value:
    {
        fontSize: 13, flex: 1, color: "grey.300"
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99999,
        backgroundColor: "rgba(0,0,0,0.7)"
    },
    label: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400,
    },
    value: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400
    },
    container1: {
        flex: 1,
    },
    checkbox: {
        width: 200,
        mr: 4
    },
    full_width: {
        my: 2, mr: 1
    },
}

export function Trip_Assign(props) {
    const dialog_title = props.dialog_title
    const items = props.item;
    const cookies = new Cookies();
    const [veh, setVeh] = useState([])
    const [veh_val, setVeh_Val] = useState(items?.vehicle_fk ? items?.vehicle_fk : 0)
    const [trip_type, setTrip_Type] = useState([])
    const [trip_val, setTrip_val] = useState(items?.type_fk ? items?.type_fk : 0)
    const [idlet, setIdlet] = useState(items?.idlet ? items?.idlet : "")
    const { enqueueSnackbar } = useSnackbar();
    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }

    useEffect(() => {
        // get_v_map_data(fence_val?.gfence_pk)
        get_vehicle()
        get_triptype()

    }, []);

    // const get_v_map_data = (fence_id) => {
    //     if (fence_id) {
    //         fetchData.get_rfence_vmap({ "fence_id": fence_id }).then((res) => {
    //             var { rfence_vmap } = res;
    //             setRfence_vmap(rfence_vmap)
    //         }).catch((error) => {
    //             console.log(error)
    //         })
    //     }
    // }

    const get_vehicle = () => {
        fetchData.vehicle_trip_dd({ "ac_fk": props.item?.ac_fk, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }).then((res) => {
            var { vehicle } = res;
            vehicle.unshift({ "label": "SELECT", "value": 0 });
            setVeh(vehicle)
        }).catch((error) => {
            console.log(error)
        })
    }

    const get_triptype = () => {
        fetchData.trip_type({ "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }).then((res) => {
            var { trip_type } = res;
            trip_type.unshift({ "label": "SELECT", "value": 0 });
            setTrip_Type(trip_type)
        }).catch((error) => {
            console.log(error)
        })
    }

    const route_fence_veh_put = () => {
        if (!veh_val) {
            alert_trigger("error", "Please select vehicle")
            return;
        }
        if (!idlet) {
            alert_trigger("error", "Please enter valid idle time limit")
            return;
        }
        // if (rfence_vmap?.sms_alert) {
        //     if (rfence_vmap?.mobile_no?.length == 0) {
        //         alert_trigger("error", "Please fill mobile number")
        //         return;
        //     }
        // }

        // rfence_vmap["rfence_fk"] = fence_val?.gfence_pk
        // if (!rfence_vmap?.sms_alert) {
        //     rfence_vmap["sms_alert"] = 0
        // }
        // if (!rfence_vmap?.mobile_no) {
        //     rfence_vmap["mobile_no"] = null
        // }
        var data = { "vmap_id": 0, "vehicle_fk": veh_val, "rfence_id": props.datas?.id, "idlet": idlet, "sms_alert": true, "mobile_no": 636436, "trip_type": trip_val }
        fetchData.put_rfence_vmap(data).then((res) => {
            var { rval, msg } = res;
            if (rval > 0) {
                props.refresh()
                alert_trigger("success", msg)
                props.onClose()
            }
            else {
                alert_trigger("error", msg)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const route_fence_veh_edit = () => {
        // if (!rfence_vmap?.vehicle_fk) {
        //     alert_trigger("error", "Please select vehicle")
        //     return;
        // }
        // if (!rfence_vmap?.idlet) {
        //     alert_trigger("error", "Please enter valid idle time limit")
        //     return;
        // }
        // if (rfence_vmap?.sms_alert) {
        //     if (rfence_vmap?.mobile_no?.length == 0) {
        //         alert_trigger("error", "Please fill mobile number")
        //         return;
        //     }
        // }
        // if (rfence_vmap) {
        //     rfence_vmap["vmap_id"] = items.vmap_id
        //     rfence_vmap["rfence_id"] = props.item?.id
        // }

        // rfence_vmap["rfence_fk"] = fence_val?.gfence_pk
        // if (!rfence_vmap?.sms_alert) {
        //     rfence_vmap["sms_alert"] = 0
        // }
        // if (!rfence_vmap?.mobile_no) {
        //     rfence_vmap["mobile_no"] = null
        // }
        if (!veh_val) {
            alert_trigger("error", "Please select vehicle")
            return;
        }
        if (!idlet) {
            alert_trigger("error", "Please enter valid idle time limit")
            return;
        }
        var data = { "vmap_id": items?.vmap_id, "vehicle_fk": veh_val, "rfence_id": props.datas?.id, "idlet": idlet, "sms_alert": true, "mobile_no": 636436, "trip_type": trip_val }
        fetchData.put_rfence_vmap(data).then((res) => {

            var { rval, msg } = res;
            if (rval > 0) {
                props.refresh()
                alert_trigger("success", msg)
                props.onClose()
            }
            else {
                alert_trigger("error", msg)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <Dialog
            open={true}
            onClose={props.onClose}
        >
            <Box sx={{ width: 450 }}>
                <DialogTitle sx={{ fontSize: 15 }}>{dialog_title}</DialogTitle>
                <DialogContent >
                    <Box sx={classes.container1}>
                        <Box sx={{ display: "flex", flex: 1, width: 1, mt: 3 }}>
                            <FormControl size='small' sx={{ flex: 1, mr: 1 }}>
                                <InputLabel >Trip Type</InputLabel>
                                <Select
                                    size='small'
                                    label="Trip Type"
                                    value={trip_val}
                                    onChange={(e) => {
                                        setTrip_val(e.target.value)
                                    }}
                                >
                                    {
                                        trip_type?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ display: "flex", flex: 1, width: 1, mt: 3 }}>
                            <FormControl size='small' sx={{ flex: 1, mr: 1 }}>
                                <InputLabel >Vehicle</InputLabel>
                                <Select
                                    size='small'
                                    label="Vehicle"
                                    value={veh_val}
                                    onChange={(e) => {
                                        setVeh_Val(e.target.value)
                                    }}
                                >
                                    {
                                        veh?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl size='small' sx={{ flex: 1, mx: 1 }}>
                                <TextField
                                    value={idlet}
                                    size='small'
                                    InputLabelProps={{ shrink: true }}
                                    label="Idle Time Limit (mins)"
                                    onChange={(e) => {
                                        setIdlet(e.target.value)
                                    }}
                                    variant="outlined" />
                            </FormControl>
                        </Box>
                        <Box sx={{ display: "flex", flex: 1, width: 1, mt: 2 }}>
                            {/* <FormControlLabel control={<Checkbox checked={rfence_vmap?.sms_alert ? true : false} />}
                                label="Allow Mobile Alerts"
                                sx={classes.checkbox}
                                onChange={(e) => { setRfence_vmap({ ...rfence_vmap, sms_alert: e.target.checked }); }}
                            /> */}
                            <FormControlLabel
                                control={<Checkbox checked={true} />}
                                label="Allow Web Alerts"
                                sx={classes.checkbox}
                            />
                        </Box>
                        {/* <Box sx={{ display: "flex" }}>
                            {rfence_vmap?.sms_alert ?
                                <FormControl size='small' sx={{ flex: 1, mr: 1, mt: 2 }}>
                                    <TextField
                                        value={rfence_vmap?.mobile_no}
                                        size='small'
                                        type="number"
                                        InputLabelProps={{ shrink: true }}
                                        label="Mobile Number"
                                        onChange={(e) => { setRfence_vmap({ ...rfence_vmap, mobile_no: e.target.value }); }}
                                        variant="outlined" />
                                </FormControl> : null
                            }
                        </Box> */}
                        <Box sx={{ display: "flex" }}>
                            <Button variant="contained" sx={classes.full_width} onClick={() => { props.submit === "Submit" ? route_fence_veh_put() : route_fence_veh_edit() }} fullWidth>{props.submit}</Button>
                            <Button variant="contained" sx={classes.full_width} onClick={() => { props.onClose() }} fullWidth>Close</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Box>
        </Dialog>
    )
}