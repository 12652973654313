import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Box, IconButton } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { ThreeDots } from 'react-loader-spinner'
import Cookies from 'universal-cookie';
import { Navigate, useNavigate } from "react-router-dom";


// components
import { Filters } from './component/filters';
import Reportmap from './report_map';
// colors
import colors from '../config/colors';

// Api
import fetchData from '../api/fetchData';

//icons
import MyLocationIcon from '@mui/icons-material/MyLocation';


const classes = {
    container: {
        position: "absolute",
        top: 10,
        left: 0,
        right: 0,
        bottom: 0,
        '& .super-app-theme--header': {
            backgroundColor: colors.dark1,
        },
    },
    data_table: {
        position: "absolute",
        top: 80,
        left: 10,
        right: 10,
        bottom: 10,
    },
    loader: {
        position: "absolute",
        top: 80,
        left: 10,
        right: 10,
        bottom: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}

var vehicle_info1 = {}

export default function OverSpeedReport() {
    const [vehicle_details, setVehicle_details] = useState([]);
    const [loader, setLoader] = useState(false)
    const [paginationModel, setPaginationModel] = useState({ pageSize: 50, page: 0 })
    const [rowCountState, setRowCountState] = useState(0)
    const [datas, setDatas] = useState([])
    const [open, setOpen] = React.useState(false);
    const [active_item, setActive_item] = React.useState([]);
    const [active_coords, setActive_coords] = React.useState({});
    const [column, setColumn] = React.useState([]);
    const navigate = useNavigate();


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const { enqueueSnackbar } = useSnackbar();

    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }

    useEffect(() => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        } else {
            set_table_col(vehicle_info1)
        }
    }, []);


    const set_table_col = (vehicle_info) => {
        const column = [
            { field: 'district', headerName: 'District', flex:1, headerClassName: 'super-app-theme--header', renderCell: (params) => vehicle_info?.dname, valueFormatter: (value) => vehicle_info?.dname },
            { field: 'ac_name', headerName: 'Assembly', flex:1, headerClassName: 'super-app-theme--header', renderCell: (params) => vehicle_info?.ac_name, valueFormatter: (value) => vehicle_info?.ac_name },
            { field: 'category', headerName: 'Category', flex:1, headerClassName: 'super-app-theme--header', renderCell: (params) => vehicle_info?.category, valueFormatter: (value) => vehicle_info?.category },
            { field: 'no', headerName: 'Vehicle No', flex:1, headerClassName: 'super-app-theme--header', renderCell: (params) => vehicle_info?.v_no, valueFormatter: (value) => vehicle_info?.v_no },
            { field: 'name', headerName: 'Vehicle Type', flex:1, headerClassName: 'super-app-theme--header', renderCell: (params) => vehicle_info?.name, valueFormatter: (value) => vehicle_info?.name },
            { field: 'dt', headerName: 'Date Time', flex:1.5, headerClassName: 'super-app-theme--header' },
            { field: 'speed', headerName: 'Speed(km/h)', flex:1, headerClassName: 'super-app-theme--header' },
            { field: 'addr', headerName: 'Location', flex:2, headerClassName: 'super-app-theme--header', },
            {
                headerName: '',
                disableExport: true,
                width: 100, headerClassName: 'super-app-theme--header',
                renderCell: (params) =>
                    <>
                        <IconButton onClick={(e) => { open_map(params.row) }}><MyLocationIcon color="primary" />
                        </IconButton >
                    </>
            },
        ]
        setColumn(column)
    }


    const apply = (data) => {
        fetchData.vehicle_info({ "vehicle_fk": data.vehicle_fk }).then((res) => {
            var { vehicle_info } = res;
            vehicle_info1 = vehicle_info
            set_table_col(vehicle_info1)

        }).catch((error) => {
            console.log(error)
            setLoader(false)
        })
        setDatas(data)
        if (!data.vehicle_fk) {
            alert_trigger("error", "Please select vehicle to view report")
            return
        }
        if (!data.speed) {
            alert_trigger("error", "Please enter speed limit to view report")
            return
        }
        else if (!data.from) {
            alert_trigger("error", "Please select date range to view report")
            return
        }
        else if (!data.to) {
            alert_trigger("error", "Please select date range to view report")
            return
        }
        setLoader(true)
        data["pageSize"] = data.pageSize ? data.pageSize : paginationModel.pageSize
        data["page"] = data.page ? data.page : paginationModel.page
        fetchData.overspeed_report(data).then((res) => {
            var { overspeed_lst, total_count } = res;
            setRowCountState(total_count)
            setVehicle_details(overspeed_lst)
            setLoader(false)
            get_addr(overspeed_lst)

        }).catch((error) => {
            console.log(error)
            setLoader(false)
        })

    }

    const get_addr = (vehicle_list) => {
        var cords = []
        vehicle_list?.map((item) => {
            cords.push({ "lat": parseFloat(item.lat), "lon": parseFloat(item.lon), "id": item.id })
        })
        fetchData.v_addr({ "latlon": cords }).then((res) => {
            var addr = res.addr
            var v_new_list = []
            vehicle_list?.map((item) => {
                var it = item
                var act = addr.find(({ id }) => id == item.id);

                it["addr"] = act.formattedAddress
                v_new_list.push(item)
            })
            setVehicle_details(v_new_list)


        }).catch((error) => {
            console.log(error)
        })
    }

    const changePagination = (e) => {
        console.log(e)
        setPaginationModel(e);
        var data1 = datas
        data1["pageSize"] = String(e.pageSize)
        data1["page"] = String(e.page)
        apply(data1)
    }

    const open_map = (e) => {

        setActive_item([{ "label": "Vehicle No", "value": vehicle_info1.v_no }, { "label": "Vehicle Type", "value": vehicle_info1.name }, { "label": "Speed", "value": e.speed }, { "label": "Speed", "value": e.addr }, { "label": "Date Time", "value": e.dt }]);
        setActive_coords({ "lat": e.lat, "lng": e.lon })
        setOpen(true)
    }




    return (
        <Box sx={classes.container}>
            <Filters page={"Report"} apply={apply} options={"District,Assembly,Category,Vehicle,Speed,From,To,Apply"}></Filters>
            {loader ? <Box sx={classes.loader}>
                <ThreeDots
                    height="100"
                    width="100"
                    radius="7"
                    color="#0288d1"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                /></Box>
                : <Box sx={classes.data_table}>
                    <DataGrid
                        disableColumnSelector
                        disableDensitySelector
                        disableSelectionOnClick
                        disableRowSelectionOnClick 
                        pageSizeOptions={[10, 20, 50]}
                        initialState={{
                            pagination: { paginationModel: { pageSize: paginationModel.pageSize } },
                        }}
                        paginationModel={paginationModel}
                        // showCellVerticalBorder
                        // showColumnVerticalBorder
                        // checkboxSelection={props.checkboxSelection}
                        // onSelectionModelChange={(itm) => {props.selected_rows(itm)}}
                        // components={ props.export } 
                        rowCount={rowCountState}
                        paginationMode="server"
                        onPaginationModelChange={(e) => { changePagination(e) }}
                        rows={vehicle_details}
                        columns={column}
                        slots={{
                            toolbar: CustomToolbar,
                        }}
                        slotProps={{ toolbar: { csvOptions: { allColumns: true } } }}
                    />
                </Box>
            }
            {open &&

                <Reportmap handleClose={handleClose} item={active_item} coords={active_coords} />
            }
        </Box>
    )
}

function CustomToolbar() {
    return (
        <GridToolbarContainer sx={{ backgroundColor: colors.dark1 }}>
            <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </GridToolbarContainer>
    );
}