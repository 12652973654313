import * as React from 'react';
import { Button, Box, Typography, MenuItem, Menu, Divider, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import ReactGA from "react-ga4";
import encryptStorage from "../../encryptStorage";
import l1 from "../../assets/l1white.png";
import l2 from "../../assets/l2white.png";
import l3 from "../../assets/l3white.png";
import { UserContext } from '../../App';

// Icons
import AccountCircle from '@mui/icons-material/AccountCircle';

// Component
import Alert_settings from '../alert_settings';

//Colors
import colors from '../../config/colors'
// Api
import fetchData from '../../api/fetchData';
import { Live_alert } from './live_alert';

//Styles
const classes = {
    header_container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 60,
        backgroundColor: colors.dark1,
    },
    icon: {
        color: "white"
    },
    drop_down: {
        zIndex: 10000,
        top: 20
    }

}

const menu_style = {
    overflow: 'visible',
    filter: 'drop-shadow(2px 2px 2px rgba(179,179,179,0.45))',
    mt: 1.5,
    '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
    },
    '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: 'background.paper',
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
    },
}

var hb_interval;
var NFW;

export function Header(props) {
    const cookies = new Cookies();
    const [is_admin, setIs_admin] = useState(cookies.get("is_admin"))
    const [anchor, setAnchor] = React.useState(null);
    const [popup, setPopup] = React.useState(false);
    const [alert_settings, setAlert_settings] = React.useState(false);
    const { alert_open, setAlert_open } = React.useContext(UserContext);
    const { alert_val, setAlert_val } = React.useContext(UserContext);
    const navigate = useNavigate();

    const appSetting = encryptStorage.getItem("appSetting");
    const GAtoken = appSetting?.ga[process.env.NODE_ENV] ?? "G-9TCKS8XLMR";
    ReactGA.initialize(GAtoken);

    useEffect(() => {

        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
            window.location.reload(true);
        }

        fn_heart_beart_start();
        console.log(process.env.NODE_ENV)
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, userId: cookies.get("user_id"), name: cookies.get("uname") });
        return () => clearInterval(hb_interval);
    }, []);


    const fn_heart_beart_start = () => {
        fn_heartbeat()
        hb_interval = setInterval(function () {
            fn_heartbeat()
        }, 30000);
    }
    const logout = () => {
        setAlert_open(false)
        setAlert_val([])
        const cookies = new Cookies();
        cookies.remove('user_id')
        cookies.remove('uname')
        cookies.remove('priv')
        cookies.remove('priv_id')
        cookies.remove('is_admin')

        encryptStorage.removeItem("appSetting");
        encryptStorage.removeItem("versionInfo");
        navigate("/");
        window.location.reload(true);
    }

    const fn_heartbeat = () => {

        const cookies = new Cookies();
        var uid = cookies.get("user_id");
        fetchData.ac_heartbeat({ "user_id": uid }).then((res) => {

            if (encryptStorage.getItem("versionInfo") < res.version) {
                load_config()
            }

            if (res.status == 0) {
                logout();
                if (NFW != undefined) {
                    NFW.close();
                }
            }
        }).catch((error) => {
            console.log(error)
        })

    }

    const load_config = () => {
        var url = window.location.origin
        var result = url.replace(/(^\w+:|^)\/\//, '');
        console.log(result)
        var data = { "path": result }
        fetchData.app_config(data).then((result) => {
            if (result) {
                encryptStorage.setItem("versionInfo", result.version);
                encryptStorage.setItem("appSetting", result.conf);
                window.location.reload(true);
            }
        }).catch((err) => {
            console.log("error-", err);
        });
    }

    const handleMenu = (event) => {
        setAnchor(event.currentTarget);
    };

    const handleClose = () => {
        setAnchor(null);
    };

    function openPage(openPage) {
        var windowW = window.screen.availWidth;
        var windowH = window.screen.availHeight;
        var ews = "width=" + windowW + ",height=" + (windowH - 10);
        var style = "toolbar=no,status=yes,location=no,border=1,menubar=no,scrollbars=yes,resizable=no,maximized=yes,managepassword=no" + ews;
        var urlPop = openPage;
        NFW = window.open(urlPop, "popFrameless", style);
        NFW.moveTo(-3, -3);
        NFW.resizeTo(NFW.screen.availWidth + 18, NFW.screen.availHeight + 8);
        if (NFW != null) {
            window.opener = window;
        } else {
            document.write('<BR><BR><br><br><br><TABLE  align="center" border="0" width=100%><tr align="center"><td class="userInfo" align="center"><img src="/img/warningIcon.gif"></img>&nbsp;&nbsp;&nbsp;&nbsp;<B>Please enable Pop-up in your Browser.</B>&nbsp;</td></tr><tr align="center"><td>&nbsp;&nbsp;&nbsp;&nbsp;<B>Click on Tools->Pop-up Blocker->Turn off Pop-up Blocker.</B>&nbsp;</td></tr><tr align="center"><td>&nbsp;&nbsp;&nbsp;&nbsp&nbsp;</td></tr></TABLE>');
        }
        NFW.focus();
    }

    return (
        <Box sx={classes.header_container}>
            {popup &&
                <Confirmation_popup open={true} onClose={() => { setPopup(false) }} />
            }
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginLeft: "10px" }}>
                {/* <img src={l1} alt="ECI" height={"45px"}></img>
                <img src={l2} alt="ECI" height={"45px"}></img>
                <img src={l3} alt="ECI" height={"45px"}></img> */}
            </Box>
            <Typography variant="subtitle1" sx={{ marginLeft: "-175px" }}>{appSetting.title}
            </Typography>
            <Button endIcon={<AccountCircle />} sx={classes.icon} onClick={handleMenu} >{cookies.get("uname")}</Button>
            <Menu 
                PaperProps={{
                    elevation: 0,
                    sx: menu_style
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchor)}
                onClose={handleClose}
                sx={classes.drop_down}
            >
                {!is_admin && appSetting.report.map((page) => {
                    return (<><MenuItem onClick={() => {
                        handleClose();
                        appSetting.feature[page].link && openPage(appSetting.feature[page].link);
                        appSetting.feature[page].popup && setAlert_settings(true);
                    }} >
                        {appSetting.feature[page].label} </MenuItem>
                        <Divider /> </>)

                })
                }

                <MenuItem onClick={() => { handleClose(); setPopup(true) }}>Logout </MenuItem>

            </Menu>
            {alert_settings &&
                <Alert_settings open={true} onClose={() => { setAlert_settings(false) }} />
            }
            <Live_alert/>
            
        </Box>
    )
}


export function Confirmation_popup(props) {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { alert_open, setAlert_open } = React.useContext(UserContext);
    const { alert_val, setAlert_val } = React.useContext(UserContext);

    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }

    const logout = () => {
        setAlert_open(false)
        setAlert_val([])
        const cookies = new Cookies();
        cookies.remove('user_id')
        cookies.remove('uname')
        cookies.remove('priv')
        cookies.remove('priv_id')
        cookies.remove('is_admin')
        encryptStorage.removeItem("appSetting");
        encryptStorage.removeItem("versionInfo");
        navigate("/");
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
        >
            <Box sx={{ width: 450 }}>
                <DialogTitle sx={{ fontSize: 15 }}>Logout</DialogTitle>

                <DialogContent >
                    <Typography variant="subtitle2" sx={{ mt: 2 }} >Are you sure you want to logout?</Typography>
                </DialogContent>
                <DialogActions sx={{ pr: 3, pb: 2 }}>
                    <Button onClick={props.onClose}>Cancel</Button>
                    <Button onClick={() => { logout() }} autoFocus>
                        Logout
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
