import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Divider, Box, InputBase, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { DataGrid } from '@mui/x-data-grid';
import { ThreeDots } from 'react-loader-spinner'
import Cookies from 'universal-cookie';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
// components
import { Header } from './component/header';
import { Filters } from './component/filters';
import SingleMapScreen from './trail'
// colors
import colors from '../config/colors';

//icons
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

// Api
import fetchData from '../api/fetchData';

const classes = {
    container: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    data_table: {
        position: "absolute",
        top: 180,
        left: 10,
        right: 10,
        bottom: 10,
        '& .super-app-theme--header': {
            backgroundColor: colors.dark1,
        },
    },
    loader: {
        position: "absolute",
        top: 130,
        left: 10,
        right: 10,
        bottom: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    export_btn: {
        // position: "absolute",
        // right: 250,
        // top: 130,
        backgroundColor: colors.dark1,
        // width: 130,
        height: 40,
        color: "white",
        fontSize: 14,
        borderRadius: 1,
        px: 3
    },
}


export default function ListScreen(props) {
    const [vehicle_details, setVehicle_details] = useState([]);
    const [vehicle_details_edit, setVehicle_details_edit] = useState([]);
    const [search_val, setSearch_val] = useState("");
    const [dom_search_val, setDom_search_val] = useState("");
    const [loader, setLoader] = useState(true)
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [paginationModel, setPaginationModel] = useState({ pageSize: 100, page: 0 })
    const [rowCountState, setRowCountState] = useState(0)
    const [datas, setDatas] = useState([])
    var [loading_dwn, setLoading_dwn] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }

    useEffect(() => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        } else {
            var data = { "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }
            // apply(data)
        }
    }, []);

    const apply = (data) => {
        setDatas(data)
        setLoader(true)
        data["pageSize"] = data.pageSize ? data.pageSize : paginationModel.pageSize
        data["page"] = data.page ? data.page : paginationModel.page
        data["page_name"] = "List"
        fetchData.vehicle_list_view(data).then((res) => {
            var { vehicle_list, total_count } = res;
            setRowCountState(total_count)
            setVehicle_details(vehicle_list)
            setLoader(false)
            // get_addr(vehicle_list)

        }).catch((error) => {
            console.log(error)
        })
    }

    const get_addr = (vehicle_list) => {
        var cords = []
        vehicle_list?.map((item) => {
            cords.push({ "lat": parseFloat(item.lat), "lon": parseFloat(item.lon), "id": item.vsumm_pk_id })
        })
        fetchData.v_addr({ "latlon": cords }).then((res) => {
            var addr = res.addr
            var v_new_list = []
            vehicle_list?.map((item) => {
                var it = item
                var act = addr.find(({ id }) => id == item.vsumm_pk_id);

                it["addr"] = act.formattedAddress
                v_new_list.push(item)
            })
            console.log(v_new_list)
            setVehicle_details(v_new_list)


        }).catch((error) => {
            console.log(error)
        })
    }

    const changePagination = (e) => {
        console.log(e)
        setPaginationModel(e);
        var data1 = datas
        data1["pageSize"] = String(e.pageSize)
        data1["page"] = String(e.page)
        apply(data1)

    }

    const export_vehicle_list = () => {
        setLoading_dwn(true)
        datas["search_val"] = dom_search_val
        fetchData.export_vehicle_list(datas).then((response) => {
            const href = window.URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'Vehicle List.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setLoading_dwn(false)
        }).catch((error) => {
            console.log(error)
        })

    }

    const search_vehicle = (action_type) => {

        var data = datas
        if (action_type == 2) {
            if (!search_val) {
                alert_trigger("info", "Please enter Vehicle No or IMEI to search vehicle")
                return
            }

            setDom_search_val(search_val)
            data["search_val"] = search_val
        } else {
            setDom_search_val("")
            data["search_val"] = ""
        }
        if (search_val) {
            apply(data)
        }
    }

    const column = [
        { field: 'dname', headerName: 'District', minWidth: 159, headerClassName: 'super-app-theme--header' },
        { field: 'ac_name', headerName: 'Assembly', minWidth: 150, headerClassName: 'super-app-theme--header' },
        { field: 'an_no', headerName: 'AC NO', minWidth: 100, headerClassName: 'super-app-theme--header' },
        { field: 'category', headerName: 'Category', minWidth: 100, headerClassName: 'super-app-theme--header', },
        { field: 'v_no', headerName: 'Vehicle No', minWidth: 120, headerClassName: 'super-app-theme--header' },
        {
            headerName: 'Map',
            width: 100, headerClassName: 'super-app-theme--header',
            renderCell: (params) =>
                <>
                    <IconButton onClick={(e) => { setVehicle_details_edit(params.row); setOpen(true) }}><MyLocationIcon color="primary" />
                    </IconButton >
                </>
        },
        { field: 'v_stat', headerName: 'Vehicle Status', minWidth: 100, headerClassName: 'super-app-theme--header' },
        { field: 'speed', headerName: 'Speed', minWidth: 100, headerClassName: 'super-app-theme--header' },
        { field: 'dt', headerName: 'Last Updated', minWidth: 170, headerClassName: 'super-app-theme--header' },
        { field: 'rssi', headerName: 'Signal Strength', minWidth: 120, headerClassName: 'super-app-theme--header' },
        { field: 'main_batery', headerName: 'Battery', minWidth: 120, headerClassName: 'super-app-theme--header' },
        { field: 'battery', headerName: 'Internal Battery Voltage', minWidth: 160, headerClassName: 'super-app-theme--header' },
        { field: 'power', headerName: 'External Battery Voltage', minWidth: 160, headerClassName: 'super-app-theme--header' },
        { field: 'run_time', headerName: 'Running(Mins)', minWidth: 120, headerClassName: 'super-app-theme--header' },
        { field: 'idle_time', headerName: 'Idle Time(Mins)', minWidth: 120, headerClassName: 'super-app-theme--header' },
        { field: 'i_dt', headerName: 'Installation Date', minWidth: 170, headerClassName: 'super-app-theme--header' },
        { field: 'imei', headerName: 'IMEI', minWidth: 150, headerClassName: 'super-app-theme--header' },
        { field: 'ign_status', headerName: 'Ignition', minWidth: 100, headerClassName: 'super-app-theme--header' },
        { field: 'name', headerName: 'Vehicle Type', minWidth: 100, headerClassName: 'super-app-theme--header' },
        { field: 'driver_name', headerName: 'Driver Name', minWidth: 150, headerClassName: 'super-app-theme--header' },
        { field: 'driver_no', headerName: 'Driver No 1', minWidth: 120, headerClassName: 'super-app-theme--header' },
        { field: 'driver_no2', headerName: 'Driver No 2', minWidth: 120, headerClassName: 'super-app-theme--header' },
        { field: 'incharge_name', headerName: 'SO Name', minWidth: 120, headerClassName: 'super-app-theme--header' },
        { field: 'incharge_no', headerName: 'SO No', minWidth: 120, headerClassName: 'super-app-theme--header' },
        { field: 'route_no', headerName: 'Route No', minWidth: 120, headerClassName: 'super-app-theme--header' },
    ]

    return (
        <Box sx={classes.container}>
            <Header ></Header>
            <Filters page={location.state} apply={apply} options={"District,Assembly,Category,Status,Nav"}></Filters>
            <Box sx={{ position: "absolute", top: 130, height: 50, px: 2, left: 0, right: 0, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                {
                    vehicle_details.length > 0 ?
                        loading_dwn == true ?
                            <LoadingButton
                                loading
                                loadingPosition="center"
                                sx={classes.export_btn}
                                size="small"
                            >Loading...
                            </LoadingButton> :
                            <Button size="small" startIcon={<SaveAltIcon />} sx={classes.export_btn} onClick={() => { export_vehicle_list() }}>Export</Button> : null
                }
                <Box
                    component="form"
                    sx={{ display: 'flex', alignItems: 'center', backgroundColor: colors.dark1, borderRadius: 1, ml: 1, height: 40 }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Vehicle No / IMEI"
                        value={search_val}
                        onChange={(e) => { setSearch_val(e.target.value) }}
                    />
                    <Divider orientation="vertical" />
                    <IconButton sx={{ borderRadius: 0 }}>
                        <CloseIcon onClick={() => { setSearch_val(""); search_vehicle(1); }} />
                    </IconButton>
                    <Divider orientation="vertical" />
                    <IconButton sx={{ borderRadius: 0 }}>
                        <SearchIcon onClick={() => { search_vehicle(2) }} />
                    </IconButton>
                </Box>

            </Box>

            {loader ? <Box sx={classes.loader}>
                <ThreeDots
                    height="100"
                    width="100"
                    radius="7"
                    color="#0288d1"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                /></Box>
                : <Box sx={classes.data_table}>
                    <DataGrid
                        disableColumnSelector
                        disableDensitySelector
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                        pageSizeOptions={[10, 20, 50, 100]}
                        initialState={{
                            pagination: { paginationModel: { pageSize: paginationModel.pageSize } },
                        }}
                        paginationModel={paginationModel}
                        // showCellVerticalBorder
                        // showColumnVerticalBorder
                        // checkboxSelection={props.checkboxSelection}
                        // onSelectionModelChange={(itm) => {props.selected_rows(itm)}}
                        // components={ props.export } 
                        rowCount={rowCountState}
                        paginationMode="server"
                        onPaginationModelChange={(e) => { changePagination(e) }}
                        rows={vehicle_details}
                        columns={column} />

                </Box>
            }
            {open &&

                <SingleMapScreen position={{
                    position: "absolute",
                    zIndex: 9999,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    flexDirection: "row"
                }} handleClose={handleClose} vehicle_fk={vehicle_details_edit.vehicle_fk} />

            }
        </Box>
    )
}