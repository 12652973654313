import { api } from "./api";

// const api_name = "fuel_rig"
const  api_name = "GPS"


export default {
  login: (data) => {
    let url = api_name + "/login";
    return api.postMethod(url, data);
  },
  app_config: (data) => {
    let url = api_name + "/app_config?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  district: (data) => {
    let url = api_name + "/district?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  alert_types: (data) => {
    let url = api_name + "/alert_types?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  ac: (data) => {
    let url = api_name + "/assembly?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  category: (data) => {
    let url = api_name + "/category?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  v_status: (data) => {
    let url = api_name + "/v_status?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  vehicle: (data) => {
    let url = api_name + "/vehicle?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },

  search_vehicle: (data) => {
    let url = api_name + "/search_vehicle?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  vehicle_list: (data) => {
    let url = api_name + "/vehicle_list?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  vehicle_list_view: (data) => {
    let url = api_name + "/vehicle_list_view?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  vehicle_list_min: (data) => {
    let url = api_name + "/vehicle_list_min?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  vehicle_list_multi: (data) => {
    let url = api_name + "/vehicle_list_multi?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  v_addr: (data) => {
    let url = api_name + "/addr";
    return api.postMethod(url,data);
  },
  route_play: (data) => {
    let url = api_name + "/route_play?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  route_play_parking: (data) => {
    let url = api_name + "/route_play_parking?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  v_sum: (data) => {
    let url = api_name + "/v_sum?data=" + encodeURIComponent(JSON.stringify(data));
    return api.postMethod(url);
  },
  engine_report: (data) => {
    let url = api_name + "/engine_report?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  parking_report: (data) => {
    let url = api_name + "/parking_report?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  overspeed_report: (data) => {
    let url = api_name + "/overspeed_report?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  get_fence: (data) => {
    let url = api_name + "/get_fence?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  put_fence: (data) => {
    let url = api_name + "/put_fence?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  delete_fence: (data) => {
    let url = api_name + "/delete_fence?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  put_fence_alert: (data) => {
    let url = api_name + "/put_fence_alert?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  get_fence_alert: (data) => {
    let url = api_name + "/get_fence_alert?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  vehicle_info: (data) => {
    let url = api_name + "/vehicle_info?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  idle_report: (data) => {
    let url = api_name + "/idle_report?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  speed_report_top: (data) => {
    let url = api_name + "/speed_report_top?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  dashboard: (data) => {
    let url = api_name + "/dashboard?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  alerts: (data) => {
    let url = api_name + "/alerts?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  alerts_settings_get: (data) => {
    let url = api_name + "/alerts_settings_get?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  alerts_settings_put: (data) => {
    let url = api_name + "/alerts_settings_put?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  notification_alerts: (data) => {
    let url = api_name + "/notification_alerts?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  put_route_fence: (data) => {
    let url = api_name + "/put_route_fence?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  device_log: (data) => {
    let url = api_name + "/device_log?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  get_fence_route: (data) => {
    let url = api_name + "/get_fence_route?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  view_fence_route: (data) => {
    let url = api_name + "/view_fence_route?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  route_fence_veh_put: (data) => {
    let url = api_name + "/route_fence_veh_put?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  export_vehicle_list: (data) => {
    let url = api_name + "/export_vehicle_list?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getFile(url);
  },
  route_play_kml: (data) => {
    let url = api_name + "/route_play_kml?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getFile(url);
  },
  vehicle_list_mapping: (data) => {
    let url = api_name + "/vehicle_list_mapping?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  delete_device: (data) => {
    let url = api_name + "/delete_device?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  add_device: (data) => {
    let url = api_name + "/add_device?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  edit_device: (data) => {
    let url = api_name + "/edit_device?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  summary_report: (data) => {
    let url = api_name + "/summary_report?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  export_summary_report: (data) => {
    let url = api_name + "/export_summary_report?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getFile(url);
  },
  ac_heartbeat: (data) => {
    let url = api_name + "/ac_heartbeat?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  get_trip_points: (data) => {
    let url = api_name + "/get_trip_points?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  put_trip_points: (data) => {
    let url = api_name + "/put_trip_points";
    return api.postMethod(url,data);
  },
  get_trips: (data) => {
    let url = api_name + "/get_trips?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  get_rfence_vmap: (data) => {
    let url = api_name + "/get_rfence_vmap?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  put_rfence_vmap: (data) => {
    let url = api_name + "/put_rfence_vmap";
    return api.postMethod(url,data);
  },
  alerts_det_get: (data) => {
    let url = api_name + "/alert_management/alerts_det_get?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  alerts_det_delete: (data) => {
    let url = api_name + "/alert_management/alerts_det_delete";
    return api.postMethod(url,data);
  },
  alerts_det_update: (data) => {
    let url = api_name + "/alert_management/alerts_det_update";
    return api.postMethod(url,data);
  },
  
  trip_type: (data) => {
    let url = api_name + "/trip_type?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  delete_rfence_vmap: (data) => {
    let url = api_name + "/delete_rfence_vmap";
    return api.postMethod(url,data);
  },
  vehicle_trip_dd: (data) => {
    let url = api_name + "/vehicle_trip_dd?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  bounding_shape: (data) => {
    let url = api_name + "/bounding_shape?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  preSignedUrl: (data) => {
    let url = data;
    return api.getMethodurl(url);
  },
  ps_fence_data: (data) => {
    let url = api_name + "/ps_fence_data?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  view_all_fence_route: (data) => {
    let url = api_name + "/view_all_fence_route?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  view_veh_fence_route: (data) => {
    let url = api_name + "/view_veh_fence_route?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  },
  cc_reach_info: (data) => {
    let url = api_name + "/cc_reach_info?data=" + encodeURIComponent(JSON.stringify(data));
    return api.getMethod(url);
  }
};
