import React from 'react';
import { useState, useEffect } from "react";
import { Button, Box, IconButton } from '@mui/material';
import { Map, GoogleApiWrapper as SingleMapScreen, Marker, InfoWindow, Polyline } from 'google-maps-react';
import CloseIcon from '@mui/icons-material/Close';
import { running_img, idle_img, stop_img } from '../screens/image'

import encryptStorage from "../encryptStorage";



// colors
import colors from '../config/colors';

// map API Key
import mapkey from '../config/map_key';

// Api
import fetchData from '../api/fetchData';


//Icons
import reddot from '../assets/reddot.png'
import greendot from '../assets/green_dot1.png'
import bluedot from '../assets/blue_dot.png'
import g_icon from '../assets/cc_icons/g_icon.png'
import r_icon from '../assets/cc_icons/r_icon.png'
import df_icon from '../assets/cc_icons/df_icon.png'

// Styles
const classes = {

    closebtn: {
        position: "absolute",
        top: 0,
        alignSelf: "center",
        zIndex: 999,
        backgroundColor: "white",
        left: '49%',
        boxShadow: 2,
        color: "grey"
    },
    map_cls_btn: {
        position: "absolute",
        height: 40,
        top: 11, right: 68,
        zIndex: 999,
        backgroundColor: colors.red,
        color: "white",
        width: 100,
        boxShadow: 1,
        '&:hover': {
            backgroundColor: colors.red,
            color: "white",
        },
    }
}


var RotateIcon = function (options) {
    this.options = options || {};
    this.rImg = options.img || new Image();
    this.rImg.src = this.rImg.src || this.options.url || '';
    this.options.width = this.options.width || this.rImg.width || 52;
    this.options.height = this.options.height || this.rImg.height || 60;
    var canvas = document.createElement("canvas");
    canvas.width = this.options.width;
    canvas.height = this.options.height;
    this.context = canvas.getContext("2d");
    this.canvas = canvas;
};
RotateIcon.makeIcon = function (url) {
    return new RotateIcon({ url: url });
};
RotateIcon.prototype.setRotation = function (options) {
    var canvas = this.context,
        angle = options.deg ? options.deg * Math.PI / 180 :
            options.rad,
        centerX = this.options.width / 2,
        centerY = this.options.height / 2;
    canvas.clearRect(0, 0, this.options.width, this.options.height);
    canvas.save();
    canvas.translate(centerX, centerY);
    canvas.rotate(angle);
    canvas.translate(-centerX, -centerY);
    canvas.drawImage(this.rImg, 0, 0);
    canvas.restore();
    return this;
};
RotateIcon.prototype.getUrl = function () {
    return this.canvas.toDataURL('image/png');
};


var interval;
var marker;
var info;
var route;
var mapp;
var active_item;
var addr;

export const MapSub = (props) => {
    const appSetting = encryptStorage.getItem("appSetting");
    const [vehicle_details, setVehicle_details] = useState({});
    const [app_conf, setApp_conf] = useState(encryptStorage.getItem("appSetting"));
    const [view_trip_det, setView_trip_det] = useState([]);
    const [start_point, setStart_point] = useState({})
    const [end_point, setEnd_point] = useState({})
    const [trip_bounds, setTrip_bounds] = useState([])
    const [coords, setCoords] = useState([])
    const [points, setPoints] = useState([])
    const [Label, setLabel] = useState(null);
    const [trip_active_marker, settrip_active_marker] = useState(null)
    const [trip_active_item, setTrip_active_item] = useState({})
    const [trip_info, setTrip_info] = useState(false)
    const [showTrip, setShowTrip] = useState(false);

    const [tripState, setTripState] = useState({});
    const show_cc_default = appSetting?.trip
    var cc_direction_api = show_cc_default?.direction_api ?? true

    useEffect(() => {
        if (props.page == "Map") {
            view_trip();
        }
    }, []);

    useEffect(() => {
        active_item = null
        addr = ""
        apply({ "vehicle_fk": props.vehicle_fk })
        return (() => {
            clearInterval(interval)
        })

    }, []);

    const apply = (data) => {
        let conf_inter = app_conf?.interval?.Trail

        if (isNaN(conf_inter) || conf_inter < 5 || conf_inter == undefined)
            conf_inter = 30;
        var coords = []
        fetchData.vehicle_list(data).then((res) => {
            var { vehicle_list } = res;
            var dat;
            for (var i = 0; i < vehicle_list.length; i++) {
                dat = vehicle_list[i]
                coords.push({ "lat": parseFloat(dat.lat), "lng": parseFloat(dat.lon) })

            }
            marker_draw(dat)

        }).catch((error) => {
            console.log(error)
        })
        interval = setInterval(function () {
            fetchData.vehicle_list(data).then((res) => {
                var { vehicle_list } = res;
                var dat;
                for (var i = 0; i < vehicle_list.length; i++) {
                    dat = vehicle_list[i]
                    coords.push({ "lat": parseFloat(dat.lat), "lng": parseFloat(dat.lon) })

                }
                track(dat, coords)

            }).catch((error) => {
                console.log(error)
            })
        }, (conf_inter ?? 15) * 1000)

    }

    const get_addr = (act) => {
        fetchData.v_addr({ "latlon": [{ "lat": act.lat, "lon": act.lon, "id": act.vsumm_pk_id }] }).then((res) => {
            addr = res.addr[0].formattedAddress
            if (active_item) {
                info.setContent(content(active_item))
            }
        }).catch((error) => {
            console.log(error)
        })
    }


    const get_icon = (data) => {
        var type = 0
        var icon;
        if (data.name == "Bus") {
            type = 4
        }
        else if (data.name == "Truck") {
            type = 2
        }
        else if (data.name == "Motorcycle") {
            type = 3
        }
        else {
            type = 1
        }
        if (data.v_stat == "Running") {
            icon = running_img[type]
        }
        else if (data.v_stat == "Idle") {
            icon = idle_img[type]

        }
        else {
            icon = stop_img[type]
        }

        return icon
    }
    const extendBounds = (bounds, radius) => {

        var point1 = new props.google.maps.LatLng(
            bounds.getNorthEast().lat() + radius,
            bounds.getNorthEast().lng() + radius
        )
        var point2 = new props.google.maps.LatLng(
            bounds.getSouthWest().lat() - radius,
            bounds.getSouthWest().lng() - radius
        )
        bounds.extend(point1);
        bounds.extend(point2);
        return bounds
    }

    const marker_draw = (item) => {
        let conf_inter = app_conf?.interval?.Tooltip
        active_item = item
        var icon = get_icon(item)
        var bounds = new props.google.maps.LatLngBounds();
        var coords = { "lat": item.lat, "lng": item.lon }
        bounds.extend(coords);
        mapp.map.fitBounds(extendBounds(bounds, 0.01));
        marker = new props.google.maps.Marker({
            position: { lat: item.lat, lng: item.lon },
            map: mapp.map,
            icon: {
                url: icon,
                size: new props.google.maps.Size(32, 32),
                origin: new props.google.maps.Point(0, 0),
                anchor: new props.google.maps.Point(16, 16),
                scale: 2,
            },

        });
        marker.addListener("click", () => {
            get_addr(item);
            info.open({
                anchor: marker,
                map: mapp.map,
                shouldFocus: true,
            });

            setTimeout(() => { info.close() }, (conf_inter ?? 10) * 1000)
        });
        get_addr(item);
        info = new props.google.maps.InfoWindow();
        info.open({
            anchor: marker,
            map: mapp.map,
            shouldFocus: true,
        });
        setTimeout(() => { info.close() }, (conf_inter ?? 10) * 1000)
        info.setContent(content(item))
        if (route)
            route.setMap(null);

        route = new props.google.maps.Polyline({
            path: coords,
            strokeColor: colors.running_cl,
            strokeOpacity: 2,
            strokeWeight: 3,
        });

        route.setMap(mapp.map);

    }

    const track = (item, coords) => {
        var icon = get_icon(item)
        active_item = item

        if (route)
            route.setMap(null);
        route = new props.google.maps.Polyline({
            path: coords,
            strokeColor: colors.running_cl,
            strokeOpacity: 6,
            strokeWeight: 6,
        });
        route.setMap(mapp.map);
        var myposition = { lat: item.lat, lng: item.lon };
        // var image = RotateIcon.makeIcon(icon).setRotation({ deg: Number(item.cog) }).getUrl();
        var icn = {
            url: icon,
            size: new props.google.maps.Size(32, 32),
            origin: new props.google.maps.Point(0, 0),
            anchor: new props.google.maps.Point(16, 16),
            scale: 2,
        };
        // if (image.length > 1000)
        //     icn.url = image;
        marker.setOptions({
            position: myposition,
            icon: icn,
        });
        // info.open({
        //     anchor: marker,
        //     map: mapp.map,
        //     shouldFocus: true,
        // });
        info.setContent(content(item))
    }


    const view_trip = () => {
        setCoords([]);
        setTrip_bounds(null);
        setTripState({
            start_point: {},
            end_point: {},
            points: []
        })
        var data = { "veh_fk": props.vehicle_fk };
        fetchData.view_veh_fence_route(data).then((res) => {
            var { view_route_lst } = res;
            setView_trip_det(view_route_lst);
            view_route(view_route_lst);

        }).catch((error) => {
            console.log(error);
        });
    };

    const view_route = (view_route_lst) => {
        if (view_route_lst.length > 0) {
            let start = view_route_lst[0];
            let end = view_route_lst[view_route_lst.length - 1];
            let wp = view_route_lst.slice(1, -1);

            console.log(wp);
            setTripState({
                start_point: start,
                end_point: end,
                points: wp
            });
            if (cc_direction_api) {
                draw_route_markers(start, end, wp);
            } else {

                const newCoords = view_route_lst.map(item => ({ lat: item.lat, lng: item.lng }));
                setCoords(newCoords);
            }
        }
    };

    const draw_route_markers = (start, end, waypoints) => {
        const waypts = waypoints.map(waypoint => ({
            location: new window.google.maps.LatLng(waypoint.lat, waypoint.lng),
            stopover: true
        }));

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route({
            origin: new window.google.maps.LatLng(start.lat, start.lng),
            destination: new window.google.maps.LatLng(end.lat, end.lng),
            waypoints: waypts,
            travelMode: window.google.maps.TravelMode.DRIVING,
            provideRouteAlternatives: true,
        }, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                const path = result.routes[0].overview_path; // Get the path from the result
                const newCoords = path.map(item => ({ lat: item.lat(), lng: item.lng() })); // Extract lat/lng from each point

                // Update state to store the first set of coordinates
                setCoords(newCoords); // Set the coords to the first chunk's coordinates

                // Create bounds for the map
                const bounds = new window.google.maps.LatLngBounds();
                path.forEach(point => bounds.extend(point));
                setTrip_bounds(bounds);
            } else {
                console.error('Error fetching directions:', result);
            }
        });
    };


    const onTripMarkerClick = (props, marker, e, point, label) => {
        console.log("eeeeeeeeeee", e)
        setLabel(label)
        settrip_active_marker(marker)
        setTrip_info(true)
        setTrip_active_item(point)
    }

    const content = (item) => {
        var incharge_name = item.incharge_name ? item.incharge_name : ""
        var incharge_no = item.incharge_no ? item.incharge_no : ""

        var contentString = '<div style="margin: 2; width: 400px; margin-right: 10px; margin-bottom: 10px; border: 1px solid grey;border-radius: 5px">'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> Vehicle No</p><p  style="font-size:12px;flex:1;font-weight:400">' + item.v_no + ' </p></div>'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> Assembly</p><p  style="font-size:12px;flex:1;font-weight:400">' + item.ac_name + ' </p></div>'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> Category</p><p  style="font-size:12px;flex:1;font-weight:400">' + item.category + ' </p></div>'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> Vehicle Status (Speed)</p><p  style="font-size:12px;flex:1;font-weight:400">' + item.v_stat + ' ( ' + item.speed + ' Km/hr )</p></div>'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> Last Updated</p><p  style="font-size:12px;flex:1;font-weight:400">' + item.dt + '</p></div>'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> Address</p><p  style="font-size:12px;flex:1;font-weight:400">' + addr + '</p></div>'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> Sector Off Name</p><p  style="font-size:12px;flex:1;font-weight:400">' + incharge_name + '</p></div>'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> Sector Off Mobile No</p><p  style="font-size:12px;flex:1;font-weight:400">' + incharge_no + '</p></div>'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> Driver Name</p><p  style="font-size:12px;flex:1;font-weight:400">' + item.driver_name + '</p></div>'
            + '<div style="display: flex; border-bottom: 1px solid grey; padding-left: 8px; paddingRight: 8px"><p style="font-size:12px;flex:1;font-weight:400"> Driver Mobile No</p><p  style="font-size:12px;flex:1;font-weight:400">' + item.driver_no + '</p></div>'

            + '</div>'
        return contentString
    }



    return (
        <Box sx={props?.position}>
            <Map
                initialCenter={{ lat: 20.5937, lng: 78.9629 }}
                google={props.google}
                zoom={14}
                ref={(mapr) => mapp = mapr}
                onClick={() => { info.close() }}

            >
                {props.page === "Map" && tripState?.start_point && (
                    <Marker
                        position={{ lat: tripState.start_point.lat, lng: tripState.start_point.lng }}
                        onClick={(props, marker, e) => onTripMarkerClick(props, marker, e, tripState.start_point, "Start")}
                        icon={{
                            url: g_icon,
                            anchor: new props.google.maps.Point(14, 14),
                            scaledSize: new props.google.maps.Size(35, 35),
                        }}
                    />
                )}
                {props.page === "Map" && tripState?.end_point && (
                    <Marker
                        position={{ lat: tripState.end_point.lat, lng: tripState.end_point.lng }}
                        onClick={(props, marker, e) => onTripMarkerClick(props, marker, e, tripState.end_point, "End")}
                        icon={{
                            url: r_icon,
                            anchor: new props.google.maps.Point(14, 14),
                            scaledSize: new props.google.maps.Size(35, 35),
                        }}
                    />
                )}
                {props.page === "Map" && tripState?.points?.map((waypoint, index) => (
                    waypoint?.lat && waypoint?.lng ? (
                        <Marker
                            key={index}
                            onClick={(props, marker, e) => onTripMarkerClick(props, marker, e, waypoint, `Waypoint - ${index + 1}`)}
                            position={{ lat: waypoint.lat, lng: waypoint.lng }}
                            icon={{
                                url: df_icon,
                                anchor: new props.google.maps.Point(14, 14),
                                scaledSize: new props.google.maps.Size(35, 35),
                            }}
                        />
                    ) : null
                ))}
                {props.page === "Map" && coords.length > 0 && (
                    <Polyline
                        path={coords}
                        strokeColor="#2b9df4"
                        strokeOpacity={6}
                        strokeWeight={6}
                    />
                )}
                <InfoWindow
                    visible={trip_info}
                    marker={trip_active_marker}
                >
                    <div style={{ width: 200, marginRight: 12, marginBottom: 12, border: "1px solid grey", borderRadius: 5 }}>
                        <div style={classes.info_cont}>
                            <p style={{ textAlign: 'center', ...classes.value }}>{Label} - {trip_active_item.point_name}</p>
                        </div>
                    </div>
                </InfoWindow>
            </Map>
            {props?.page == "Map" ?
                <Button endIcon={<CloseIcon />} onClick={props.handleClose} sx={classes.map_cls_btn}>
                    Close
                </Button> :
                <IconButton sx={classes.closebtn} onClick={props.handleClose}>
                    <CloseIcon />
                </IconButton>}
        </Box>
    )
}


export default SingleMapScreen({
    apiKey: mapkey.mapKey
})(MapSub)

