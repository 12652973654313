import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Navigate, useNavigate } from "react-router-dom";
import { Box, IconButton, Typography } from '@mui/material';
import { Map, Polyline, Marker, GoogleApiWrapper as TripView, Circle, InfoWindow } from 'google-maps-react';
import { ThreeDots } from 'react-loader-spinner'
import Cookies from 'universal-cookie';
import reddot from '../assets/reddot.png'
import greendot from '../assets/green_dot1.png'
import bluedot from '../assets/blue_dot.png'
import encryptStorage from "../encryptStorage";

// colors
import colors from '../config/colors';

// map API Key
import mapkey from '../config/map_key';
import { CloseOutlined } from '@mui/icons-material';

const classes = {
    container: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    box: {
        position: "absolute",
        top: 45,
        left: 15,
        right: 15,
        bottom: 15,
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99999,
        backgroundColor: "rgba(0,0,0,0.7)"
    },
    info_cont: {
        display: "flex",
        border: 1,
        borderBottom: "1px solid grey",
        paddingLeft: 8,
        paddingRight: 8
    },
    value: {
        flex: 1,
        fontSize: 11,
        fontWeight: 400
    },
}

const Trip_view = (props) => {
    const [bounds, setBounds] = useState([])
    const [coords, setCoords] = useState([])
    const [points, setPoints] = useState([])
    const [Label, setLabel] = useState(null);
    const [active_marker, setActive_marker] = useState(null)
    const [active_item, setActive_item] = useState({})
    const [info, setInfo] = useState(false)
    const appSetting = encryptStorage.getItem("appSetting");
    const map_default1 = { "lat": 16.2160, "lon": 77.3566, "zoom": 6 }
    const map_default2 = appSetting?.map_default
    const navigate = useNavigate();
    const [start_point, setStart_point] = useState({})
    const [end_point, setEnd_point] = useState({})

    useEffect(() => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        }
        view_route()
    }, []);

    const view_route = () => {
        var view_route_lst = props?.item
        setStart_point(view_route_lst[0])
        setEnd_point(view_route_lst[view_route_lst.length - 1])
        if (view_route_lst.length > 0) {
            let start = view_route_lst[0]
            let end = view_route_lst[view_route_lst.length - 1]
            let wp = view_route_lst
            wp.splice(0, 1)
            wp.splice(wp.length - 1, 1)
            setPoints(wp)

            draw_route_markers(start, end, wp)
        }
    }

    const draw_route_markers = (start, end, waypoints) => {
        const waypts = waypoints.map(waypoint => ({
            location: new window.google.maps.LatLng(waypoint.lat, waypoint.lng),
            stopover: true
        }));

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route({
            origin: start,
            destination: end,
            waypoints: waypts,
            travelMode: window.google.maps.TravelMode.DRIVING,
            provideRouteAlternatives: true,

        }, (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
                const path = result.routes[0].overview_path;
                setCoords(path);
                var res_ar = []
                path.map((items) => {
                    res_ar.push({ "lat": items.lat(), "lon": items.lng() })
                })
                console.log("res_ar", res_ar)
                const bounds = new window.google.maps.LatLngBounds();
                result.routes[0].overview_path.forEach(function (point) {
                    bounds.extend(point);
                    setBounds(bounds)
                });
            } else {
                console.error('Error fetching directions:', result);
            }
        });
    };

    const onMarkerClick = (props, marker, e, point, label) => {
        setLabel(label)
        setActive_marker(marker)
        setInfo(true)
        setActive_item(point)
    }

    return (
        <Box sx={classes.container}>
            <Box sx={{ display: "flex", mt: 0.5 }}>
                <Typography variant="h6" sx={{ ml: 35 }}>
                    {props.trip_name}
                </Typography>
                <IconButton onClick={props.onClose} sx={{ position: "absolute", right: 0 }}>
                    <CloseOutlined />
                </IconButton>
            </Box>
            <Box sx={classes.box}>
                <Map
                    bounds={bounds}
                    initialCenter={{ lat: map_default2?.lat ? map_default2?.lat : map_default1?.lat, lng: map_default2?.lon ? map_default2?.lon : map_default1?.lon }}
                    google={props.google}
                    zoom={map_default2?.zoom ? map_default2?.zoom : map_default1?.zoom}
                >
                    {start_point?.lat && start_point?.lng && (
                        <Marker
                            position={{ lat: start_point.lat, lng: start_point.lng }}
                            onClick={(props, marker, e) => onMarkerClick(props, marker, e, start_point, "Start")}
                            icon={{
                                url: greendot,
                                anchor: new props.google.maps.Point(14, 14),
                                scaledSize: new props.google.maps.Size(25, 25),
                            }}
                        />
                    )}
                    {end_point?.lat && end_point?.lng && (
                        <Marker
                            position={{ lat: end_point.lat, lng: end_point.lng }}
                            onClick={(props, marker, e) => onMarkerClick(props, marker, e, end_point, "End")}
                            icon={{
                                url: reddot,
                                anchor: new props.google.maps.Point(14, 14),
                                scaledSize: new props.google.maps.Size(25, 25),
                            }}
                        />
                    )}
                    {points.map((waypoint, index) => (
                        waypoint?.lat && waypoint?.lng && (
                            <Marker
                                key={index}
                                onClick={(props, marker, e) => onMarkerClick(props, marker, e, waypoint, `Waypoint - ${index + 1}`)}
                                position={{ lat: waypoint.lat, lng: waypoint.lng }}
                                icon={{
                                    url: bluedot,
                                    anchor: new props.google.maps.Point(14, 14),
                                    scaledSize: new props.google.maps.Size(25, 25),
                                }}
                            />
                        )
                    ))}
                    <Polyline
                        path={coords}
                        strokeColor="green"
                        strokeOpacity='6.0'
                        strokeWeight='4'
                    />
                    <InfoWindow
                        visible={info}
                        marker={active_marker}
                    >
                        <div style={{ width: 200, marginRight: 12, marginBottom: 12, border: "1px solid grey", borderRadius: 5 }}>
                            <div style={classes.info_cont}>
                                <p style={{ textAlign: 'center', ...classes.value }}>{Label} - {active_item.point_name}</p>
                            </div>

                        </div>
                    </InfoWindow>
                </Map>
            </Box>
        </Box>
    );
};

export default TripView({
    apiKey: mapkey.mapKey
})(Trip_view);