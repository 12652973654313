import { Button, TextField, Box, MenuItem, FormControl, Select, InputLabel, DialogTitle, Dialog, DialogContent, DialogActions, Typography, FormControlLabel, Checkbox, FormGroup, InputBase, Divider, IconButton } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { useSnackbar } from 'notistack';

import encryptStorage from "../../encryptStorage";

// Colors

// Api
import fetchData from '../../api/fetchData'

//icons
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import DataSaverOnOutlinedIcon from '@mui/icons-material/DataSaverOnOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';


// Styles
const classes = {
    filter_container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 60
    },
    filter_container1: {
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        justifyContent: "flex-start",
        height: 110,
    },
    filter_box: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        pl: 1
    },
    filter: {
        maxWidth: 200,
        flex: 1,
        m: 1,
    },
    condition_filter: {
        maxWidth: 80,
        flex: 1,
        m: 1,
    },
    filter1: {
        maxWidth: 400,
        flex: 1.8,
        m: 1,
    },
    filterdate: {
        width: 100,
        flex: 1,
        m: 0.8,
        backgroundColor: "white",
        color: "#444",
        height: 40,
        '&:hover': {
            backgroundColor: 'white',
            color: '#444',
        },
    },
    report_filter: {
        maxWidth: 140,
        flex: 1,
        m: 0.8,
        height: 40,

    },
    nav_box: {
        flex: 0.5, display: "flex", justifyContent: 'flex-end', pr: 1
    },
    nav_box1: {
        flex: 0.5, display: "flex", pr: 1, flexDirection: 'row-reverse'
    },
    btn: {
        flex: 1, m: 0.5
    },
    toggle_btn: {
        m: 1,
        flex: 1,
        maxWidth: 250,
        mr:0
    },
    toggle_btn1: {
        mt: 1,
        flex: 1,
        maxWidth: 80,
    },
    toggle_btn2: {
        mt: 1,
        flex: 1,
        maxWidth: 150,
    }
}
var NFW;

const idle_types = [{ "label": "=", "value": -1 }, { "label": "<", "value": 1 }, { "label": ">", "value": 2 }]

export function Filters(props) {
    const cookies = new Cookies();
    const [priv, setPriv] = useState(parseInt(cookies.get("priv")))
    const navigate = useNavigate();

    const appSetting = encryptStorage.getItem("appSetting");

    const [dist, setDist] = useState([])
    const [dist_val, setDist_val] = useState(0)
    const [ac, setAc] = useState([])
    const [ac_val, setAc_val] = useState(0)
    const [cat, setCat] = useState([])
    const [cat_val, setCat_val] = useState(0)
    const [v_stat, setV_stat] = useState([])
    const [v_stat_val, setV_stat_val] = useState(0)
    const [veh, setVeh] = useState([])
    const [veh_val, setVeh_val] = useState(0)
    const [speed_val, setSpeed_val] = useState(0)
    const [from, setFrom] = useState(null)
    const [to, setTo] = useState(null)
    const [fromdt, setFromDT] = useState(null)
    const [todt, setToDT] = useState(null)
    const [status, setStatus] = useState(0)
    const [sms_alert, setSms_alert] = useState(false)
    const [open, setOpen] = useState(0)
    const [alert_types, setAlert_types] = useState([])
    const [alert_type_val, setalert_type_val] = useState(0)
    const [idle_mins, setIdle_mins] = useState(0)
    const [idle_type_val, setIdle_type_val] = useState(-1)
    const [search_val, setSearch_val] = useState("");
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (props?.page != "Dash") {
            get_district();
            get_category();
            v_status();

        }
        if (props?.alertf) {
            get_alert_type()
            today()
        }
        if (props?.page == "NReport" || props?.page == "Alerts" || props.page == "Alert") {
            get_vehicle(0, 0)
        }
        if (props.page == "Report" || props.page == "Route" || props.page == "Alerts") {
            get_vehicle_dist(0, 0)
        }

        return (() => {
            const cookies = new Cookies();
            if (!cookies.get('user_id') && NFW != undefined) {
                NFW.close();
            }
        })

    }, [])

     
    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }

    const get_district = (stat) => {
        if (cookies.get("priv") == 2) {
            setDist_val(0)
            get_ac(0)
            return
        }
        var data = { "status": stat, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }
        fetchData.district(data).then((res) => {
            var { dist } = res;

            // if (dist.length > 1) {
            //     if (props.page == "Map" || props.page == "SReport" || props.page == "Route" || props.page == "Report" || props.page == "Fence") {
            //         dist.unshift({ "label": "Select", "value": 0 });
            //     }
            //     else {
            //         dist.unshift({ "label": "All", "value": 0 });

            //     }
            // }
            // if (dist.length > 1) {
            //     if (props.page == "Map" || props.page == "SReport" || props.page == "Route" || props.page == "Report" || props.page == "Fence"|| props.page == "Trip") {
            //         dist.unshift({ "label": "Select", "value": 0 });
            //     }
            //     else {
            //         dist.unshift({ "label": "All", "value": 0 });

            //     }
            // }
            if (props.page == "List") {
                dist.unshift({ "label": "All", "value": 0 });
                setDist_val(0);
                get_ac(0)
                dist_change(dist[0].value);
            }
            else if (dist.length >= 1) {
                setDist_val(dist[0].value)
                get_ac(dist[0].value)

            } else {
                get_ac(0)

            }
            setDist(dist)


        }).catch((error) => {
            console.log(error)
        })
    }

    const get_alert_type = () => {
        var data = {}
        fetchData.alert_types(data).then((res) => {
            var { alert_types } = res;
            if (alert_types.length > 1) {
                alert_types.unshift({ "label": "All", "value": 0 });
            }
            if (alert_types.length == 1) {
                setalert_type_val(alert_types[0].value)
            }
            setAlert_types(alert_types)
        }).catch((error) => {
            console.log(error)
        })
    }

    const get_ac = (stat) => {
        var data = { "dist_fk": stat, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }
        // if ((props.page == "Map" || props.page == "Route" || props.page == "Report" || props.page == "Fence"|| props.page == "Trip" || props.page == "SReport") && stat == 0 && priv == 0) {
        //     setAc([{ "label": "Select", "value": 0 }])
        //     return
        // }
        console.log("+++++++++++++++ACCC")
        fetchData.ac(data).then((res) => {
            var { assembly } = res;
            // if (assembly.length > 1) {
            //     if (props.page == "Route" || props.page == "Report" || props.page == "Fence") {
            //         assembly.unshift({ "label": "Select", "value": 0 });
            //     }
            //     else {
            //         assembly.unshift({ "label": "All", "value": 0 });

            //     }
            // }
            if (props.page == "List") {
                assembly.unshift({ "label": "All", "value": 0 });
                setAc_val(0)
            }
            else
                if (assembly?.length >= 1) {
                    setAc_val(assembly[0].value)
                    if (props.page == "Map" || props.page == "Alerts" || props.page == "List") {
                        ac_change(assembly[0].value)
                    }
                    if (props.page == "Fence" || props.page == "Trip") {
                        ac_change(assembly[0].value)
                    }
                    if (props.page == "Route" || props.page == "Report") {
                        get_vehicle(assembly[0].value)
                    }


                } else {
                    setAc_val(0)
                }
            setAc(assembly)
        }).catch((error) => {
            console.log(error)
        })
    }

    const get_category = () => {
        fetchData.category({}).then((res) => {
            var { category } = res;
            if (category.length > 1) {
                if (props.page == "Trip") {
                    category.unshift({ "label": "Select", "value": 0 });
                }
                else {
                    category.unshift({ "label": "All", "value": 0 });

                }
            }
            if (category.length == 1) {
                setCat_val(category[0].value)
            }
            setCat(category)

        }).catch((error) => {
            console.log(error)
        })
    }

    const v_status = () => {
        fetchData.v_status({}).then((res) => {
            var { v_status } = res;
            if (v_status.length > 1) {
                v_status.unshift({ "label": "All", "value": 0 });
            }

            setV_stat(v_status)

        }).catch((error) => {
            console.log(error)
        })
    }

    const get_vehicle = (ac_fk, category_fk) => {
        fetchData.vehicle({ "ac_fk": ac_fk, "category_fk": category_fk, "dist_fk": 0, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }).then((res) => {
            var { vehicle } = res;
            console.log(vehicle)
            if (props?.alertf) {
                vehicle.unshift({ "label": "All", "value": 0 });
                // if(!ac_fk){
                //     vehicle = [{ "label": "All", "value": 0 }];
                // }
            }
            else {
                vehicle.unshift({ "label": "Select", "value": 0 });

            }
            setVeh(vehicle)

        }).catch((error) => {
            console.log(error)
        })
    }

    const get_vehicle_dist = (dist_fk) => {
        if ((props.page == "Map" || props.page == "SReport" || props.page == "Route" || props.page == "Report" || props.page == "Alerts")) {
            setVeh([{ "label": "Select", "value": 0 }])
            return
        }
        fetchData.vehicle({ "ac_fk": 0, "category_fk": 0, "dist_fk": dist_fk, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }).then((res) => {
            var { vehicle } = res;
            if (props.page == "NReport" || props.page == "Alerts" || props.page == "Alert") {
                vehicle.unshift({ "label": "All", "value": 0 });
            }
            else {
                vehicle.unshift({ "label": "Select", "value": 0 });

            }
            setVeh(vehicle)
        }).catch((error) => {
            console.log(error)
        })
    }

    const apply = (frm1, to1) => {
        props.apply({ "status": v_stat_val, "dist_fk": dist_val, "ac_fk": ac_val, "category_fk": cat_val, "from": frm1 ? frm1 : from, "to": to1 ? to1 : to, "vehicle_fk": veh_val, "speed": speed_val, "alert_fk": alert_type_val, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv"), "fromdt": frm1 ? frm1 : fromdt, "todt": to1 ? to1 : todt, "idle_mins": idle_mins, "idle_type": idle_type_val , "idle_t":search_val})
    }

    const add_new_device = (bool) => {
        props.add_device({ "open": bool })
    }

    const get_veh = (ac, cat) => {
        if (props.page == "Route" || props.page == "Report" || props.page == "NReport" || props.page == "Alerts" || props.page == "Alert")
            get_vehicle(ac, cat)
    }

    const postDatetimeformat = (props) => {
        var date = new Date(props)
        var final = date.getFullYear() + "-" + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2) + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ":00"
        return (final);
    }

    const postDateformat = (props) => {
        var date = new Date(props)
        var final = date.getFullYear() + "-" + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2)
        return (final);
    }

    const today = () => {
        var from = moment().format('YYYY-MM-DD') + " 00:00"
        var to = moment().format('YYYY-MM-DD HH:mm')
        setFrom(from)
        setTo(to)
        apply(from, to)
    }
    const yesterday = () => {
        var from = moment().add(-1, 'days').format('YYYY-MM-DD') + " 00:00"
        var to = moment().add(-1, 'days').format('YYYY-MM-DD') + " 23:59"
        setFrom(from)
        setTo(to)
        apply(from, to)

    }
    const this_week = () => {
        var from = moment().startOf('week').isoWeekday(1).format('YYYY-MM-DD') + " 00:00"
        var to = moment().format('YYYY-MM-DD HH:mm')
        setFrom(from)
        setTo(to)
        apply(from, to)

    }
    const last_week = () => {
        var from = moment().startOf('week').isoWeekday(1).add(-7, 'days').format('YYYY-MM-DD') + " 00:00"
        var to = moment().startOf('week').isoWeekday(1).add(-1, 'days').format('YYYY-MM-DD') + " 23:59"
        setFrom(from)
        setTo(to)
        apply(from, to)

    }

    const today_date = () => {
        var from = moment().format('YYYY-MM-DD')
        var to = moment().format('YYYY-MM-DD')
        setFromDT(from)
        setToDT(to)
        apply(from, to)
    }
    const yesterday_date = () => {
        var from = moment().add(-1, 'days').format('YYYY-MM-DD')
        var to = moment().add(-1, 'days').format('YYYY-MM-DD')
        setFromDT(from)
        setToDT(to)
        apply(from, to)

    }
    const this_week_date = () => {
        var from = moment().startOf('week').isoWeekday(1).format('YYYY-MM-DD')
        var to = moment().format('YYYY-MM-DD')
        setFromDT(from)
        setToDT(to)
        apply(from, to)

    }
    const last_week_date = () => {
        var from = moment().startOf('week').isoWeekday(1).add(-7, 'days').format('YYYY-MM-DD')
        var to = moment().startOf('week').isoWeekday(1).add(-1, 'days').format('YYYY-MM-DD')
        setFromDT(from)
        setToDT(to)
        apply(from, to)

    }


    const dist_change = (val) => {
        if (props.page == 'List') {
            props.apply({ "status": v_stat_val, "dist_fk": val, "ac_fk": 0, "category_fk": 0, "vehicle_fk": 0, "speed": speed_val, "alert_fk": alert_type_val, "from": from, "to": to,"idle_type": idle_type_val, "idle_t":search_val ,"priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") })
        }
        if (val == 0) {
            setAc_val(0)
            setCat_val(0)
        }
        else {
            get_ac(val)
        }

        setVeh_val(0)
    }

    const ac_change = (val) => {
        if (props.page == 'Map' || props.page == 'List' || props.page == 'Alerts' || props.page == 'NReport' || props.page == 'Fence' || props.page == 'Trip' || props.page == "Alert") {
            props.apply({ "status": v_stat_val, "dist_fk": 0, "ac_fk": val, "category_fk": cat_val, "vehicle_fk": 0, "speed": speed_val, "alert_fk": alert_type_val, "from": from, "to": to, "fromdt": fromdt, "todt": todt,"idle_type": idle_type_val, "idle_t":search_val })
        }
        setVeh_val(0)
    }
    const cat_change = (val) => {
        if (props.page == 'Map' || props.page == 'List' || props.page == 'NReport' || props.page == 'Alerts' || props.page == "Alert") {
            props.apply({ "status": v_stat_val, "dist_fk": dist_val, "ac_fk": ac_val, "category_fk": val, "vehicle_fk": 0, "speed": speed_val, "alert_fk": alert_type_val, "from": from, "to": to, "fromdt": fromdt, "todt": todt,"idle_type": idle_type_val, "idle_t":search_val  })
        }
        setVeh_val(0)
    }

    const stat_change = (val) => {
        if (props.page == 'Map' || props.page == 'List' || props.page == 'NReport' || props.page == 'Alerts') {
            props.apply({ "status": val, "dist_fk": dist_val, "ac_fk": ac_val, "category_fk": cat_val, "vehicle_fk": 0, "speed": speed_val, "alert_fk": alert_type_val, "from": from, "to": to, "fromdt": fromdt, "todt": todt,"idle_type": idle_type_val , "idle_t":search_val })
        }
        setVeh_val(0)
    }

    const condition_change = (val) => {
        if (props.page == 'Map') {
            props.apply({ "status": v_stat_val, "dist_fk": dist_val, "ac_fk": ac_val, "category_fk": cat_val, "vehicle_fk": 0, "speed": speed_val, "alert_fk": alert_type_val, "from": from, "to": to, "fromdt": fromdt, "todt": todt,"idle_type": val , "idle_t":search_val})
        }
        setVeh_val(0)
    }

    const idle_change = (val) => {
        if (props.page == 'Map') {
           
        }
        setVeh_val(0)
    }


    function openPage(openPage) {
        var windowW = window.screen.availWidth;
        var windowH = window.screen.availHeight;
        var ews = "width=" + windowW + ",height=" + (windowH - 10);
        var style = "toolbar=no,status=yes,location=no,border=1,menubar=no,scrollbars=yes,resizable=no,maximized=yes,managepassword=no" + ews;
        var urlPop = openPage;
        NFW = window.open(urlPop, "popFrameless", style);
        NFW.moveTo(-3, -3);
        NFW.resizeTo(NFW.screen.availWidth + 18, NFW.screen.availHeight + 8);
        if (NFW != null) {
            window.opener = window;
        } else {
            document.write('<BR><BR><br><br><br><TABLE  align="center" border="0" width=100%><tr align="center"><td class="userInfo" align="center"><img src="/img/warningIcon.gif"></img>&nbsp;&nbsp;&nbsp;&nbsp;<B>Please enable Pop-up in your Browser.</B>&nbsp;</td></tr><tr align="center"><td>&nbsp;&nbsp;&nbsp;&nbsp;<B>Click on Tools->Pop-up Blocker->Turn off Pop-up Blocker.</B>&nbsp;</td></tr><tr align="center"><td>&nbsp;&nbsp;&nbsp;&nbsp&nbsp;</td></tr></TABLE>');
        }
        NFW.focus();
    }

    const dialogClose = () => {
        setOpen(false)
    }

    // const alerts_settings_put = (alert_typ) => {
    //     if (sms_alert) {
    //         if (mobile_no.length == 0) {
    //             alert_trigger("error", "Please fill mobile number")
    //             return;
    //         }
    //     }
    // }

    const idle_mins_search = (action_type) => {
        if(action_type == 1)
        {
            setSearch_val("")
            props.apply({ "status": v_stat_val, "dist_fk": dist_val, "ac_fk": ac_val, "category_fk": cat_val, "vehicle_fk": 0, "speed": speed_val, "alert_fk": alert_type_val, "from": from, "to": to, "fromdt": fromdt, "todt": todt,"idle_type": 0 , "idle_t":""})

        }
        if (action_type == 2) {
            if (!search_val) {
                alert_trigger("info", "Please enter Idle Mins")
                return
            }
            else{
            props.apply({ "status": v_stat_val, "dist_fk": dist_val, "ac_fk": ac_val, "category_fk": cat_val, "vehicle_fk": 0, "speed": speed_val, "alert_fk": alert_type_val, "from": from, "to": to, "fromdt": fromdt, "todt": todt,"idle_type": idle_type_val , "idle_t":search_val})
            }
        } 
        else {
            
            
        }
    }

    return (
        <Box sx={props.page == "NReport" || props.page == "Report" ? classes.filter_container1 : classes.filter_container}>
            <Box sx={classes.filter_box}>
                {props.options.includes("District") ? priv == 0 || priv == 1 ?
                    <FormControl fullWidth size='small' sx={classes.filter}>
                        <InputLabel >District</InputLabel>
                        <Select
                            MenuProps={{
                                style: { zIndex: 35001 }
                            }}
                            size='small'
                            label="District"
                            value={dist_val}
                            onChange={(e) => {
                                setDist_val(e.target.value); dist_change(e.target.value)
                            }}
                        >
                            {
                                dist?.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl> : null : null}
                {props.options.includes("Assembly") &&
                    <FormControl fullWidth size='small' sx={classes.filter}>
                        <InputLabel >Assembly</InputLabel>
                        <Select
                            MenuProps={{
                                style: { zIndex: 35001 }
                            }}
                            size='small'
                            label="Assembly"
                            value={ac_val}
                            onChange={(e) => {
                                setAc_val(e.target.value); get_veh(e.target.value, cat_val); ac_change(e.target.value);
                            }
                            }
                        >
                            {
                                ac?.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                }
                {props.options.includes("Category") &&
                    <FormControl fullWidth size='small' sx={classes.filter}>
                        <InputLabel >Category</InputLabel>
                        <Select
                            MenuProps={{
                                style: { zIndex: 35001 }
                            }}
                            size='small'
                            label="Category"
                            value={cat_val}
                            onChange={(e) => {
                                setCat_val(e.target.value); get_veh(ac_val, e.target.value); cat_change(e.target.value)
                            }}
                        >
                            {
                                cat?.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>}
                {props.options.includes("Vehicle") &&
                    <FormControl fullWidth size='small' sx={classes.filter}>
                        <InputLabel >Vehicle</InputLabel>
                        <Select
                            MenuProps={{
                                style: { zIndex: 35001 }
                            }}
                            size='small'
                            label="Vehicle"
                            value={veh_val}
                            onChange={(e) => {
                                setVeh_val(e.target.value)
                            }}
                        >
                            {
                                veh?.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                }
                {props.options.includes("Alerttype") &&
                    <FormControl fullWidth size='small' sx={classes.filter}>
                        <InputLabel >Alert</InputLabel>
                        <Select
                            size='small'
                            label="Alert"
                            value={alert_type_val}
                            onChange={(e) => {
                                setalert_type_val(e.target.value)
                            }}
                        >
                            {
                                alert_types?.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                }
                {props.options.includes("Status") &&
                    <FormControl fullWidth size='small' sx={classes.filter}>
                        <InputLabel >Status</InputLabel>
                        <Select
                            size='small'
                            label="Status"
                            value={v_stat_val}
                            onChange={(e) => { setV_stat_val(e.target.value); stat_change(e.target.value) }}
                        >
                            {
                                v_stat?.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                    )
                                })
                            }

                        </Select>
                    </FormControl>
                }

                {props.options.includes("Speed") &&
                    <TextField size='small' label="Speed(Km/h)" type="number" sx={classes.filter} variant="outlined" onChange={(e) => { setSpeed_val(e.target.value) }} />
                }
                {props.options.includes("IdleT") &&
                    <TextField size='small' label="Idle(Mins)" type="number" sx={classes.filter} variant="outlined" onChange={(e) => { setIdle_mins(e.target.value) }} />
                }
                {props.options.includes("idleType") &&
                    <FormControl fullWidth size='small' sx={classes.condition_filter}>
                        <InputLabel >Condition</InputLabel>
                        <Select
                            size='small'
                            label="Condition"
                            value={idle_type_val}
                            onChange={(e) => { setIdle_type_val(e.target.value);condition_change(e.target.value) }}
                        >
                            {
                                idle_types?.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                }
                {props.options.includes("From") &&
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            inputFormat='DD/MM/YYYY HH:mm:ss'
                            ampm={false}
                            label="From"
                            value={from}
                            emptyLabel="From"
                            onChange={(e) => {
                                console.log(e);
                                setFrom(postDatetimeformat(e))
                            }}
                            renderInput={(params) => <TextField size="small" variant="outlined" {...params} sx={classes.filter1} />}
                        />
                    </LocalizationProvider>
                }
                {props.options.includes("To") &&
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            inputFormat='DD/MM/YYYY HH:mm:ss'
                            ampm={false}
                            label="To"
                            value={to}
                            emptyLabel="To"
                            onChange={(e) => {
                                console.log(e)
                                setTo(postDatetimeformat(e))

                            }}
                            renderInput={(params) => <TextField size="small" variant="outlined" {...params} sx={classes.filter1} />}
                        />
                    </LocalizationProvider>
                }
                {props.options.includes("DATEfrom") &&
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat='DD/MM/YYYY'
                            label="From"
                            value={fromdt}
                            emptyLabel="From"
                            onChange={(e) => {
                                console.log(e);
                                setFromDT(postDateformat(e))
                            }}
                            renderInput={(params) => <TextField size="small" variant="outlined" {...params} sx={classes.filter} />}
                        />
                    </LocalizationProvider>
                }
                {props.options.includes("DATEto") &&
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat='DD/MM/YYYY'
                            label="To"
                            value={todt}
                            emptyLabel="To"
                            onChange={(e) => {
                                console.log(e)
                                setToDT(postDateformat(e))

                            }}
                            renderInput={(params) => <TextField size="small" variant="outlined" {...params} sx={classes.filter} />}
                        />
                    </LocalizationProvider>
                }
                {(props.options.includes("Idlemins")) &&
                    <Box
                        sx={{ display: 'flex', alignItems: 'center', backgroundColor: "black", borderRadius: 1, ml: 1, height: 40,mt:1 ,mr:1,flex:1.1,borderColor:"divider"}}
                    >
                        <InputBase
                            sx={{ ml: 1}}
                            placeholder=" Idle Mins"
                            value={search_val}
                            onChange={(e) => { setSearch_val(e.target.value) }}
                        />
                        <Divider orientation="vertical" />
                        <IconButton sx={{ borderRadius: 0 }}>
                            <RestartAltIcon onClick={() => { idle_mins_search(1)}} />
                        </IconButton>
                        <Divider orientation="vertical" />
                        <IconButton sx={{ borderRadius: 0 }}>
                            <SearchIcon onClick={() => { idle_mins_search(2)}}/>
                        </IconButton>
                    </Box>
                }
                {
                    props.options.includes("toggle") &&
                    <FormGroup sx={classes.toggle_btn}>
                        <FormControlLabel label={"Assembly Fence"} control={<Checkbox size="small" checked={props?.getchecked} onChange={(e) => { props.changeToggle(e.target.checked) }} />} />
                    </FormGroup>
                }
                {
                    props.options.includes("pstoggle") &&
                    <FormGroup sx={classes.toggle_btn1}>
                        <FormControlLabel label={"PS"} control={<Checkbox size="small" checked={props?.getpschecked} onChange={(e) => { props.changepsToggle(e.target.checked) }} />} />
                    </FormGroup>
                }
                 {
                    props.options.includes("cctoggle") &&
                    <FormGroup sx={classes.toggle_btn2}>
                        <FormControlLabel label={"CC & DC"} control={<Checkbox size="small" checked={props?.getccchecked} onChange={(e) => { props.changeccToggle(e.target.checked) }} />} />
                    </FormGroup>
                }
                 {/* {
                    props.options.includes("dctoggle") &&
                    <FormGroup sx={classes.toggle_btn1}>
                        <FormControlLabel label={"DC"} control={<Checkbox size="small" checked={props?.getdcchecked} onChange={(e) => { props.changedcToggle(e.target.checked) }} />} />
                    </FormGroup>
                } */}
                {props.options.includes("Apply") &&
                    <Button variant="contained" sx={classes.filter} onClick={() => apply(null, null)}>Apply</Button>
                }
                {props.options.includes("TripAdd") &&
                    <Button variant="contained" sx={classes.filter} startIcon={<DataSaverOnOutlinedIcon />} onClick={() => { props.onCreate() }} >Create Trip</Button>
                }
                {props.options.includes("Create") &&
                    <Button variant="contained" sx={classes.filter} startIcon={<DataSaverOnOutlinedIcon />} onClick={() => { props.setOpen(true); props.setDialog_title("Add New") }} >Add</Button>
                }
                {props.options.includes("AddDevice") &&
                    <Button variant="contained" startIcon={<DataSaverOnIcon />} sx={classes.filter} onClick={() => { add_new_device(true) }}>Add</Button>
                }
                {props.options.includes("Fname") &&
                    <FormControl fullWidth size='small' sx={classes.filter}>

                        <TextField
                            value={props?.item?.gfence_name}
                            size='small'
                            InputLabelProps={{ shrink: true }}
                            label="Name"
                            onChange={(e) => {
                                console.log(e)
                                props.setItem({ ...props.item, ["gfence_name"]: e.target.value })
                            }}
                            variant="outlined" /></FormControl>
                }

                {(props.options.includes("Radius") && props.fence_type == 1) &&
                    <FormControl fullWidth size='small' sx={classes.filter}>

                        <TextField
                            value={props?.item?.tollerence}
                            size='small'
                            InputLabelProps={{ shrink: true }}
                            label="Radius(Meter)"
                            onChange={(e) => {
                                console.log(e)
                                props.setItem({ ...props.item, ["tollerence"]: e.target.value })
                            }}
                            variant="outlined" /></FormControl>
                }
                
                {props.fence_edit &&
                    <Button variant="contained" sx={classes.filter} onClick={() => props.add_edit_fence("update")}>Update</Button>
                }
                {props.options.includes("AddFence") &&
                    <Button variant="contained" sx={classes.filter} onClick={() => props.add_edit_fence("insert")}>Add New</Button>
                }

            </Box>
            {(props.options.includes("Nav")) ?
                <Box sx={classes.nav_box}>
                    {appSetting.menu.map((page) => {


                        return <Button variant={props.page == page ? "contained" : "outlined"} sx={classes.btn} onClick={() => {
                            appSetting.feature[page].navigate && navigate(appSetting.feature[page].navigate, { state: page });
                            appSetting.feature[page].link && openPage(appSetting.feature[page].link);
                        }}>{appSetting.feature[page].label}</Button>
                    })
                    }
                </Box> : null}
            {(props.options.includes("To") && veh_val && (props.page == "Route")) ?
                <Box sx={{ position: "absolute", top: 125, zIndex: 9999, right: 50 }}>
                    <Button variant={props.page == "Route" ? "contained" : "outlined"} sx={props.page == "Report" ? classes.report_filter : classes.filterdate} onClick={() => today()}>Today</Button>
                    <Button variant={props.page == "Route" ? "contained" : "outlined"} sx={props.page == "Report" ? classes.report_filter : classes.filterdate} onClick={() => yesterday()}>Yesterday</Button>
                    <Button variant={props.page == "Route" ? "contained" : "outlined"} sx={props.page == "Report" ? classes.report_filter : classes.filterdate} onClick={() => this_week()}>This Week</Button>
                    <Button variant={props.page == "Route" ? "contained" : "outlined"} sx={props.page == "Report" ? classes.report_filter : classes.filterdate} onClick={() => last_week()}>Last Week</Button>
                </Box> : null}
            {props.page == "NReport" || props.page == "Report" ?
                <Box sx={classes.nav_box1} >
                    <Button variant={"outlined"} sx={classes.report_filter} onClick={() => last_week()}>Last Week</Button>
                    <Button variant={"outlined"} sx={classes.report_filter} onClick={() => this_week()}>This Week</Button>
                    <Button variant={"outlined"} sx={classes.report_filter} onClick={() => yesterday()}>Yesterday</Button>
                    <Button variant={"outlined"} sx={classes.report_filter} onClick={() => today()}>Today</Button>
                </Box> : null}
            {props.page == "SReport" ?
                <Box sx={classes.nav_box} >
                    {/* <Button variant={"outlined"} sx={classes.report_filter} onClick={() => today_date()}>Today</Button> */}
                    <Button variant={"outlined"} sx={classes.report_filter} onClick={() => yesterday_date()}>Yesterday</Button>
                    <Button variant={"outlined"} sx={classes.report_filter} onClick={() => this_week_date()}>This Week</Button>
                    <Button variant={"outlined"} sx={classes.report_filter} onClick={() => last_week_date()}>Last Week</Button>
                </Box> : null}
            <Box>
                <Dialog
                    open={open}
                    onClose={dialogClose}
                >
                    <Box sx={{ width: 450 }}>
                        <DialogTitle sx={{ fontSize: 15, height: 2 }}>Alerts</DialogTitle>
                        <DialogContent sx={{ flex: 1 }}>
                            <FormControl fullWidth size='small' sx={{ mt: 3 }} disabled>
                                <InputLabel >District</InputLabel>
                                <Select
                                    size='small'
                                    label="District"
                                    value={dist_val}
                                >
                                    {
                                        dist?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })
                                    }

                                </Select>
                            </FormControl>
                            <FormControl fullWidth size='small' sx={{ mt: 2 }} disabled>
                                <InputLabel >Assembly</InputLabel>
                                <Select
                                    size='small'
                                    value={ac_val}
                                    label="Assembly"
                                >
                                    {
                                        ac?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>

                            </FormControl>
                            <FormControl fullWidth size='small' sx={{ mt: 2 }} disabled>
                                <InputLabel >Category</InputLabel>
                                <Select
                                    size='small'
                                    value={cat_val}
                                    label="Category"
                                >
                                    {
                                        cat?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl fullWidth size='small' sx={{ mt: 2 }} disabled>
                                <InputLabel >Vehicle</InputLabel>
                                <Select
                                    size='small'
                                    value={veh_val}
                                    label="Vehicle"
                                >
                                    {
                                        veh?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <FormControl fullWidth size='small' sx={{ mt: 2 }} disabled>
                                <InputLabel >Alert</InputLabel>
                                <Select
                                    size='small'
                                    value={alert_type_val}
                                    label="Alert"
                                >
                                    {
                                        alert_types?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <Typography variant="subtitle1" sx={{ mt: 2 }}>Idle Alert Settings</Typography>
                            <Box sx={{ display: "flex" }}>
                                <FormControl size='small' sx={{ mt: 3 }}>
                                    <TextField
                                        //value={}
                                        size='small'
                                        InputLabelProps={{ shrink: true }}
                                        label="Idle Time Limit (mins)"
                                        // onChange={(e) => {

                                        // }}
                                        variant="outlined" />
                                </FormControl>
                                <FormControlLabel control={<Checkbox checked={sms_alert} />}
                                    label="Allow Mobile Alerts"
                                    sx={{ mt: 2, ml: 2 }}
                                    onChange={(e) => { setSms_alert(e.target.checked) }}
                                />
                            </Box>
                            <Box sx={{ display: "flex" }}>
                                {sms_alert &&
                                    <FormControl size='small' fullWidth>
                                        <TextField
                                            sx={{ mt: 2 }}
                                            size='small'
                                            type="number"
                                            InputLabelProps={{ shrink: true }}
                                            label="Mobile Number"
                                            // onChange={(e) => {
                                            //     setMobile_no(e.target.value)
                                            // }}
                                            variant="outlined" />
                                    </FormControl>
                                }
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button>Save</Button>
                            <Button onClick={dialogClose}>Cancel</Button>
                        </DialogActions>
                    </Box>
                </Dialog>

            </Box>
        </Box>

    )
}
