import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Box, IconButton, InputBase, Divider, Button } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { ThreeDots } from 'react-loader-spinner'
import Cookies from 'universal-cookie';
import { Navigate, useNavigate } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';


// components
import { Filters } from './component/filters';
import Reportmap from './report_map';

// colors
import colors from '../config/colors';

// Api
import fetchData from '../api/fetchData';

//icons
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const classes = {
    container: {
        position: "absolute",
        top: 10,
        left: 0,
        right: 0,
        bottom: 0,
        '& .super-app-theme--header': {
            backgroundColor: colors.dark1,
        },
    },
    data_table: {
        position: "absolute",
        top: 100,
        left: 10,
        right: 10,
        bottom: 60,
    },
    loader: {
        position: "absolute",
        top: 80,
        left: 10,
        right: 10,
        bottom: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    export_btn: {
        // position: "absolute",
        // right: 250,
        // top: 130,
        backgroundColor: colors.dark1,
        // width: 130,
        height: 40,
        color: "white",
        fontSize: 14,
        borderRadius: 1,
        px: 3,
        my: 1
    },
}

var vehicle_info1 = {}
export default function Sumary_report() {
    const [vehicle_details, setVehicle_details] = useState([]);
    const [paginationModel, setPaginationModel] = useState({ pageSize: 50, page: 0 })
    const [rowCountState, setRowCountState] = useState(0)
    const [datas, setDatas] = useState([])
    const [loader, setLoader] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const [active_item, setActive_item] = React.useState([]);
    const [active_coords, setActive_coords] = React.useState({});
    const [open, setOpen] = React.useState(false);
    // const [column, setColumn] = React.useState([]);
    const [search_val, setSearch_val] = useState("");
    const [dom_search_val, setDom_search_val] = useState("");
    var [loading_dwn, setLoading_dwn] = useState(false);
    var [disabled, setDisabled] = useState(true);


    const navigate = useNavigate();


    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }


    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        } else {
            // set_table_col(vehicle_info1)
        }
    }, []);



    const column = [
        { field: 'dname', headerName: 'District', flex: 1.2, headerClassName: 'super-app-theme--header', },
        { field: 'ac_name', headerName: 'Assembly', flex: 1.2, headerClassName: 'super-app-theme--header', },
        { field: 'category', headerName: 'Category', flex: 1, headerClassName: 'super-app-theme--header', },
        { field: 'v_no', headerName: 'Vehicle No', flex: 1.2, headerClassName: 'super-app-theme--header', },
        { field: 'imei', headerName: 'IMEI', flex: 1.2, headerClassName: 'super-app-theme--header', },
        // { field: 'name', headerName: 'Vehicle Type', flex: 1.5, headerClassName: 'super-app-theme--header' },
        { field: 'km', headerName: 'Total Distance Travelled (KMS)', flex: 1.5, headerClassName: 'super-app-theme--header', },
        // { field: 'idle_time', headerName: 'Total Idle Time (Mins)', flex: 1.5, headerClassName: 'super-app-theme--header', },
        // { field: 'running_time', headerName: 'Total Running Time (Mins)', flex: 1.5, headerClassName: 'super-app-theme--header', },
        { field: 'driver_name', headerName: 'Driver Name', flex: 1.2, headerClassName: 'super-app-theme--header', },
        { field: 'driver_no', headerName: 'Driver No 1', flex: 1, headerClassName: 'super-app-theme--header', },
        { field: 'driver_no2', headerName: 'Driver No 2', flex: 1, headerClassName: 'super-app-theme--header', },
        // { field: 'dt', headerName: 'Date', flex: 1.5, headerClassName: 'super-app-theme--header', },
    ]

    const apply = (data) => {
        const cookies = new Cookies();
        setDatas(data)
        if ((!data.dist_fk) && (cookies.get("priv") == 0 || cookies.get("priv") == 1)) {
            alert_trigger("error", "Please select District to view report")
            return
        }
        // else if (!data.ac_fk) {
        //     alert_trigger("error", "Please select Assembly to view report")
        //     return
        // }
        else if (!data.fromdt) {
            alert_trigger("error", "Please select date range to view report")
            return
        }
        else if (!data.todt) {
            alert_trigger("error", "Please select date range to view report")
            return
        }
        setLoader(true)
        data["pageSize"] = data.pageSize ? data.pageSize : paginationModel.pageSize
        data["page"] = data.page ? data.page : paginationModel.page
        fetchData.summary_report(data).then((res) => {
            var { summary_lst, total_count } = res;
            setRowCountState(total_count)
            setVehicle_details(summary_lst)
            setLoader(false)
            if (summary_lst.length > 0) {
                setDisabled(false)
            } else {
                setDisabled(true)
            }
        }).catch((error) => {
            console.log(error)
            setLoader(false)
        })
    }




    const export_summary_report = () => {
        setLoading_dwn(true)
        fetchData.export_summary_report(datas).then((response) => {
            const href = window.URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'Summary Report.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setLoading_dwn(false)
        }).catch((error) => {
            console.log(error)
        })
    }



    return (
        <Box sx={classes.container}>
            <Filters page={"SReport"} apply={apply} options={"District,Assembly,Category,DATEfrom,DATEto,Apply"}></Filters>
            {loader ? <Box sx={classes.loader}>
                <ThreeDots
                    height="100"
                    width="100"
                    radius="7"
                    color="#0288d1"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                /></Box>
                : <Box sx={classes.data_table}>
                    {/* {
                        loading_dwn == true ?
                            <LoadingButton
                                loading
                                loadingPosition="center"
                                sx={classes.export_btn}
                                size="small"
                            >Loading...
                            </LoadingButton> :
                            <Button size="small" startIcon={<SaveAltIcon />} sx={classes.export_btn} onClick={() => { export_summary_report() }} disabled={disabled}>Export</Button>
                    } */}
                    <DataGrid
                        disableColumnSelector
                        disableDensitySelector
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                        pageSizeOptions={[10, 20, 50]}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 50 } },
                        }}
                        // paginationModel={paginationModel}
                        // rowCount={rowCountState}
                        // paginationMode="server"
                        // onPaginationModelChange={(e) => { changePagination(e) }}
                        rows={vehicle_details}
                        columns={column}
                    slots={{
                         toolbar: CustomToolbar,
                     }}
                     slotProps={{ toolbar: { csvOptions: { allColumns: true } } }}
                    />
                </Box>
            }
            {open &&
                <Reportmap handleClose={handleClose} item={active_item} coords={active_coords} />
            }
            {/* {(vehicle_details.length > 0 && !loader)&&
                <Box
                    component="form"
                    sx={{ display: 'flex', alignItems: 'center', backgroundColor: "black", borderRadius: 1, ml: 1, height: 40, position: "absolute", top: 88, right: 140 }}
                >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Vehicle No/IMEI"
                        value={search_val}
                        onChange={(e) => { setSearch_val(e.target.value) }}
                    />
                    <Divider orientation="vertical" />
                    <IconButton sx={{ borderRadius: 0 }}>
                        <CloseIcon onClick={() => { setSearch_val(""); search_vehicle(1); }} />
                    </IconButton>
                    <Divider orientation="vertical" />
                    <IconButton sx={{ borderRadius: 0 }}>
                        <SearchIcon onClick={() => { search_vehicle(2) }} />
                    </IconButton>
                </Box>
            } */}
        </Box>
    )
}

function CustomToolbar() {
    return (
        <GridToolbarContainer sx={{ backgroundColor: colors.dark1, height: 50, justifyContent: "flex-end", pt: 1 }}>
            <GridToolbarExport printOptions={{ disableToolbarButton: true }} sx={{ mr: 3, backgroundColor: "black", height: 39, width: 90, color: "white", fontSize: 14 }} />
        </GridToolbarContainer>
    );
}