import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Box, Button, TextField, IconButton, DialogActions, Dialog, DialogTitle, DialogContent, } from '@mui/material';
import { ThreeDots } from 'react-loader-spinner'
import Cookies from 'universal-cookie';
import { Navigate, useNavigate } from "react-router-dom";

// components
import { Filters } from './component/filters';
import { Table } from './component/table_alerts';
import { CustomDialog } from './component/table_alerts';
import { Header } from './component/header';

// colors
import colors from '../config/colors';

// Api
import fetchData from '../api/fetchData';

//icons
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { Bolt } from '@mui/icons-material';

const classes = {
    container: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    data_table: {
        position: "absolute",
        top: 70,
        left: 10,
        right: 10,
        bottom: 10,
        '& .super-app-theme--header': {
            backgroundColor: colors.dark1,
        },
    },
    loader: {
        position: "absolute",
        top: 130,
        left: 10,
        right: 10,
        bottom: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    search: {
        top: 15,
        position: 'absolute',
        maxWidth: 200,
        right: 10
    }
}

export default function Alert(props) {
    const [edit_item, setEdit_item] = useState({});
    const [loader, setLoader] = useState(false)
    const [open, setOpen] = useState(false)
    const [delete_modal, setDelete_modal] = useState(false)
    const [delete_item, setDelete_item] = useState({});
    const [dialog_title, setDialog_title] = useState("")
    const [datas, setDatas] = useState([])
    const navigate = useNavigate();
    const [filtered_items, setFiltered_items] = useState([])
    const [alerts_det, setAlerts_Det] = useState([]);
    const [paginationModel, setPaginationModel] = useState({ pageSize: 100, page: 0 })
    const [rowCountState, setRowCountState] = useState(0)
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        { field: 'dname', headerName: 'District', flex: 1, headerClassName: 'super-app-theme--header', width: 70 },
        { field: 'ac_name', headerName: 'Assembly', flex: 1, headerClassName: 'super-app-theme--header', width: 70 },
        { field: 'category', headerName: 'Category', flex: 1, headerClassName: 'super-app-theme--header', width: 70 },
        // { field: 'v_no', headerName: 'Vehicle No', flex: 1, headerClassName: 'super-app-theme--header', width: 70 },
        // { field: 'alerttype', headerName: 'Alert Type', flex: 1, headerClassName: 'super-app-theme--header', width: 70 },
        { field: 'idle_time', headerName: 'Idle Time Limits(mins)', flex: 1, headerClassName: 'super-app-theme--header', },
        // { field: 'mobile_num', headerName: 'Mobile Number', flex: 1, headerClassName: 'super-app-theme--header', width: 70 },
        // {
        //     field: 'sms_alert',
        //     headerName: 'Mobile Alert',
        //     flex: 1,
        //     headerClassName: 'super-app-theme--header',
        //     renderCell: (params) => {
        //         return params.value === 1 ? (
        //             <Box sx={{
        //                 backgroundColor: colors.green,
        //                 width: 80,
        //                 textAlign: "center",
        //                 borderRadius: 2,
        //                 padding: 0.5
        //             }}>
        //                 Enabled
        //             </Box>
        //         ) : (
        //             <Box sx={{
        //                 backgroundColor: colors.red,
        //                 width: 80,
        //                 textAlign: "center",
        //                 padding: 0.5,
        //                 borderRadius: 2
        //             }}>
        //                 Disabled
        //             </Box>
        //         );
        //     },
        //     width: 70
        // },
        { field: 'Edit', headerName: '', flex: 0.3, headerClassName: 'super-app-theme--header', renderCell: (params) => { return (<IconButton onClick={(event) => { setEdit_item(params.row); setOpen(true); setDialog_title("Edit") }}> <EditNoteOutlinedIcon color="primary" /></IconButton>) } },
        { field: 'Delete', headerName: '', flex: 0.3, headerClassName: 'super-app-theme--header', renderCell: (params) => { return (<IconButton onClick={(event) => { setDelete_item(params.row); setDelete_modal(true) }}><DeleteIcon color="error" /></IconButton>) } },
    ]

    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }

    useEffect(() => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        } else {
            var data = { "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }
            //apply(data)
        }
    }, []);

    const apply = (data) => {
        setDatas(data)
        setLoader(true)
        data["pageSize"] = data.pageSize ? data.pageSize : paginationModel.pageSize
        data["page"] = data.page ? data.page : paginationModel.page
        fetchData.alerts_det_get(data).then((res) => {
            var { alerts_det, total_count } = res;
            console.log("total count", total_count)
            setAlerts_Det(alerts_det)
            setRowCountState(total_count)
            setFiltered_items(alerts_det)
            setLoader(false)
        }).catch((error) => {
            console.log(error)
        })
    }

    const refresh = () => {
        apply(datas)
    }

    const detete_data = () => {
        const cookies = new Cookies();
        var data = { "alert_id": delete_item.id }
        fetchData.alerts_det_delete(data).then((res) => {
            var { msg, rval } = res;
            if (rval > 0) {
                alert_trigger("success", msg)
                setDelete_modal(false)
                refresh()
            } else {
                alert_trigger("error", msg)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const filter_table = (text) => {
        const filteredItems = alerts_det.filter(
            item => JSON.stringify(item)
                .toLowerCase().includes(text.toLowerCase())
        );
        setFiltered_items(filteredItems)
    }

    const changePagination = (e) => {
        console.log(e)
        setPaginationModel(e);
        var data1 = datas
        data1["pageSize"] = String(e.pageSize)
        data1["page"] = String(e.page)
        apply(data1)
    }

    return (
        <Box sx={classes.container}>
            <Filters setOpen={setOpen} page={"Alerts"} setDialog_title={setDialog_title} apply={apply} options={"District,Assembly,Category,Create"}></Filters>
            <TextField size='small' label="Search" sx={classes.search} variant="outlined" onChange={(e) => { filter_table(e.target.value) }} />
            {loader ? <Box sx={classes.loader}>
                <ThreeDots
                    height="100"
                    width="100"
                    radius="7"
                    color="#0288d1"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                /></Box> :
                <Box>
                    <Table columns={columns} rows={filtered_items} changePagination={changePagination} rowCountState={rowCountState} paginationModel={paginationModel} open={open} setOpen={setOpen} />
                    {open ? <CustomDialog open={open} dialog_title={dialog_title} setOpen={setOpen} close={() => { setEdit_item({}) }} edit_item={edit_item} refresh={refresh} ></CustomDialog> : null}
                    {delete_modal &&
                        <Dialog
                            open={true}
                            onClose={() => setDelete_modal(false)}
                        >
                            <Box sx={{ width: 450 }}>
                                <DialogTitle sx={{ fontSize: 15 }}>Confirm Delete</DialogTitle>
                                <DialogContent >
                                    Are you sure you want to delete item?
                                </DialogContent>
                                <DialogActions sx={{ pr: 3, pb: 2 }}>
                                    <Button onClick={() => setDelete_modal(false)}>Cancel</Button>
                                    <Button onClick={() => detete_data()}>Delete</Button>
                                </DialogActions>
                            </Box>
                        </Dialog>
                    }
                </Box>
            }
        </Box>
    )
}
