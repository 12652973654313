import { Button, Box, MenuItem, FormControl, Grid, Select, InputLabel, DialogTitle, Dialog, DialogContent, DialogActions, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { useState, useEffect } from "react";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';

//colors
import colors from '../../config/colors'

//cookies
import Cookies from 'universal-cookie';

//fetchdata
import fetchData from '../../api/fetchData';

const classes = {
    filter1: {
        width: 200,
        my: 1,
    }
}

export function CustomDialog(props) {
    var item = props.edit_item;
    // const { dist, ac, cat, veh, alert_types, open, setOpen,addRow} = props;
    const cookies = new Cookies();
    const [alert_id, setAlert_Id] = useState(item.id ? item.id : 0);
    const [dist_val, setDist_val] = useState(item.district_pk_id ? item.district_pk_id : 0)
    const [dist, setDist] = useState([])
    const [ac, setAc] = useState([])
    const [ac_val, setAc_val] = useState(item.ac_pk_id ? item.ac_pk_id : 0);
    const [cat_val, setCat_val] = useState(item.category_pk_id ? item.category_pk_id : 0);
    const [cat, setCat] = useState([])
    // const [sms_alert, setSms_alert] = useState(item.sms_alert ? item.sms_alert : "");
    const [idlet, setIdlet] = useState(item.idle_time ? item.idle_time : "")
    // const [mobile_no, setMobile_no] = useState(item.mobile_num ? item.mobile_num : "")
    const { enqueueSnackbar } = useSnackbar();
    const [insert, setInsert] = useState("insert")
    const [update, setUpdate] = useState("update")
    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }
    useEffect(() => {
        get_district();
        get_category();
        //  get_vehicle();
        // get_alert_type();
    }, []);

    const get_district = (stat) => {
        var data = { "status": stat, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }
        fetchData.district(data).then((res) => {
            var { dist } = res;
            if (dist.length > 1) {
                dist.unshift({ "label": "Select", "value": 0 });
            }
            if (dist.length == 1) {
                setDist_val(dist[0].value)
            }
            setDist(dist)
            get_ac(dist_val)

        }).catch((error) => {
            console.log(error)
        })
    }

    const get_ac = (stat) => {
        var data = { "dist_fk": stat, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }
        fetchData.ac(data).then((res) => {
            var { assembly } = res;
            if (assembly.length > 1) {
                assembly.unshift({ "label": "Select", "value": 0 });
            }
            if (assembly.length == 1) {
                setAc_val(assembly[0].value)
            } else {
                setAc_val(0)
            }
            setAc(assembly)
        }).catch((error) => {
            console.log(error)
        })
    }

    const get_category = () => {
        fetchData.category({}).then((res) => {
            var { category } = res;
            if (category.length > 1) {
                category.unshift({ "label": "Select", "value": 0 });
            }
            if (category.length == 1) {
                setCat_val(category[0].value)
            }
            setCat(category)

        }).catch((error) => {
            console.log(error)
        })
    }

    // const get_vehicle = (ac_fk, category_fk) => {
    //     fetchData.vehicle({ "ac_fk": ac_fk, "category_fk": category_fk, "dist_fk": 0, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }).then((res) => {
    //         var { vehicle } = res;
    //         console.log(vehicle)
    //         if (props?.alertf) {
    //             vehicle.unshift({ "label": "All", "value": 0 });
    //             // if(!ac_fk){
    //             //     vehicle = [{ "label": "All", "value": 0 }];
    //             // }
    //         }
    //         else {
    //             vehicle.unshift({ "label": "Select", "value": 0 });

    //         }
    //         setVeh(vehicle)

    //     }).catch((error) => {
    //         console.log(error)
    //     })
    // }

    // const get_alert_type = () => {
    //     var data = {}
    //     fetchData.alert_types(data).then((res) => {
    //         var { alert_types } = res;
    //         if (alert_types.length > 1) {
    //             alert_types.unshift({ "label": "All", "value": 0 });
    //         }
    //         if (alert_types.length == 1) {
    //             setalert_type_val(alert_types[0].value)
    //         }
    //         setAlert_types(alert_types)
    //     }).catch((error) => {
    //         console.log(error)
    //     })
    // }

    const new_device_put = () => {
        if (dist_val == 0) {
            alert_trigger("error", "please select District")
            return
        }
        else if (ac_val == 0) {
            alert_trigger("error", "please select Assembly")
            return
        }
        else if (cat_val == 0) {
            alert_trigger("error", "please select Category")
            return
        }
        else if (!idlet) {
            alert_trigger("error", "please Enter Idle Time Limit")
            return
        }
        var data = { "dist_fk": dist_val, "ac_fk": ac_val, "category_fk": cat_val, "idle_t": idlet, "act": insert }
        fetchData.alerts_det_update(data).then((res) => {
            var { rval, msg } = res;
            if (rval > 0) {
                alert_trigger("success", msg)
                props.refresh()
                props.setOpen(false)
                props.close()
            }

            else {
                alert_trigger("error", msg)
            }
        }).catch((error) => {
            console.log(error)
            alert_trigger("error", "Something went wrong")

        })
    }

    const update_device = () => {
        if (dist_val == 0) {
            alert_trigger("error", "please select District")
            return
        }
        else if (ac_val == 0) {
            alert_trigger("error", "please select Assembly")
            return
        }
        else if (cat_val == 0) {
            alert_trigger("error", "please select Category")
            return
        }
        else if (!idlet) {
            alert_trigger("error", "please Enter Idle Time Limit")
            return
        }
        var data = { "dist_fk": dist_val, "ac_fk": ac_val, "category_fk": cat_val, "idle_t": idlet, "alert_id": alert_id, "act": update }
        fetchData.alerts_det_update(data).then((res) => {
            var { rval, msg } = res;
            if (rval > 0) {
                alert_trigger("success", msg)
                props.refresh()
                props.setOpen(false)
                props.close()
            }
            else {
                alert_trigger("error", msg)

            }
        }).catch((error) => {
            console.log(error)
            alert_trigger("error", "Something went wrong")

        })
    }

    const dialog_close = () => {
        props.setOpen(false)
        props.close()
    }

    return (
        <Box>
            <Dialog
                open={props.open}
                onClose={dialog_close}
            >
                <Box sx={{ width: 500 }}>
                    <DialogTitle sx={{ fontSize: 15 }}>{props.dialog_title}</DialogTitle>
                    <DialogContent sx={{ flex: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth size='small' sx={{ ...classes.filter1, mt: 2 }} >
                                    <InputLabel >District</InputLabel>
                                    <Select
                                        size='small'
                                        label="District"
                                        value={dist_val}
                                        onChange={(e) => { setDist_val(e.target.value); get_ac(e.target.value) }}
                                    >
                                        {
                                            dist?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth size='small' sx={{ ...classes.filter1, mt: 2 }} >
                                    <InputLabel >Assembly</InputLabel>
                                    <Select
                                        size='small'
                                        value={ac_val}
                                        onChange={(e) => {
                                            setAc_val(e.target.value)
                                        }}
                                        label="Assembly"
                                    >
                                        {
                                            ac?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>

                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth size='small' sx={classes.filter1} >
                                    <InputLabel >Category</InputLabel>
                                    <Select
                                        size='small'
                                        value={cat_val}
                                        onChange={(e) => {
                                            setCat_val(e.target.value)
                                        }}
                                        label="Category"
                                    >
                                        {
                                            cat?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            {/* <Grid item xs={6}>
                                <FormControl fullWidth size='small' sx={classes.filter1} >
                                    <InputLabel >Vehicle</InputLabel>
                                    <Select
                                        size='small'
                                        value={veh_val}
                                        onChange={(e) => {
                                            setVeh_val(e.target.value)
                                        }}
                                        label="Vehicle"
                                    >
                                        {
                                            veh?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid> */}
                            {/* <FormControl fullWidth size='small' sx={{ mt: 2 }} >
                            <InputLabel >Alert</InputLabel>
                            <Select
                                size='small'
                                value={alert_type_val}
                                onChange={(e) => {
                                    setalert_type_val(e.target.value)
                                }}
                                label="Alert"
                            >
                                {
                                    alert_types?.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl> */}
                            <Grid item xs={6}>
                                <FormControl size='small' sx={classes.filter1} >
                                    <TextField
                                        value={idlet}
                                        size='small'
                                        InputLabelProps={{ shrink: true }}
                                        label="Idle Time Limit (mins)"
                                        onChange={(e) => {
                                            setIdlet(e.target.value)
                                        }}
                                        variant="outlined" />
                                </FormControl>
                            </Grid>
{/* 
                            <Grid item xs={6}>
                                <Box >
                                    <FormControlLabel control={<Checkbox checked={sms_alert} />}
                                        label="Allow Mobile Alerts"
                                        // sx={{ mt: 2, ml: 2 }}
                                        onChange={(e) => { setSms_alert(e.target.checked == 0 ? setMobile_no("") : 1) }}
                                    />
                                </Box>
                            </Grid>


                            <Grid item xs={6}>
                                {sms_alert &&
                                    <FormControl size='small' sx={classes.filter1}>
                                        <TextField
                                            size='small'
                                            type="number"
                                            InputLabelProps={{ shrink: true }}
                                            label="Mobile Number"
                                            value={mobile_no}
                                            onChange={(e) => {
                                                setMobile_no(e.target.value)
                                            }}
                                            variant="outlined" />
                                    </FormControl>
                                }
                            </Grid> */}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={dialog_close}>Cancel</Button>
                        {props.dialog_title == "Add New" ? <Button onClick={() => { new_device_put() }}>Save</Button> : <Button onClick={() => update_device()}>Update</Button>}
                    </DialogActions>
                </Box>
            </Dialog>
        </Box>)
}

export function Table(props) {
    const classes = {

        data_table: {
            position: "absolute",
            top: 70,
            left: 10,
            right: 10,
            bottom: 10,
            '& .super-app-theme--header': {
                backgroundColor: colors.dark1,
            },
        },
    }

    return (
        <Box>
            <Box sx={classes.data_table} >
                <DataGrid
                    disableColumnSelector
                    disableDensitySelector
                    disableSelectionOnClick
                    disableRowSelectionOnClick
                    pageSizeOptions={[10, 20, 50, 100]}
                    initialState={{
                        pagination: { paginationModel: { pageSize: props.paginationModel.pageSize } },
                    }}
                    paginationModel={props.paginationModel}
                    rowCount={props.rowCountState}
                    paginationMode="server"
                    onPaginationModelChange={(e) => { props.changePagination(e) }}
                    columns={props.columns}
                    rows={props.rows}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    slotProps={{ toolbar: { csvOptions: { allColumns: true } } }}
                />
            </Box>
            <Box>
                <Dialog open={props.deldialog}>
                    <Box sx={{ width: 400 }}>
                        <DialogContent>
                            Are You Sure Want to Delete ?
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { props.setDelDialog(false) }}>No</Button>
                            <Button onClick={() => { props.handleConfirmDelete() }}>Yes</Button>
                        </DialogActions>
                    </Box>
                </Dialog>
            </Box>
        </Box>
    )
}
function CustomToolbar() {
    return (
        <GridToolbarContainer sx={{ backgroundColor: colors.dark1, justifyContent: "flex-end", pt: 1 }}>
            <GridToolbarExport printOptions={{ disableToolbarButton: true }} sx={{ mr: 3, backgroundColor: "black", height: 39, width: 100, color: "white", fontSize: 14 }} />
        </GridToolbarContainer>
    );
}
