import React from 'react';
import { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Navigate, useNavigate } from "react-router-dom";
import { Button, TextField, Box, Typography, DialogActions, IconButton, Dialog, DialogTitle, DialogContent, Grid, InputLabel, FormControl, Select, MenuItem } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { ThreeDots } from 'react-loader-spinner'
import Cookies from 'universal-cookie';


// components
import { Header } from './component/header';
import { Filters } from './component/filters';

// colors
import colors from '../config/colors';

//icons
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';

// Api
import fetchData from '../api/fetchData';

const classes = {
    container: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    data_table: {
        position: "absolute",
        top: 130,
        left: 10,
        right: 10,
        bottom: 10,
        '& .super-app-theme--header': {
            backgroundColor: colors.dark1,
        },
    },
    loader: {
        position: "absolute",
        top: 130,
        left: 10,
        right: 10,
        bottom: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    add_btn: {
        position: "absolute",
        right: 15,
        top: 70,
        width: 170,
        py: 0.8
    },
    container1: {
        flex: 1,
    },
    filter1: {
        width: 200,
        my: 1,
    },
    search: {
        position: "absolute",
        top: 80, right: 10
    }
}

const style = {
    menu_style: {
        overflow: 'visible',
        filter: 'drop-shadow(2px 2px 2px rgba(179,179,179,0.45))',
        mt: 1.5,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
    },
}


export default function Admin() {
    const [vehicle_details, setVehicle_details] = useState([]);
    const [edit_item, setEdit_item] = useState({});
    const [delete_item, setDelete_item] = useState({});
    const [loader, setLoader] = useState(false)
    const [delete_modal, setDelete_modal] = useState(false)
    const [add_edit_modal, setAdd_edit_modal] = useState(false)
    const [modal_title, setModal_title] = useState("")
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [datas, setDatas] = useState([])
    const [filtered_items, setFiltered_items] = useState([])


    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }

    useEffect(() => {
        const cookies = new Cookies();
        var uid = cookies.get("user_id")
        if (uid == null || uid == "") {
            navigate("/")
        } else {
            var data = { "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }
            // apply(data)
        }
    }, []);

    const apply = (data) => {
        setDatas(data)
        setLoader(true)
        fetchData.vehicle_list_mapping(data).then((res) => {
            var { vehicle_list } = res;
            setVehicle_details(vehicle_list)
            setFiltered_items(vehicle_list)
            setLoader(false)
        }).catch((error) => {
            console.log(error)
        })
    }

    const refresh = () => {
        apply(datas)
    }

    const add_new_device = (bool) => {
        setModal_title("Add New Device")
        setAdd_edit_modal(bool)
    }

    const filter_table = (text) => {
        const filteredItems = vehicle_details.filter(
            item => JSON.stringify(item)
                .toLowerCase().includes(text.toLowerCase()),
        );
        setFiltered_items(filteredItems)
    }


    const detete_data = () => {
        const cookies = new Cookies();
        var data = { "vehicle_fk": delete_item.vehicle_fk }
        fetchData.delete_device(data).then((res) => {
            var { msg, rval } = res;
            if (rval > 0) {
                alert_trigger("success", msg)
                var data = { "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }
                apply(data)
                setDelete_modal(false)
            } else {
                alert_trigger("error", msg)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const column = [
        { field: 'dname', headerName: 'District', flex: 1.2, headerClassName: 'super-app-theme--header' },
        { field: 'ac_name', headerName: 'Assembly', flex: 1.2, headerClassName: 'super-app-theme--header' },
        { field: 'an_no', headerName: 'AC NO', flex: 1, headerClassName: 'super-app-theme--header' },
        { field: 'category', headerName: 'Category', flex: 1, headerClassName: 'super-app-theme--header', },
        { field: 'v_no', headerName: 'Vehicle No', flex: 1, headerClassName: 'super-app-theme--header' },
        { field: 'imei', headerName: 'IMEI', flex: 1.2, headerClassName: 'super-app-theme--header' },
        { field: 'name', headerName: 'Vehicle Type', flex: 1, headerClassName: 'super-app-theme--header' },
        { field: 'driver_name', headerName: 'Driver Name', flex: 1.5, headerClassName: 'super-app-theme--header' },
        { field: 'driver_no', headerName: 'Driver No 1', flex: 1, headerClassName: 'super-app-theme--header' },
        { field: 'driver_no2', headerName: 'Driver No 2', flex: 1, headerClassName: 'super-app-theme--header' },
        { field: 'so_name', headerName: 'SO Name', flex: 1, headerClassName: 'super-app-theme--header' },
        { field: 'so_no', headerName: 'SO No', flex: 1, headerClassName: 'super-app-theme--header' },
        { field: 'r_no', headerName: 'Route No', flex: 1, headerClassName: 'super-app-theme--header' },
        { field: 'Edit', headerName: '', flex: 0.5, headerClassName: 'super-app-theme--header', renderCell: (params) => { return (<IconButton onClick={(event) => { setEdit_item(params.row); setAdd_edit_modal(true); setModal_title("Edit Device") }}><EditNoteIcon color="primary" /></IconButton>) } },
        { field: 'Delete', headerName: '', flex: 0.5, headerClassName: 'super-app-theme--header', renderCell: (params) => { return (<IconButton onClick={(event) => { setDelete_item(params.row); setDelete_modal(true) }}><DeleteIcon color="error" /></IconButton>) } },
    ]

    return (
        <Box sx={classes.container}>
            <Header ></Header>
            <Filters page={"Map"} apply={apply} options={"District,Assembly,AddDevice"} add_device={add_new_device}></Filters>
            <TextField size='small' label="Search" sx={classes.search} variant="outlined" onChange={(e) => { filter_table(e.target.value) }} />

            {loader ? <Box sx={classes.loader}>
                <ThreeDots
                    height="100"
                    width="100"
                    radius="7"
                    color="#0288d1"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                /></Box>
                : <Box sx={classes.data_table}>

                    <DataGrid
                        disableColumnSelector
                        disableDensitySelector
                        disableSelectionOnClick
                        disableRowSelectionOnClick
                        rows={filtered_items}
                        columns={column} />

                </Box>
            }
            {delete_modal &&
                <Dialog
                    open={true}
                    onClose={() => setDelete_modal(false)}
                >
                    <Box sx={{ width: 450 }}>
                        <DialogTitle sx={{ fontSize: 15 }}>Confirm Delete</DialogTitle>
                        <DialogContent >
                            Are you sure you want to delete item?
                        </DialogContent>
                        <DialogActions sx={{ pr: 3, pb: 2 }}>
                            <Button onClick={() => setDelete_modal(false)}>Cancel</Button>
                            <Button onClick={() => detete_data()}>Delete</Button>
                        </DialogActions>
                    </Box>
                </Dialog>
            }
            {add_edit_modal &&
                <Add_edit_device open={true} onClose={() => { setAdd_edit_modal(false); setEdit_item({}) }} modal_title={modal_title} edit_item={edit_item} refresh={refresh} />
            }
        </Box>
    )
}

export function Add_edit_device(props) {
    const cookies = new Cookies();
    var item = props.edit_item;
    const [priv, setPriv] = useState(cookies.get("priv"))
    const [uid, setUid] = useState(cookies.get("user_id"))
    const [dist, setDist] = useState([])
    const [dist_val, setDist_val] = useState(item.district_fk ? item.district_fk : 0)
    const [ac, setAc] = useState([])
    const [ac_val, setAc_val] = useState(item.ac_fk ? item.ac_fk : 0)
    const [cat, setCat] = useState([])
    const [cat_val, setCat_val] = useState(item.category_fk ? item.category_fk : 0)
    const [veh_type, setVeh_type] = useState([{ "value": 0, "label": "Select" }, { "value": "Car", "label": "Car" }, { "value": "Van", "label": "Van" }, { "value": "MotorCycle", "label": "MotorCycle" }, { "value": "Bus", "label": "Bus" }, { "value": "Truck", "label": "Truck" }])
    const [veh_type_val, setVeh_type_val] = useState(item.name ? item.name : 0)
    const [vehicle_no, setVehicle_no] = useState(item.v_no ? item.v_no : "")
    const [driver_name, setDriver_name] = useState(item.driver_name ? item.driver_name : "")
    const [driver_mob1, setDriver_mob1] = useState(item.driver_no ? item.driver_no : "")
    const [driver_mob2, setDriver_mob2] = useState(item.driver_no2 ? item.driver_no2 : "")
    const [so_name, setSo_name] = useState(item.so_name ? item.so_name : "")
    const [so_no, setSo_no] = useState(item.so_no ? item.so_no : "")
    const [r_no, setR_no] = useState(item.r_no ? item.r_no : "")

    const [imei, setImei] = useState(item.imei ? item.imei : "")
    const [imei_disable, setImei_disable] = useState(item.imei ? true : false)
    const [vehicle_val, setVehicle_val] = useState(item.vehicle_fk ? item.vehicle_fk : "")
    const { enqueueSnackbar } = useSnackbar();


    const alert_trigger = (variant, message) => {
        enqueueSnackbar(message, { variant, anchorOrigin: { horizontal: "right", vertical: "top" } });
    }

    useEffect(() => {
        get_district();
        get_category();
    }, []);


    const get_district = (stat) => {
        var data = { "status": stat, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }
        fetchData.district(data).then((res) => {
            var { dist } = res;
            if (dist.length > 1) {
                dist.unshift({ "label": "Select", "value": 0 });
            }
            if (dist.length == 1) {
                setDist_val(dist[0].value)
            }
            setDist(dist)
            get_ac(dist_val)

        }).catch((error) => {
            console.log(error)
        })
    }


    const get_ac = (stat) => {
        var data = { "dist_fk": stat, "priv_id": cookies.get("priv_id"), "priv": cookies.get("priv") }
        fetchData.ac(data).then((res) => {
            var { assembly } = res;
            if (ac_val == 0) {
                if (assembly.length > 1) {
                    assembly.unshift({ "label": "Select", "value": 0 });
                }
                if (assembly.length == 1) {
                    setAc_val(assembly[0].value)
                } else {
                    setAc_val(0)
                }
            }
            setAc(assembly)
        }).catch((error) => {
            console.log(error)
        })
    }

    const get_category = () => {
        fetchData.category({}).then((res) => {
            var { category } = res;
            if (category.length > 1) {
                category.unshift({ "label": "All", "value": 0 });
            }
            if (category.length == 1) {
                setCat_val(category[0].value)
            }
            setCat(category)

        }).catch((error) => {
            console.log(error)
        })
    }


    const new_device_put = () => {
        var data = { "dist_fk": dist_val, "ac_fk": ac_val, "cat_fk": cat_val, "v_type": veh_type_val, "device_id": imei, "d_name": driver_name, "d_no": driver_mob1, "d_no2": driver_mob2, "so_name": so_name, "so_no": so_no, "r_no": r_no, "v_no": vehicle_no, "uid": uid }
        fetchData.add_device(data).then((res) => {
            var { rval, msg } = res;
            if (rval > 0) {
                alert_trigger("success", msg)
                props.refresh()
                props.onClose()
            }
            else {
                alert_trigger("error", msg)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const update_device = () => {
        var data = { "dist_fk": dist_val, "ac_fk": ac_val, "cat_fk": cat_val, "v_type": veh_type_val, "device_id": imei, "d_name": driver_name, "d_no": driver_mob1, "d_no2": driver_mob2, "so_name": so_name, "so_no": so_no, "r_no": r_no, "v_no": vehicle_no, "uid": uid, "vfk": vehicle_val }
        fetchData.edit_device(data).then((res) => {
            var { rval, msg } = res;
            if (rval > 0) {
                alert_trigger("success", msg)
                props.refresh()
                props.onClose()
            }
            else {
                alert_trigger("error", msg)
            }
        }).catch((error) => {
            console.log(error)
        })
    }


    return (
        <Dialog
            open={true}
            onClose={props.onClose}
        >
            <Box sx={{ width: 500 }}>
                <DialogTitle sx={{ fontSize: 15 }}>{props.modal_title}</DialogTitle>
                <DialogContent >
                    <Grid container spacing={2}>
                        {priv == 0 || priv == 1 ?
                            <Grid item xs={6}>
                                <FormControl fullWidth size='small' sx={classes.filter1}>
                                    <InputLabel >District</InputLabel>
                                    <Select
                                        size='small'
                                        label="District"
                                        value={dist_val}
                                        onChange={(e) => { setDist_val(e.target.value); get_ac(e.target.value) }}
                                    >
                                        {
                                            dist?.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            : null}
                        <Grid item xs={6}>
                            <FormControl size='small' sx={classes.filter1}>
                                <InputLabel >Assembly</InputLabel>
                                <Select
                                    size='small'
                                    label="Assembly"
                                    value={ac_val}
                                    onChange={(e) => { setAc_val(e.target.value); }}
                                >
                                    {
                                        ac?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth size='small' sx={classes.filter1}>
                                <InputLabel >Category</InputLabel>
                                <Select
                                    size='small'
                                    label="Category"
                                    value={cat_val}
                                    onChange={(e) => { setCat_val(e.target.value) }}
                                >
                                    {
                                        cat?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size='small' sx={classes.filter1}>
                                <TextField
                                    value={vehicle_no}
                                    size='small'
                                    InputLabelProps={{ shrink: true }}
                                    label="Vehicle No"
                                    onChange={(e) => {
                                        setVehicle_no(e.target.value)
                                    }}
                                    variant="outlined" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth size='small' sx={classes.filter1}>
                                <InputLabel >Vehicle Type</InputLabel>
                                <Select
                                    size='small'
                                    label="Vehicle Type"
                                    value={veh_type_val}
                                    onChange={(e) => { setVeh_type_val(e.target.value) }}
                                >
                                    {
                                        veh_type?.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size='small' sx={classes.filter1}>
                                <TextField
                                    value={imei}
                                    size='small'
                                    InputLabelProps={{ shrink: true }}
                                    label="Imei"
                                    onChange={(e) => {
                                        setImei(e.target.value)
                                    }}
                                    variant="outlined"
                                    disabled={imei_disable}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size='small' sx={classes.filter1}>
                                <TextField
                                    value={driver_name}
                                    size='small'
                                    InputLabelProps={{ shrink: true }}
                                    label="Driver Name"
                                    onChange={(e) => {
                                        setDriver_name(e.target.value)
                                    }}
                                    variant="outlined" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size='small' sx={classes.filter1}>
                                <TextField
                                    value={driver_mob1}
                                    size='small'
                                    InputLabelProps={{ shrink: true }}
                                    label="Driver Mobile No 1"
                                    onChange={(e) => {
                                        setDriver_mob1(e.target.value)
                                    }}
                                    variant="outlined" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size='small' sx={classes.filter1}>
                                <TextField
                                    value={driver_mob2}
                                    size='small'
                                    InputLabelProps={{ shrink: true }}
                                    label="Driver Mobile No 2"
                                    onChange={(e) => {
                                        setDriver_mob2(e.target.value)
                                    }}
                                    variant="outlined" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size='small' sx={classes.filter1}>
                                <TextField
                                    value={so_name}
                                    size='small'
                                    InputLabelProps={{ shrink: true }}
                                    label="SO Name"
                                    onChange={(e) => {
                                        setSo_name(e.target.value)
                                    }}
                                    variant="outlined" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size='small' sx={classes.filter1}>
                                <TextField
                                    value={so_no}
                                    size='small'
                                    InputLabelProps={{ shrink: true }}
                                    label="SO Mobile No"
                                    onChange={(e) => {
                                        setSo_no(e.target.value)
                                    }}
                                    variant="outlined" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl size='small' sx={classes.filter1}>
                                <TextField
                                    value={r_no}
                                    size='small'
                                    InputLabelProps={{ shrink: true }}
                                    label="Route No"
                                    onChange={(e) => {
                                        setR_no(e.target.value)
                                    }}
                                    variant="outlined" />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ pr: 3, pb: 2 }}>
                    <Button onClick={() => props.onClose()}>Close</Button>
                    {props.modal_title == "Edit Device" ? <Button onClick={() => { update_device() }}>Update</Button> :
                        <Button onClick={() => { new_device_put() }}>Submit</Button>
                    }
                </DialogActions>
            </Box>
        </Dialog>
    )
}