import g1 from '../assets/map_icons/g1.png'
import g2 from '../assets/map_icons/g2.png'
import g4 from '../assets/map_icons/g4.png'
import g3 from '../assets/map_icons/g3.png'

import b1 from '../assets/map_icons/b1.png'
import b2 from '../assets/map_icons/b2.png'
import b4 from '../assets/map_icons/b4.png'
import b3 from '../assets/map_icons/b3.png'


import r1 from '../assets/map_icons/r1.png'
import r2 from '../assets/map_icons/r2.png'
import r4 from '../assets/map_icons/r4.png'
import r3 from '../assets/map_icons/r3.png'


import g from '../assets/map_new_icons/run_r.png'
import b from '../assets/map_new_icons/idle_r.png'
import o from '../assets/map_new_icons/off_r.png'

import l1 from '../assets/list_icons/1.png'
import l2 from '../assets/list_icons/2.png'
import l4 from '../assets/list_icons/4.png'
import l3 from '../assets/list_icons/3.png'

// export const running_img = {
//     1: g1,
//     2: g2,
//     3: g3,
//     4: g4
// }

export const running_img = {
    1: g,
    2: g,
    3: g,
    4: g
}

// export const idle_img = {
//     1: b1,
//     2: b2,
//     3: b3,
//     4: b4
// }

export const idle_img = {
    1: b,
    2: b,
    3: b,
    4: b
}

// export const stop_img = {
//     1: r1,
//     2: r2,
//     3: r3,
//     4: r4
// }

export const stop_img = {
    1: o,
    2: o,
    3: o,
    4: o
}

export const list_img = {
    1: l1,
    2: l2,
    3: l3,
    4: l4
}